<template>
	<div class="mainContent">
		<div class="mainHeader">
			<h1>LIST OF ORDERS</h1>
			<!-- <ul>
				<li v-for="order in orders" :key="order.id">{{ order.id }}</li>
			</ul> -->
			<div>
				<b-btn class="button" :class="{ 'active' : selected==='active'}" @click="onReloadOrdersClicked">Active Orders</b-btn>
				<b-btn class="button" :class="{ 'active' : selected==='completed'}" @click="onLoadCompleted">Completed Orders</b-btn>
				<b-btn class="button" :class="{ 'active' : selected==='deleted'}" @click="onLoadDeleted">Deleted Orders</b-btn>
			</div>
		</div>
		<OrdersGrid style="grid-area:content" />
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from './store';
import OrdersGrid from './OrdersGrid.vue';

export default {
	name: 'Orders',
	components: {
		OrdersGrid
	},
	data() {
		return {
			selected: 'active',
		};
	},
	computed: {
		...mapState([
			'orders'
		])
	},
	methods: {
		...mapActions([
			'getOrders',
			'getDeleted',
			'getCompleted'
		]),
		...mapMutations([
			'setSelectedOrders'
		]),
		async onReloadOrdersClicked() {
			this.selected = 'active';
			await this.getOrders();
			this.setSelectedOrders([]);
		},
		async onLoadDeleted(){
			this.selected = 'deleted';
			await this.getDeleted();
			this.setSelectedOrders([]);
		},
		async onLoadCompleted(){
			this.selected = 'completed';
			await this.getCompleted();
			this.setSelectedOrders([]);
		}
	},
	mounted(){
		this.getOrders();
	},
	beforeMounted() {

	}
};
</script>

<style lang="scss" scoped>
.button {
	margin: 5px 5px 0 5px;
}
.mainContent {
    display: grid;
	max-height: calc(100vh - 176px);
	height: calc(100vh - 176px);
	grid-template-rows: 142px auto;
	grid-template-areas: 'header' 'content';
}
@media (min-width: 425px) {
	.mainContent {
		max-height: calc(100vh - 96px);
		height: calc(100vh - 96px);
		grid-template-rows: 100px auto;
	}
}
@media (min-width: 768px) {
	.mainContent {
		max-height: calc(100vh - 96px);
		height: calc(100vh - 96px);
		grid-template-rows: 100px auto;
	}
}
@media (min-width: 1024px) {
	.mainContent {
		max-height: calc(100vh - 56px);
		height: calc(100vh - 56px);
		grid-template-rows: 100px auto;
	}
}
.mainHeader {
	grid-area: 'header';
}
</style>
