<template>
	<div>
		<b-container fluid>
			<div class="newUserContainer">
				<div style="grid-area:newUser">
					<b-btn @click="showNewUser=!showNewUser">Add new User</b-btn>
					<b-collapse v-model="showNewUser" id="newUser" style="margin-top:1rem;">
						<b-form @submit="onSubmit" @reset="onReset" v-if="formReset">
							<b-form-input type="email"
								v-model="form.email"
								required
								placeholder="Email"
							/>
							<b-form-input type="text"
								v-model="form.displayName"
								required
								placeholder="Name"
							/>
							<b-form-group>
								<b-form-checkbox v-model="form.giveAccess">Give user access</b-form-checkbox>
							</b-form-group>
							<b-btn type="reset" variant="danger" style="margin:0.5rem .15rem;">Reset</b-btn>
							<b-btn type="submit" variant="primary" style="margin:0.5rem .15rem;">Add</b-btn>
							<b-alert :show="formAlert.dismissCountDown"
								dismissible
								fade
								:variant="formAlert.variant"
								@dismissed="formAlertdismissCountDown=0"
								@dismiss-count-down="(countDown) => {formAlert.dismissCountDown=countDown}"
								v-text="formAlert.message"
							/>
						</b-form>
					</b-collapse>
				</div>
			</div>
			<div class="componentContainer">
				<div style="grid-area:woAccess">
					<h2>Users without access</h2>
					<b-list-group>
						<b-list-group-item href="#" class="withoutAccess" v-for="user in usersWithoutAccess" :key="user.id" @click="listItemClick($event, user)">
							<div class="d-flex w-100 justify-content-between">
								<h5 class="mb-1" v-text="user.name"></h5>
								<div>
									<b-badge variant="secondary" title="Delete user" style="padding:5px;margin:0 2px;" @click="deleteUser(user.id)"><font-awesome-icon icon="trash-alt"></font-awesome-icon></b-badge>
									<b-badge variant="danger" title="Ban user" style="padding:5px;margin:0 2px;" @click="banUser(user.id)"><font-awesome-icon icon="ban"></font-awesome-icon></b-badge>
								</div>
							</div>
							<p class="mb-1" v-text="user.email"></p>
						</b-list-group-item>
					</b-list-group>
					<em v-if="!withoutAccessLength">None</em>
				</div>
				<div style="grid-area:buttons;margin:auto;">
					<div class="button-container">
						<div style="margin: 3px;">
							<b-btn @click="grantAccess"><font-awesome-icon icon="arrow-right"></font-awesome-icon></b-btn>
						</div>
						<div style="margin: 3px;">
							<b-btn @click="revokeAccess"><font-awesome-icon icon="arrow-left"></font-awesome-icon></b-btn>
						</div>
					</div>
				</div>
				<div style="grid-area:wAccess">
					<h2>Users with access</h2>
					<b-list-group>
						<b-list-group-item href="#" class="withAccess" v-for="user in usersWithAccess" :key="user.id" @click="listItemClick($event, user)" :disabled="listItemIsCurrentUser(user.id)">
							<div class="d-flex w-100 justify-content-between">
								<h5 class="mb-1" v-text="user.name"></h5>
								<div>
									<b-badge v-if="isSupportUser" variant="danger" title="Generate password link" style="padding:5px;margin:0 2px;" @click="generatePasswordLink(user.id)"><font-awesome-icon icon="unlock"></font-awesome-icon></b-badge>
									<b-badge v-if="user.orderHandler" variant="primary" title="Remove order handler" style="padding:5px;margin:0 2px;" @click="removeUserOrderHandler(user.id)"><font-awesome-icon icon="inbox"></font-awesome-icon></b-badge>
									<b-badge v-if="!user.orderHandler" variant="secondary" title="Set order handler" style="padding:5px;margin:0 2px;" @click="setUserOrderHandler(user.id)"><font-awesome-icon icon="inbox"></font-awesome-icon></b-badge>
									<b-badge variant="secondary" title="Upgrade to superuser" style="padding:5px;margin:0 2px;" @click="upgradeToSuperuser(user.id)"><font-awesome-icon icon="star"></font-awesome-icon></b-badge>
								</div>
							</div>
							<p class="mb-1" v-text="user.email"></p>
						</b-list-group-item>
					</b-list-group>
					<em v-if="!withAccessLength">None</em>
				</div>
				<div style="grid-area:banned">
					<h2 @click="showBanned=!showBanned" :class="{ 'dropdown-toggle': bannedLength}" href="#">Banned users<span v-if="bannedLength">{{ ' ('+bannedLength+')' }}</span></h2>
					<b-collapse v-model="showBanned" v-if="bannedLength" id="bannedUsers">
						<b-list-group>
							<b-list-group-item href="#" class="bannedUsers" v-for="user in bannedUsers" :key="user.id" @click="listItemClick($event, user)">
								<div class="d-flex w-100 justify-content-between">
									<h5 class="mb-1" v-text="user.name"></h5>
									<div>
										<b-badge variant="secondary" title="Unban user" style="padding:5px;margin:0 2px;" @click="unbanUser(user.id)"><font-awesome-icon icon="undo-alt"></font-awesome-icon></b-badge>
									</div>
								</div>
								<p class="mb-1" v-text="user.email"></p>
							</b-list-group-item>
						</b-list-group>
					</b-collapse>
					<em v-if="!bannedLength">None</em>
				</div>
				<div style="grid-area:super">
					<h2>Superusers</h2>
					<b-list-group>
						<b-list-group-item href="#" class="withAccess" v-for="user in superUsers" :key="user.id" :disabled="listItemIsCurrentUser(user.id)">
							<div class="d-flex w-100 justify-content-between">
								<h5 class="mb-1">{{ user.name + (listItemIsCurrentUser(user.id) ? ' (You)': '') }}</h5>
								<div>
									<b-badge v-if="isSupportUser" variant="danger" title="Generate password link" style="padding:5px;margin:0 2px;" @click="generatePasswordLink(user.id)"><font-awesome-icon icon="unlock"></font-awesome-icon></b-badge>
									<b-badge v-if="user.orderHandler" variant="primary" title="Remove order handler" style="padding:5px;margin:0 2px;" @click="removeUserOrderHandler(user.id)"><font-awesome-icon icon="inbox"></font-awesome-icon></b-badge>
									<b-badge v-if="!user.orderHandler" variant="secondary" title="Set order handler" style="padding:5px;margin:0 2px;" @click="setUserOrderHandler(user.id)"><font-awesome-icon icon="inbox"></font-awesome-icon></b-badge>
									<b-badge variant="secondary" title="Downgrade from superuser" style="padding:5px;margin:0 2px;" @click="downgradeFromSuperuser(user.id)"><font-awesome-icon icon="star-half-alt"></font-awesome-icon></b-badge>
								</div>
							</div>
							<p class="mb-1" v-text="user.email"></p>
						</b-list-group-item>
					</b-list-group>
				</div>
			</div>
		</b-container>
		<response-modal
			v-if="responseModalProperties.show"
			:closeButton="responseModalProperties.closeButton"
			:content="responseModalProperties.content"
			:visible="responseModalProperties.show"
			:title="responseModalProperties.title"
			@close="responseModalClose"
		></response-modal>
	</div>
</template>

<script>
import { userAuth } from '@/core';
import { mapState, mapGetters, mapActions } from './store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import ResponseModal from './ResponseModal.vue';

library.add(fas);

export default {
	name: 'admin',
	components: {
		'font-awesome-icon': FontAwesomeIcon,
		ResponseModal,
	},
	data() {
		return {
			selectedWithout: [],
			selectedWith: [],
			selectedBanned: [],
			currentUserId: null,
			preventToggleSelected: false,
			showBanned: false,
			showNewUser: false,
			form: {
				email: null,
				displayName: null,
				giveAccess: false
			},
			formReset: true,
			formAlert: {
				dismissSecs: 3,
				dismissCountDown: 0,
				variant: null,
				message: null
			},
			responseModalProperties: {
				show: false,
				title: false,
				content: false,
				okButton: false,
				closeButton: false,
			},
		};
	},
	computed: {
		...mapState([
			'usersWithoutAccess',
			'usersWithAccess',
			'bannedUsers',
			'superUsers',
			'isSuper',
		]),
		...mapGetters([
			'isSupportUser',
		]),
		withoutAccessLength(){
			if(this.usersWithoutAccess){
				let length = this.usersWithoutAccess.length;
				return +length;
			}
			return 0;
		},
		withAccessLength(){
			if(this.usersWithAccess){
				let length = this.usersWithAccess.length;
				return +length;
			}
			return 0;
		},
		bannedLength(){
			if(this.bannedUsers){
				let length = this.bannedUsers.length;
				return +length;
			}
			return 0;
		},
	},
	methods: {
		...mapActions([
			'fetchUsersWithoutAccess',
			'fetchUsersWithAccess',
			'fetchBannedUsers',
			'fetchSuperUsers',
			'grantUsersAccess',
			'revokeUsersAccess',
			'banUsers',
			'unbanUsers',
			'deleteUsers',
			'upgradeUser',
			'downgradeUser',
			'setOrderHandler',
			'removeOrderHandler',
			'createUserWithFirebase',
			'generatePasswordResetLink',
		]),
		async getCurrentUserId(){
			let currentUser = await userAuth.getCurrentUser();
			this.currentUserId = currentUser.uid;
			// console.log(this.currentUserId);
		},
		listItemIsCurrentUser(userId){
			if(this.currentUserId === null) return false;
			return this.currentUserId === userId;
		},
		listItemClick(e, user){
			/**
			 * From method deleteUser
			 * If user deleted (preventToggleSelected = true), do not set item as selected
			 */
			if(this.preventToggleSelected){
				this.preventToggleSelected = false;
				return;
			}
			let target = e.target;
			// console.log(e);
			while(!target.classList.contains('list-group-item')){
				target = target.parentNode;
			}
			target.classList.toggle('active');
			//Add remove from list of selected items with access
			if(target.classList.contains('withAccess')){
				let i = this.selectedWith.indexOf(user.id);
				if(i == -1){
					this.selectedWith.push(user.id);
				} else {
					this.selectedWith.splice(i, 1);
				}
			//Add remove from list of selected items without access
			} else if(target.classList.contains('withoutAccess')){
				let i = this.selectedWithout.indexOf(user.id);
				if(i == -1){
					this.selectedWithout.push(user.id);
				} else {
					this.selectedWithout.splice(i, 1);
				}
			} else if(target.classList.contains('bannedUsers')){
				let i = this.selectedBanned.indexOf(user.id);
				if(i == -1){
					this.selectedBanned.push(user.id);
				} else {
					this.selectedBanned.splice(i, 1);
				}
			}
		},
		revokeAccess() {
			this.revokeUsersAccess(this.selectedWith);
			this.selectedWith = [];
		},
		grantAccess(){
			this.grantUsersAccess(this.selectedWithout);
			this.selectedWithout = [];
		},
		banUser(userId){
			/**
			 * When clicking delete-icon on list-item, it also selects it
			 * preventToggleSelected used to flag that user should not be selected
			 * */
			this.preventToggleSelected = true;
			this.banUsers([userId]);
		},
		unbanUser(userId){
			/**
			 * When clicking delete-icon on list-item, it also selects it
			 * preventToggleSelected used to flag that user should not be selected
			 * */
			this.preventToggleSelected = true;
			this.unbanUsers([userId]);
		},
		deleteUser(userId){
			/**
			 * When clicking delete-icon on list-item, it also selects it
			 * preventToggleSelected used to flag that user should not be selected
			 * */
			this.preventToggleSelected = true;
			this.deleteSelectedUsers([userId]);
		},
		deleteSelectedUsers(userIds = this.selectedWithout){
			this.$dialog.confirm(`Are you sure you want to delete ${userIds.length > 1 ? `these ${userIds.length} users` : 'this user'}?`, {
				okText: 'Delete user(s)',
				cancelText: 'Do not delete user(s)',
				animation: 'bounce'
			}).then(() => {
				// console.log('delete:', userIds);
				this.deleteUsers(userIds);
			}).catch(() => {
				return;
			});
		},
		upgradeToSuperuser(userId){
			this.preventToggleSelected = true;
			this.upgradeUser(userId);
		},
		downgradeFromSuperuser(userId){
			this.preventToggleSelected = true;
			this.downgradeUser(userId);
		},
		setUserOrderHandler(userId){
			this.preventToggleSelected = true;
			this.setOrderHandler(userId);
		},
		removeUserOrderHandler(userId){
			this.preventToggleSelected = true;
			this.removeOrderHandler(userId);
		},
		async generatePasswordLink(userId){
			let response = await this.generatePasswordResetLink([userId]);
			this.responseModalProperties = {
				show: true,
				title: 'Password link',
				content: `${response}`,
				closeButton: 'Close'
			};
		},
		async responseModalClose(){
			this.responseModalProperties.show = false;
		},
		async onSubmit(evt) {
			evt.preventDefault();
			let res = await this.createUserWithFirebase(this.form);
			this.onSubmitResponse(res);
		},
		async onSubmitResponse(res){
			// https://bootstrap-vue.js.org/docs/components/alert/#auto-dismissing-alerts
			this.formAlert.message = res.message;
			if(res.success) {this.formAlert.variant = 'success';}
			else {this.formAlert.variant = 'warning';}
			this.formAlert.dismissCountDown = this.formAlert.dismissSecs;

			// //If new user created, send mail for password-reset
			// //Cannot be handled serverside for some reason...
			// if(res.userCreated !== null){
			// 	await userAuth.resetPasswordEmail(res.userCreated)
			// 		.catch( e => {
			// 			console.log(e);
			// 		});
			// }
			//Reload users
			this.fetchUsersWithoutAccess();
			this.fetchUsersWithAccess();
		},
		onReset(evt) {
			evt.preventDefault();
			/* Reset our form values */
			this.form.email = '';
			this.form.displayName = '';
			this.form.giveAccess = false;
			/* Trick to reset/clear native browser form validation state */
			/* https://bootstrap-vue.js.org/docs/components/form/ */
			this.formReset = false;
			this.$nextTick(() => { this.formReset = true; });
		}
	},
	activated() {
		/**Check if user is superUser on activate
		 * If not, push to home
		 * Else, load as usual
		 */
		if(!this.isSuper){
			this.$router.push('/');
		} else {
			this.fetchUsersWithoutAccess();
			this.fetchUsersWithAccess();
			this.fetchBannedUsers();
			this.fetchSuperUsers();
			this.getCurrentUserId();
		}
	},
};
</script>

<style lang="scss" scoped>
.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.button-container .btn .svg-inline--fa {
	transform: rotate(90deg);
}
.componentContainer {
	display: grid;
	grid-gap: 10px;
	padding: 15px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'woAccess' 'buttons' 'wAccess' 'banned' 'super';
}
.newUserContainer {
	display: grid;
	grid-gap: 10px;
	padding: 15px;
	grid-template-columns: 1fr;
	grid-template-areas:
	'newUser';
}
@media(min-width: 768px){
	.componentContainer {
		grid-template-columns: 1fr auto 1fr;
		grid-template-areas:
		'woAccess buttons wAccess'
		'banned . super';
	}
	.newUserContainer {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
		'. newUser .';
	}
	.button-container {
		flex-direction: column;
	}
	.button-container .btn .svg-inline--fa {
		transform: none;
	}
}
</style>
