<template>
	<div class="uploadContainer">
		<form enctype="multipart/form-data" novalidate>
			<div class="dropbox">
				<input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target); fileCount = $event.target.files.length"
					accept="application/pdf,image/*" class="input-file">
				<div class="content">
					<font-awesome-layers>
						<font-awesome-icon class="fa-lg" :icon="['far', 'circle']" />
						<font-awesome-icon class="fa-md" :icon="['fas', 'plus']" />
					</font-awesome-layers>
					<p v-if="isInitial">
						Upload files
					</p>
					<p v-else-if="isSaving">
						Uploading {{ fileCount }} files...
					</p>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
// import { upload } from './file-upload.service';

const STATUS_INITIAL = 0, STATUS_SAVING = 1;

import { pdfCore } from '@/core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas);
library.add(far);

export default {
	name: 'OrderUpload',
	components: {
		'font-awesome-icon': FontAwesomeIcon,
		'font-awesome-layers': FontAwesomeLayers
	},
	data() {
		return {
			uploadedFiles: [],
			uploadError: null,
			currentStatus: null,
			uploadFieldName: 'photos'
		};
	},
	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
	},
	methods: {
		filesChange(input) {
			var fileList = input.files;
			if (!fileList.length) return;

			// append the files to FormData
			var files = [];
			for(var file of fileList){
				files.push(file);
			}

			// console.log(files);
			// save it
			this.save(files).then( thumbnails => {
				this.currentStatus = STATUS_INITIAL;
				this.$emit('uploadSuccess', thumbnails);
				input.value = null;
			}).catch( err => {
				this.currentStatus = STATUS_INITIAL;
				this.$emit('uploadError', err);
				input.value = null;
			});
		},
		async save(files) {
			// upload data to the server
			this.currentStatus = STATUS_SAVING;
			var thumbnails = [];

			let caughtError;
			for(const file of files) {
				try {
					var b64d = await this.readFile(file);
					var image = await this.generateImage(b64d); //canvas, pagerender
					var thumbnail = await this.imageToObject(image, b64d, file); //img

					thumbnails.push(thumbnail);
				} catch(err) {
					if(!caughtError) caughtError = err;
				}
			}
			return thumbnails;
		},
		async readFile(file) {
			return new Promise((resolve) => {
				var fReader = new FileReader();

				fReader.onload = () => {
					resolve(fReader.result);
				};

				fReader.readAsDataURL(file);
			});
		},
		async generateImage(b64d) {
			// console.log(b64d);
			return new Promise( resolve => {
				let startIndex = b64d.indexOf(':');
				let endIndex = b64d.indexOf(';');
				let type = b64d.substring(startIndex+1,endIndex);
				if(type.includes('image')){
					// throw new Error('Unable to upload pictures in this version');
					var img = document.createElement('img');
					img.src = b64d;
					img.onload = () => {
						var canvas = document.createElement('canvas');
						var ctx = canvas.getContext('2d');
						canvas.width = img.width;
						canvas.height = img.height;
						// console.log(img);
						ctx.drawImage(img, 0, 0);

						var dataURL = canvas.toDataURL('image/png');
						// console.log(dataURL);
						resolve(dataURL);
					};

				} else {
					startIndex = b64d.indexOf(',');
					b64d = b64d.substring(startIndex+1);
					b64d = atob(b64d);
					pdfCore.documentToDataUrl(b64d).then(dataUrl => {
						resolve(dataUrl);
					});
				}
			});
		},
		async imageToObject(img, b64d, file) {
			return new Promise( resolve => {
				let type = b64d.substring(b64d.indexOf(':')+1,b64d.indexOf(';'));
				let newFileName = file.name;
				let fileType = null;
				if(type.includes('image')){
					newFileName = `${file.name.slice(0, file.name.lastIndexOf('.'))}.png`;
					fileType = 'PP';
				}
				var image = {
					description:newFileName,
					documentUrl: b64d,
					documentThumbnailUrl: img,
					fileName: newFileName,
					documentType: fileType,
				};
				resolve(image);
			});
		},
	},
	mounted() {
		this.currentStatus = STATUS_INITIAL;
		this.uploadedFiles = [];
		this.uploadError = null;
	},
};
</script>

<style lang="scss" scoped>
$text-color: #9fa8b0;
$white: #fff;
.uploadContainer{
	height:100%;
}
.dropbox {
	border: 2px dashed lighten($color: $text-color, $amount: 10%); /* the dash box */
	border-radius: 20px;
    // background: rgba(0, 0, 0, 0.45);
    // background-color: rgba(0, 0, 0, 0.5);
    // text-shadow: 1px 1px 2px #000;
    color: $text-color;
	min-height: 185px;
	position: relative;
	cursor: pointer;
    transition: background-color 175ms;
}

.input-file {
	opacity: 0; /* invisible but it's there! */
	left: 0;
	top: 0;
	width: 100%;
	min-height: 185px;
	position: absolute;
	cursor: pointer;
    z-index: 9998;
}

.dropbox:hover {
	background-color: #f7f7f7;
}
.dropbox .content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.dropbox p {
	font-size: 1.2em;
    margin: 0;
}
.content .fa-layers {
	color: lighten($color: $text-color, $amount: 10%);
	height:3rem;
	width:3rem;
	font-size:2rem;
}

</style>
