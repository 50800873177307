<template>
	<div style="position:relative">
		<date-picker :class="componentClass" :disabled="disabled" :placeholder="placeholder" :value="value" :config="options" @dp-change="onInputChanged($event)"></date-picker>
	</div>
</template>
<script>


import DatePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { dom } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

dom.watch();
moment.updateLocale('en', {week: {dow: 1}});
export default {
	components: {
		DatePicker
	},
	props: {
		value: String,
		dateFormat: String,
		invalid: {
			default: false,
			type: Boolean
		},
		placeholder: String,
		disabled: Boolean,
		size: String
	},
	data() {
		return	{
			options: {
				format: this.dateFormat,
				useCurrent: false,
				debug: false,
				showClose: true,
				showClear: true,
				showTodayButton: true,
				toolbarPlacement: 'top',
				locale: this.getLocale(),
				icons: {
					previous: 'fas fa-chevron-left',
					next: 'fas fa-chevron-right',
					close: 'fas fa-times',
					clear: 'fas fa-trash-alt',
					today: 'fas fa-calendar-check',
					time: 'fas fa-clock',
				},
				tooltips: {
					today: 'Go to today',
					clear: 'Clear selection',
					close: 'Close',
					selectMonth: 'Select Month',
					selectYear: 'Select Year',
					selectDecade: 'Select Decade',
				}
			}
		};
	},
	computed: {
		isValid() {
			if (this.invalid) return 'is-invalid';
			return '';
		},
		componentClass() {
			let classNames = ['datetimepicker'];
			if(this.size === 'sm')
				classNames.push('form-control-sm');
			else if(this.size === 'lg')
				classNames.push('form-control-lg');
			
			if(this.invalid)
				classNames.push('is-invalid');

			return classNames.join(' ');
		}
	},
	methods: {
		onInputChanged(value) {
			this.$emit('input', value.date);
		},
		getLocale() {
			let lang = localStorage.getItem('locale');
			if (lang === 'no') {
				lang = 'nb';
			}
			return lang;
		}
	}
};
</script>