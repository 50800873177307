<template>
	<b-tabs class="declarationTabs">
		<b-tab
			v-for="(declaration, index) in declarations"
			:key="index"
			:title="declaration.orderNo"
		>
			<order-declaration
				:declarationData="declaration"
			/>
		</b-tab>
	</b-tabs>
</template>

<script>
import {mapState, mapMutations, mapActions} from '../store';
import OrderDeclaration from './ComponentDeclaration.vue';

export default {
	name: 'OrderDeclarations',
	components: {
		OrderDeclaration
	},
	data() {
		return {
			declarations: [],
		};
	},
	computed: {
		...mapState([]),
	},
	methods: {
		...mapMutations([]),
		...mapActions([
			'getRelatedDeclarations'
		]),
	},
	async beforeMount() {
		let declarations = await this.getRelatedDeclarations();
		this.declarations = declarations;
	}
};
</script>

<style lang="scss" scoped>
	.declarationTabs /deep/ .nav-item {
		font-size: .8rem;
	}
</style>
