<template>
	<div class="ordersGrid">
		<div class="toolsContainer">
			<div class="left tool" style="grid-area:leftTool;">
				<b-btn size="sm" class="b-btn" @click="acceptSelectedOrders" v-if="selectedOrdersJustTender">Accept selected Orders</b-btn>
				<b-btn size="sm" class="b-btn" @click="refuseSelectedOrders" v-if="selectedOrdersJustTender">Refuse selected Orders</b-btn>
				<b-btn size="sm" class="b-btn" @click="editSelectedOrders" v-if="selectedOrdersJustDraft">Edit selected Orders</b-btn>
				<b-btn size="sm" class="b-btn" @click="sendToCofirm" v-if="selectedOrdersJustDraft && transporterHasConfirm">Send to Confirm</b-btn>
				<b-btn size="sm" class="b-btn" @click="deleteSelectedOrders" v-if="selectedOrdersJustDraftOrCancelled">Delete selected Orders</b-btn>
				<b-btn size="sm" class="b-btn" @click="restoreSelectedOrders" v-if="selectedOrdersJustDeleted">Restore selected Orders</b-btn>
			</div>
			<div class="right tool" style="grid-area:rightTool;">
				<date-picker size="sm" :value="after" :invalid="afterInvalid" placeholder="From date" dateFormat="DD.MM.YYYY" @input="onDateAfterChanged($event)"></date-picker>
				<date-picker size="sm" :value="before" :invalid="beforeInvalid" placeholder="To date" dateFormat="DD.MM.YYYY" @input="onDateBeforeChanged($event)"></date-picker>
				<b-btn size="sm" class="b-btn" @click="loadOrdersByQuery">Fetch Orders</b-btn>
			</div>
		</div>
		<ag-grid-vue
			width="100%"
			class="ag-theme-alpine grid"
			@gridReady="onGridReady"
			:gridOptions="gridOptions"
			:columnDefs="columnDefs"
			:rowData="orders"
			:rowClassRules="rowClassRules"
			:suppressColumnVirtualisation="true"
			:debounceVerticalScrollbar="true"
		/>
		<orders-modal
			ref="ordersModal"
			v-if="modalShow && selectedOrderData"
			:visible="modalShow"
			:selectedOrderData="selectedOrderData"
			@close="modalClose"
		></orders-modal>
		<orders-multiple-modal
			ref="ordersMultipleModal"
			v-if="modalMultipleShow"
			:visible="modalMultipleShow"
			@close="modalMultipleClose"
		></orders-multiple-modal>
		<code-of-conduct-modal
			v-if="codeOfConductShow"
			:visible="codeOfConductShow"
			:customerCodes="selectedOrderCustomers"
			@close="codeOfConductClose"
		></code-of-conduct-modal>

	</div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import moment from 'moment-timezone';
import { negotiateTranslatedText, orderStatusValues, customerLogos } from '@/core';

import { mapState, mapMutations, mapActions } from './store';
import { mapState as mapLoginState, mapActions as mapLoginActions } from '../login/store';

import OrdersModal from './modals/OrdersModal.vue';
import OrdersMultipleModal from './modals/multipleRowEdit/OrdersEditMultipleModal.vue';
import CodeOfConductModal from './modals/CodeOfConductModal.vue';
import DatePicker from './modals/DatePicker.vue';

import DateFilter from './dateFilters/DateFilter.vue';
import FloatingDateFilter from './dateFilters/FloatingDateFilter.vue';

//AgGrid-buttons implemented on last column
//Must be edited directly in GridActions.vue-component
import Actions from './GridActions.vue';
// import FreightSummary from './GridFreightSummary.vue';

export default {
	name: 'OrdersGrid',
	components: {
		AgGridVue,
		OrdersModal,
		OrdersMultipleModal,
		CodeOfConductModal,
		// Actions,
		// FreightSummary,
		DatePicker,
		// DateFilter,
		// FloatingDateFilter
	},
	data() {
		return {
			gridOptions: {},
			components: null,
			columnDefs: [],
			rowClassRules: {},
			userColDefs: null,
			hasSavedFilters: false,
			modalShow: false,
			modalMultipleShow: false,
			codeOfConductShow: false,
			selectedOrder: null,
			dateAfter: null,
			dateBefore: null,
			selectedOrderCustomers: [],
		};
	},
	computed: {
		...mapState([
			'orders',
			'shortDateFormat',
			'selectedOrderData',
			'selectedOrders',
			'queryStatus',
			'customsPoints',
			'defaultGridSort',
		]),
		...mapLoginState([
			'currentUser',
			'transporterSettings'
		]),
		after() {
			return moment(this.dateAfter, 'YYYYMMDDHHmm').format('DD.MM.YYYY');
		},
		before() {
			return moment(this.dateBefore, 'YYYYMMDDHHmm').format('DD.MM.YYYY');
		},
		afterInvalid(){
			if(this.dateAfter !== null){
				let long = 'YYYYMMDDHHmm';
				let before = moment(this.dateBefore, 'YYYYMMDDHHmm').isValid();
				let after = moment(this.dateAfter, long);
				if(
					!(before ? after.isSameOrBefore(moment(this.dateBefore, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		beforeInvalid(){
			if(this.dateBefore !== null){
				let long = 'YYYYMMDDHHmm';
				let after = moment(this.dateAfter, 'YYYYMMDDHHmm').isValid();
				let before = moment(this.dateBefore, long);
				if(
					!(after ? before.isSameOrAfter(moment(this.dateAfter, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		selectedOrdersJustTender(){
			if(!this.selectedOrders.length) return false;
			for(let order of this.selectedOrders){
				if(order.status != 0) return false;
			}
			return true;
		},
		selectedOrdersJustDraft(){
			if(!this.selectedOrders.length) return false;
			for(let order of this.selectedOrders){
				if(order.status < 10 || order.status >= 100) return false;
			}
			return true;
		},
		selectedOrdersJustDraftOrCancelled(){
			if(!this.selectedOrders.length) return false;
			for(let order of this.selectedOrders){
				if((order.status < 10 || order.status >= 100) && order.status !== -5) return false;
			}
			return true;
		},
		selectedOrdersJustDeleted(){
			if(!this.selectedOrders.length) return false;
			for(let order of this.selectedOrders){
				if(order.status >= -5) return false;
			}
			return true;

		},
		transporterHasConfirm() {
			return this.transporterSettings.hasConfirm;
		}
	},
	methods: {
		t(key) {
			return negotiateTranslatedText('', key);
		},
		...mapMutations([
			'setSelectedOrder',
			'setSelectedOrders',
			'clearSelectedOrder',
			'setStoredGridOptions',
		]),
		...mapActions([
			'reloadSelectedOrder',
			'getOrdersByQuery',
			'sendOrderToTMS',
			'sendOrderToConfirm',
			'deleteOrder',
			'restoreOrder',
			'acceptOrder',
			'refuseOrder',
			'flagSeenByUser',
		]),
		...mapLoginActions([
			'loadUserDefinedColumnDefinitions',
		]),
		onGridReady() {
			// params.api.sizeColumnsToFit();
			// var timeoutId;
			// window.addEventListener('resize', function() {
			// 	clearTimeout(timeoutId);
			// 	timeoutId = setTimeout(function() {
			// 		clearTimeout(timeoutId);
			// 		timeoutId = null;
			// 		params.api.sizeColumnsToFit();
			// 	}, 50);
			// });
			// console.log('setModel');
			// this.gridOptions.api.setSortModel(this.defaultGridSort); // Stored in state
		},
		async onRowSelected(e){
			/**
			 * e: {
			 * api: GridApi {…}
			 * columnApi: ColumnApi {__ob__: Observer}
			 * context: {__ob__: Observer}
			 * data: {…}
			 * event: MouseEvent {isTrusted: true, screenX: 616, screenY: 398, clientX: 549, clientY: 305, …}
			 * node: RowNode {…}
			 * rowIndex: 2
			 * rowPinned: undefined
			 * source: RowComp {…}
			 * type: "rowClicked"
			 * __proto__:Object
			 * }
			 */
			//console.log('row selected\n', e);\
			let selectedOrder = this.findOrderById(e.data.id);
			this.setSelectedOrder(selectedOrder);
			this.modalShow = !this.modalShow;
			if(!selectedOrder || !selectedOrder.hasChanges){
				// await this.flagSeenByUser(e.data.id); // Moved to modal on close
				await this.reloadSelectedOrder(e.data.id);
			}
		},
		onRowMultipleSelectionChanged(e) {
			let orders = [];
			for(let order of e.api.getSelectedNodes()){
				orders.push(this.findOrderById(order.data.id));
			}
			// console.log(orders);
			this.setSelectedOrders(orders);
		},
		editSelectedOrders() {
			if(!this.selectedOrders.length){
				return;
			} else if(this.selectedOrders.length > 1){
				this.modalMultipleShow = !this.modalMultipleShow;
			} else {
				//Single edit
				this.onRowSelected({data:this.selectedOrders[0]});
			}
		},
		deleteSelectedOrders() {
			if(!this.selectedOrders.length){
				return;
			} else if(this.selectedOrders.length > 1){
				for(let orderId of this.selectedOrders.map(x => x.id)) {
					this.deleteOrder(orderId);
				}
			} else {
				this.deleteOrder(this.selectedOrders[0].id);
			}
			this.setSelectedOrders([]);
		},
		async codeOfConductClose(accepted){
			this.codeOfConductShow = false;
			if(accepted){
				if(!this.selectedOrders.length){
					return;
				} else if(this.selectedOrders.length > 1){
					for(let orderId of this.selectedOrders.map(x => x.id)) {
						this.acceptOrder(orderId);
					}
				} else {
					this.acceptOrder(this.selectedOrders[0].id);
				}
			}
			this.selectedOrderCustomers = [];
		},
		acceptSelectedOrders(){

			let requiresCodeOfConduct = this.selectedOrders.some(x => !!x.customerSettings.requiresCodeOfConduct);

			if(requiresCodeOfConduct){
				this.selectedOrderCustomers = Array.from(new Set(this.selectedOrders.map(x => x.customerSettings.code)));
				this.codeOfConductShow = true;
			} else {
				this.codeOfConductClose(true);
			}
		},
		refuseSelectedOrders() {
			if(!this.selectedOrders.length){
				return;
			} else if(this.selectedOrders.length > 1){
				for(let orderId of this.selectedOrders.map(x => x.id)) {
					this.refuseOrder(orderId);
				}
			} else {
				this.refuseOrder(this.selectedOrders[0].id);
			}
			this.setSelectedOrders([]);
		},
		restoreSelectedOrders() {
			if(!this.selectedOrders.length){
				return;
			} else if(this.selectedOrders.length > 1){
				for(let orderId of this.selectedOrders.map(x => x.id)) {
					this.restoreOrder(orderId);
				}
			} else {
				this.restoreOrder(this.selectedOrders[0].id);
			}
			this.setSelectedOrders([]);
		},
		findOrderById(id){
			for(let order of this.orders) {
				if(order.id === id){
					return order;
				}
			}
			return null;
		},
		modalClose(order) {
			this.modalShow = false;
			this.clearSelectedOrder();
			if(this.orders.filter(o => o.id === order.id).length){
				this.gridOptions.api.applyTransaction({update:[order]});
				this.gridOptions.api.redrawRows();
			}
		},
		modalMultipleClose(orders) {
			this.modalMultipleShow = false;
			// this.gridOptions.api.deselectAll(); // Deselect after close or not?
			this.gridOptions.api.applyTransaction({update:orders});
			this.gridOptions.api.redrawRows();
		},
		confirmModalShow(){
			this.confirmModalProperties = {
				show: true,
				title: 'Confirm',
				okButton: 'Send to Confirm',
				closeButton: 'Cancel'
			};
		},
		async confirmModalClose({shouldSend, confirmInformation}){
			this.confirmModalProperties.show = false;
			if(shouldSend && this.selectedOrders.length){
				for(let orderId of this.selectedOrders.map(x => x.id)) {
					this.sendOrderToConfirm({orderId: orderId, confirmInformation: confirmInformation});
				}
			}
			this.setSelectedOrders([]);
		},
		onDateAfterChanged(value){
			let dateValid = moment(value, 'DD.MM.YYYY').isValid();
			this.dateAfter = dateValid ? moment(value, 'DD.MM.YYYY').format('YYYYMMDD0000') : null;
		},
		onDateBeforeChanged(value){
			let dateValid = moment(value, 'DD.MM.YYYY').isValid();
			this.dateBefore = dateValid ? moment(value, 'DD.MM.YYYY').format('YYYYMMDD2359') : null;
		},
		loadOrdersByQuery(){
			if(this.afterInvalid || this.beforeInvalid) return;
			let params = {};
			if(this.dateAfter !== null){
				params.etd_ge = this.dateAfter;
			}
			if(this.dateBefore !== null) {
				params.etd_le = this.dateBefore;
			}
			//Which status orders should be searched
			//Changed each time different type is loaded, i.e active (0 < 100), completed, or deleted
			if(!this.queryStatus){
				params.status_ge = -5;
				params.status_lt = 100;
			} else {
				params.status_eq = this.queryStatus;
			}
			this.getOrdersByQuery(params);
		},
	},
	beforeMount() {
		this.gridOptions = {
			enableCellChangeFlash: true,
			domLayout: 'normal',
			suppressPropertyNamesCheck: true,
			suppressColumnMoveAnimation: true,
			pagination: false,
			// floatingFilter: true,
			context: {
				componentParent: this
			},
			onRowDoubleClicked: this.onRowSelected,
			suppressRowClickSelection: true,
			rowSelection: 'multiple',
			onSelectionChanged: this.onRowMultipleSelectionChanged,
			refreshCells: true,
			getRowNodeId: (data) => {return data.id;},
			dateComponentParams: {dateFormat: 'YYYYMMDDHHmm'},
			rowHeight: 48,
			valueCache: true,
			defaultColDef: {
				editable: false,
				headerClass: 'table-header',
				resizable: true,
				floatingFilter: true,
				filterParams: {
					buttons: ['clear'],
				},
				sortable: true,
				filter: true,
			},
		};
		this.columnDefs = [
			{
				field: 'customerLogo',
				headerName: '',
				width: 100,
				resizable: false,
				valueGetter: (params) => {
					if(!params.data.customerSettings) return;
					return params.data.customerSettings.name;
				},
				cellRenderer: (params) => {
					if(!params.data.customerSettings) return;
					var customerCode = params.data.customerSettings.code;
					if(customerCode) {
						let src = customerLogos[customerCode];
						return `<img data-name="logo" class="order-customer-logo" src="${src}">`;
					}
				},
			},
			{
				field: 'orderNo',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.orderNo),
					'red': 'true',
				},
				valueGetter: (params) => {
					return parseInt(params.data.orderNo);
				},
				cellRenderer: (params) => {
					let preOrder = params.data.isPreorder ? '<span class="isPreorder">(P)</span>' : '';
					let poRef = `${params.data.orderNo}<br>`;
					let refNo = `${params.data.transporter.referenceNo || ''}`;
					return `${preOrder} ${poRef} ${refNo}`;
					// if(!(params.data.seenBy || []).includes(this.currentUser.uid)){
					// 	return `${params.data.orderNo}*<br>
					// 	${params.data.transporter.referenceNo || ''}`;
					// } else {
					// 	return `${params.data.orderNo}<br>
					// 	${params.data.transporter.referenceNo || ''}`;
					// }
				},
				comparator: (a,b) => +a - +b
			},
			{
				field: 'poReference',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.poReference),
					'red': 'true',
				},
				valueGetter: (params) => {
					return parseInt(params.data.poReference);
				},
				comparator: (a,b) => +a - +b
			},
			{
				field: 'status',
				width: 150,
				sort: 'asc',
				sortIndex: 0,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.status),
					'green': (params) => params.data.status === 10 || params.data.status === 30
				},
				valueGetter: (params) => {
					return this.t(`orderStatus/${orderStatusValues(params.data.status)}`);
				},
				cellRenderer: (params) => {
					return this.t(`orderStatus/${orderStatusValues(params.data.status)}`);
				},
				comparator: (a,b, na, nb) => +na.data.status - +nb.data.status
			},
			{
				field: 'orderCollectionNo',
				headerName: 'TransportNeed No.',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.orderCollectionNo),
				},
				valueGetter: (params) => {
					return params.data.orderCollectionNo;
				},
			},
			{
				field: 'consignee',
				headerName: 'To',
				width: 350,
				cellClassRules: {
					'unseenChanges': ({data}) => Boolean(data.deliveryParty ? data.changeMap.deliveryParty : data.changeMap.consignee),
					'yellow': 'true',
					'left': 'true'
				},
				cellRenderer: (params) => {
					let party = params.data.deliveryParty ? params.data.deliveryParty : params.data.consignee;
					return addressRender(party);
				},
				valueGetter(params) {
					let party = params.data.deliveryParty ? params.data.deliveryParty : params.data.consignee;
					return `${party.companyName} ${party.street} ${party.postalPlace}`;
				},
			},
			{
				field: 'awbReference',
				headerName: 'AWB',
				width: 135,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.awbReference),
					'yellow': 'true'
				},
				valueGetter: (params) => {
					return params.data.awbReference;
				}
			},
			{
				field: 'quantity',
				headerName: 'Quantity',
				width: 135,
				editable: false, filter: false,
				sortable: false,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.freightLinesQuantity),
					'red': 'true'
				},
				valueGetter: (params) => {
					return params.data.freightLinesQuantity;
				},
			},
			{
				field: 'iataCode',
				headerName: 'Airport destination',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data}) => {
						let party = data.deliveryParty ? data.changeMap.deliveryParty : data.changeMap.consignee;
						return Boolean((party || {}).iataCode);
					},
					'yellow': 'true',
					'left': 'true'
				},
				valueGetter: (params) => {
					let party = params.data.deliveryParty || params.data.consignee;
					return party.iataCode;
				}
			},
			// Flight details
			{
				field: 'flightDetails',
				headerName: 'Flight details',
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.departingFlight || changeMap.arrivingFlight),
				},
				valueGetter: (params) => {
					return `${params.data.departingFlight || ''}${params.data.arrivingFlight || ''}`;
				},
				cellRenderer: (params) => {
					return `
						<div>${params.data.departingFlight || ''}</div>
						<div>${params.data.arrivingFlight || ''}</div>
					`;
				},
			},
			// ArrivalDay
			{
				field: 'etaDay',
				headerName: 'Arrival day',
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.eta),
					'red': 'true'
				},
				valueGetter: (params) => {
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').isoWeekday();
				},
				cellRenderer: (params) => {
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').format('dddd');
				},
			},
			// TimeOfArrival
			{
				field: 'etaTime',
				headerName: 'Time of arrival',
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.eta),
					'red': 'true'
				},
				valueGetter: (params) => {
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
				cellRenderer: (params) => {
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
			},
			// Terminal
			{
				field: 'departingTerminal',
				headerName: 'Terminal',
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.departingTerminal),
					'red': 'true'
				},
				valueGetter: (params) => {
					return params.data.departingTerminal;
				},
				cellRenderer: (params) => {
					return params.data.departingTerminal;
				},
			},
			// DeadlineTerminal
			{
				field: 'etdTime',
				headerName: 'Deadline terminal',
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.etd),
					'red': 'true'
				},
				valueGetter: (params) => {
					return moment(params.data.etd.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
				cellRenderer: (params) => {
					return moment(params.data.etd.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
			},
			// TerminalInfo
			{
				field: 'terminalInformation',
				headerName: 'Terminal information',
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.terminalInformation),
					'yellow': 'true'
				},
				valueGetter: (params) => {
					return params.data.terminalInformation;
				},
				cellRenderer: (params) => {
					return params.data.terminalInformation;
				},
			},
			// Rate
			{
				field: 'rate',
				headerName: 'Rate',
				cellClassRules: {
					'unseenChanges': ({data}) => {
						if(!data.transporter.priceItems.length) return;
						return Boolean(data.changeMap.freightLinesGrossWeight || (data.changeMap.transporter || {}).priceItems);
					},
					'yellow': 'true'
				},
				valueGetter: (params) => {
					return priceRate(params.data.transporter.priceItems, params.data.freightLinesGrossWeight);
				},
				cellRenderer: (params) => {
					return priceRateRenderer(params.data.transporter.priceItems, params.data.freightLinesGrossWeight);
				},
			},
			{
				field: 'consignor',
				headerName: 'From',
				width: 350,
				sort: 'asc',
				sortIndex: 2,
				cellClassRules: {
					'unseenChanges': ({data}) => Boolean(data.despatchParty ? data.changeMap.despatchParty : data.changeMap.consignor),
					'left': 'true'
				},
				cellRenderer: (params) => {
					let party = params.data.despatchParty ? params.data.despatchParty : params.data.consignor;
					return addressRender(party);
				},
				valueGetter(params) {
					let party = params.data.despatchParty ? params.data.despatchParty : params.data.consignor;
					return `${party.companyName} ${party.street} ${party.postalPlace}`;
				},
			},
			{
				field: 'grossWeight',
				headerName: 'Total gross weight',
				width: 135,
				editable: false, filter: false,
				sortable: false,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.freightLinesGrossWeight),
					'yellow': 'true',
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.freightLinesGrossWeight;
				},
				cellRenderer: (params) => {
					return params.data.freightLinesGrossWeight;
				},
			},
			{
				field: 'netWeight',
				headerName: 'Total net weight',
				width: 135,
				editable: false, filter: false,
				sortable: false,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.freightLinesNetWeight),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.freightLinesNetWeight;
				},
				cellRenderer: (params) => {
					return params.data.freightLinesNetWeight;
				},
			},
			{
				field: 'incotermsCode',
				headerName: 'Incoterms',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.incotermsCode || changeMap.incotermsPlace),
					'left': 'true'
				},
				valueGetter: (params) => {
					if(!params.data.incotermsCode || !params.data.incotermsPlace) return null;
					return `${params.data.incotermsCode}${params.data.incotermsPlace}`;
				},
				cellRenderer: (params) => {
					if(!params.data.incotermsCode || !params.data.incotermsPlace) return null;
					return `${params.data.incotermsCode} - ${params.data.incotermsPlace}`;
				},
			},
			{
				field: 'customsCrossingPoint',
				headerName: 'Border Crossing',
				width: 200,
				editable: false,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.customsCrossingPoint),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.customsCrossingPoint;
				},
				cellRenderer: (params) => {
					if(params.data.customsCrossingPoint){
						let ccp = this.customsPoints.find(x => x.tollst == params.data.customsCrossingPoint);
						return ccp ? ccp.tollsted : null;
					}
					return null;
				}
			},
			{
				field: 'priceItems',
				cellClass: 'left',
				headerName: 'Price',
				width: 150,
				valueGetter: (params) => {
					return priceItemsSum(params.data.transporter.priceItems);
				},
				cellRenderer: (params) => {
					return priceRenderer(params.data.transporter.priceItems);
				}
			},
			{
				field: 'vehicleLicensePlate',
				headerName: 'Vehicle No.',
				width: 200,
				editable: false,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.vehicleLicensePlate),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.vehicleLicensePlate;
				},
				cellRenderer: (params) => {
					return params.data.vehicleLicensePlate;
				}
			},
			{
				field: 'transporterInstructions',
				headerName: 'TransporterInstructions',
				width: 200,
				editable: false,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.transporterInstructions),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.transporterInstructions;
				},
				cellRenderer: (params) => {
					return params.data.transporterInstructions;
				}
			},
			{
				field: 'etd',
				sort: 'asc',
				sortIndex: 1,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.etd),
					'red': 'true',
					'left': 'true'
				},
				valueGetter: (params) => {
					var etd = params.data.etd;
					if(etd == null) return null;
					return moment(etd.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var etd = params.data.etd;
					return dateRender(etd, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			{
				field: 'eta',
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.eta),
					'red': 'true',
					'left': 'true'
				},
				valueGetter: (params) => {
					var eta = params.data.eta;
					if(eta == null) return null;
					return moment(eta.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var eta = params.data.eta;
					return dateRender(eta, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			// {
			// 	field: 'atd',
			// 	cellClass: 'left',
			// 	valueGetter: (params) => {
			// 		var atd = params.data.atd;
			// 		if(atd == null) return null;
			// 		return moment(atd.date, 'YYYYMMDDHHmm');
			// 	},
			// 	cellRenderer: (params) => {
			// 		var atd = params.data.atd;
			// 		return dateRender(atd, 'DD.MM.YY');
			// 	},
			// 	width: 171,
			// 	comparator: dateCompare,
			// 	filterFramework: DateFilter,
			// 	filterParams: {
			// 		dateFormat: 'DD.MM.YY',
			// 		clearButton: true
			// 	},
			// 	floatingFilterComponentFramework: FloatingDateFilter,
			// 	floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			// },
			// {
			// 	field: 'ata',
			// 	cellClass: 'left',
			// 	valueGetter: (params) => {
			// 		var ata = params.data.ata;
			// 		if(ata == null) return null;
			// 		return moment(ata.date, 'YYYYMMDDHHmm');
			// 	},
			// 	cellRenderer: (params) => {
			// 		var ata = params.data.ata;
			// 		return dateRender(ata, 'DD.MM.YY');
			// 	},
			// 	width: 171,
			// 	comparator: dateCompare,
			// 	filterFramework: DateFilter,
			// 	filterParams: {
			// 		dateFormat: 'DD.MM.YY',
			// 		clearButton: true
			// 	},
			// 	floatingFilterComponentFramework: FloatingDateFilter,
			// 	floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			// },
			{
				field: 'id', pinned: 'left',
				lockPinned: true,
				width: 34,
				cellClass: 'left',
				headerName: '',
				editable: false, filter: false,
				resizable: false, sortable: false,
				cellRendererFramework: Actions,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true
			},
		];
		this.rowClassRules = {
			// 'unseenChanges': (params) => {
			// 	let {seenBy = []} = params.data;
			// 	return !seenBy.includes(this.currentUser.uid);
			// }
			'canceled': (params) => {
				return params.data.status === -5;
			}
		};
	},
	async mounted() {
		var orderId = this.$route.params.orderId;
		if(orderId){
			this.$router.replace('/');
			let eventMock = {data:{id:orderId}}; //Hack into onRowSelected
			await this.onRowSelected(eventMock);
		}
		// Sort model must be set after gridOptions are defined
		// this.gridOptions.api.setSortModel(this.defaultGridSort); // Stored in state

		this.setStoredGridOptions(this.gridOptions);
		this.loadUserDefinedColumnDefinitions();
	}
};

function addressRender(party) {
	if(!party) return;
	var firstLine = party.companyName || '';
	var secondLine = [party.street, party.postalPlace].filter(x => !!x);
	secondLine = secondLine.join(', ');
	return `
		<div class="firstAddressLine">${firstLine}</div>
		<div class="secondAddressLine">${secondLine}</div>
	`;
}
function dateRender(dateObject, dateFormat) {
	var date = moment(dateObject.date, 'YYYYMMDDHHmm');
	var value = date.format(dateFormat);
	if (dateObject.hasTime) value += date.format(' HH:mm');
	if (!date.isValid()) return null;
	return `
        <div class="dateValue">${value}</div>
    `;
}
// function dateRender(dateObject, dateFormat) {
// 	var date = moment(dateObject.date, 'YYYYMMDDHHmm');
// 	//date.tz(dateObject.timeZone);
// 	var value = date.format(dateFormat);
// 	if (dateObject.hasTime) value += date.format(' HH:mm');
// 	if (!date.isValid()) return null;
// 	let weekday =  moment(dateObject.date, 'YYYYMMDDHHmm').format('ddd');
// 	let dow = moment(dateObject.date, 'YYYYMMDDHHmm').isoWeekday() - 1;
// 	return `
//         <div class="dateValue">${value}</div>
//         <div class="dateDay" style="--dow:${dow};">${weekday}</div>
//     `;
// }
function dateCompare(a, b) {
	// return a.dateObject.valueOf() - b.dateObject.valueOf();
	// let first = a.date;
	// let second = b.date;
	// if(first == null) first = 0;
	// if(second == null) second = 0;
	return a.valueOf() - b.valueOf();
}
function priceRateRenderer(items = [], grossWeight){
	if(!items[0]) return 0;
	var sameCurrency = true;
	for(let item of items) {
		if(items.some(i => (i !== item) && (i.currency !== item.currency))){
			sameCurrency = false;
			break;
		}
	}
	if(!sameCurrency) {
		return 'Unable to calculate.\nDifferent currencies.';
	}
	return `${items[0].currency} ${priceRate(items, grossWeight)}`;
}
function priceRate(items = [], grossWeight){
	if(!items[0]) return 0;
	let prices = items.filter(i => i.type === 'frakt');
	let sum = prices.reduce( (sum, item) => {
		return sum + item.grossAmount;
	}, 0);
	let rate = (sum / grossWeight).toFixed(2);
	return rate;
}
function priceRenderer(items){
	if(!items || !items[0]) return 0;
	var sameCurrency = true;
	for(let item of items) {
		if(items.some(i => (i !== item) && (i.currency !== item.currency))){
			sameCurrency = false;
			break;
		}
	}
	if(!sameCurrency) {
		return 'Unable to calculate.\nDifferent currencies.';
	}
	return `${items[0].currency} ${priceItemsSum(items)}`;
}
function priceItemsSum(items){
	if(!items || !items[0]) return 0;
	return items.reduce((t, i) => {
		return i.netAmount + t;
	}, 0).toFixed(2);
}
</script>

<style lang="scss" scoped>
.ordersGrid {
    display: grid;
    grid-template-rows: 134px auto 0px;
    grid-template-areas: 'tools' 'grid';
}
.ordersGrid .grid {
	grid-area: grid;
}
.ordersGrid .grid /deep/ .firstAddressLine {
    font-size: 1em;
}
.ordersGrid .grid /deep/ .secondAddressLine {
    font-size: 0.75em;
}
.ordersGrid .grid /deep/ .dateValue {
    font-size: 1em;
}
.ordersGrid .grid /deep/ .dateDay {
    font-size: 0.75em;
	padding-left: calc(15.5px * var(--dow));
}
.ordersGrid .grid /deep/ .isPreorder {
    float: left;
}
.ordersGrid .grid /deep/ .ag-row.unseenChanges {
	background-color: lighten(#ffc107, 35%);
}
.ordersGrid .grid /deep/ .ag-row.canceled {
	background-color: #b8a9a97f;
}
.ordersGrid .grid /deep/ .ag-cell.unseenChanges {
	background-color: lighten(#ffc107, 35%);
}
.ordersGrid .grid /deep/ .ag-cell.unseenChanges.green {
	background-color: lighten(#0fff07, 35%);
}
.ordersGrid .grid /deep/ .ag-cell.unseenChanges.red {
	background-color: lighten(#ff0707, 35%);
}
.ordersGrid .grid /deep/ .ag-cell.unseenChanges.yellow {
	background-color: lighten(#ffc107, 10%);
}
.ordersGrid .grid /deep/ .ag-cell,
.ordersGrid .grid /deep/ .ag-header-cell,
.ordersGrid .grid /deep/ .ag-pinned-left-cols-container .ag-cell,
.ordersGrid .grid /deep/ .ag-pinned-left-header .ag-header-cell {
	border-right: 1px solid #e6e6e6 !important;
}
.ordersGrid .grid /deep/ .ag-cell.ag-cell-focus,
.ordersGrid .grid /deep/ .ag-pinned-left-cols-container .ag-cell.ag-cell-focus {
    border: 1px solid darkgrey !important;
}
.ordersGrid .grid /deep/ .ag-cell.left {
    text-align: left;
}
.ordersGrid .grid /deep/ .ag-cell.mono {
	font-family: 'Roboto Mono';
}
.ordersGrid .grid /deep/ .ag-root {
	border-top: 1px solid #e6e6e6 !important;
}
.ordersGrid .grid /deep/ .order-customer-logo {
    max-width: 4rem;
    max-height: 2rem;
    width: auto;
    height: auto;
}
.toolsContainer .tool {
	margin: 7px 0;
	display: flex;
	justify-content: center;
}
.b-btn {
	margin: 0 5px;
}
.toolsContainer {
	grid-area: tools;
	display: grid;
	padding:15px;
	grid-template-columns:1fr;
	grid-template-rows:1fr 1fr;
	grid-template-areas:
		'leftTool' 'rightTool';
}
@media (min-width: 425px) {
	.ordersGrid {
		grid-template-rows: 102px auto 0px;
	}
}
@media(min-width: 768px){
	.toolsContainer .tool.left {
		justify-content: flex-start;
	}
	.toolsContainer .tool.right {
		justify-content: flex-end;
	}
	.toolsContainer {
		grid-gap: 10px;
		grid-template-columns:1fr 1fr;
		grid-template-rows:1fr;
		grid-template-areas:
			'leftTool rightTool';
	}
	.ordersGrid {
		grid-template-rows: 66px auto 0px;
	}
}
</style>
