<template>
	<div class="componentContainer">
		<div class="summaryWrapper border" style="grid-area:estimated;">
			<b-input-group class="datetimepicker-input-group">
				<div class="summaryHeader etd">
					<!--
						Etd - Estimated time departure
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Estimated time departure"
					:value="etd"
					:disabled="!editable"
					:invalid="etdInvalid"
					class="datetime-container first"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'etd')"
				></date-picker>
				<div class="summaryHeader eta">
					<!--
						Eta / Estimated time arrival
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Estimated time arrival"
					:value="eta"
					:disabled="!editable"
					:invalid="etaInvalid"
					class="datetime-container last"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'eta')"
				></date-picker>
			</b-input-group>
		</div>
		<div class="summaryWrapper border" style="grid-area:actual;">
			<b-input-group class="datetimepicker-input-group">
				<div class="summaryHeader atd">
					<!--
						Atd - Actual time departure
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Actual time departure"
					:value="atd"
					:disabled="!editable || atdSetByConfirm"
					:invalid="atdInvalid"
					class="datetime-container first"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'atd')"
				></date-picker>
				<div class="summaryHeader ata">
					<!--
						Ata - Actual time arrival
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Actual time arrival"
					:value="ata"
					:disabled="!editable || ataSetByConfirm"
					:invalid="ataInvalid"
					class="datetime-container last"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'ata')"
				></date-picker>
			</b-input-group>
		</div>
		<div class="summaryWrapper border" style="grid-area:awb;">
			<div class="summaryHeader">
				Air Waybill
			</div>
			<b-form-input
				size="sm"
				title="Air Waybill number"
				type="text"
				:disabled="!editable"
				:value="awbReference"
				@change="onAwbReferenceChanged($event)"
				:state="validityChecked ? awbReferenceValid : null"
			></b-form-input>
			<b-form-invalid-feedback :state="validityChecked ? awbReferenceValid : null">
				Format of airwaybill must be <em>xxx-xxxxxxxx</em>
			</b-form-invalid-feedback>
		</div>
		<div class="summaryWrapper border" style="grid-area:customs;">
			<div class="summaryHeader">
				Customs crossing point
			</div>
			<b-input-group>
				<b-form-select
					class="modalSelect"
					:class="{ 'font-italic': !customsPointSelected }"
					size="sm"
					title="Customs border crossing point"
					v-model="customsPointSelected"
					@change="onCustomsCrossingChanged($event)"
					:disabled="!editable"
				>
					<option class="font-italic" :value="null"></option>
					<option
						v-for="point in customsPoints"
						class="customs-point"
						style="font-style:normal;"
						:key="point.tollst"
						:value="point.tollst"
						v-text="point.tollsted"
						:disabled="point.disabled"
					/>
				</b-form-select>
			</b-input-group>
		</div>
		<div class="summaryWrapper border" style="grid-area:vehicle;">
			<b-input-group class="double-header">
				<div class="summaryHeader">
					Vehicle No.
				</div>
				<b-form-input
					size="sm"
					title="License Plate"
					type="text"
					:value="vehicleLicensePlate"
					@change="onLicensePlateChanged($event)"
					:state="validityChecked ? vehicleLicenseValid : null"
					:disabled="!editable || disableVehicle"
				></b-form-input>
				<div class="summaryHeader">
					Vehicle nationality
				</div>
				<b-form-select
					class="modalSelect"
					:class="{ 'font-italic': !vehicleNationalitySelected}"
					size="sm"
					title="Vehicle Nationality"
					v-model="vehicleNationalitySelected"
					@change="onVehicleNationalityChanged($event)"
					:state="validityChecked ? vehicleNationalityValid : null"
					:disabled="!editable || disableVehicle"
				>
					<option class="font-italic" :value="null"></option>
					<option
						v-for="(nation, index) in nationality"
						style="font-style:normal;"
						:key="index"
						:value="nation.value"
						v-text="nation.text"
					/>
				</b-form-select>
			</b-input-group>
			<b-form-invalid-feedback :state="validityChecked ? vehicleFeedbackState : null">
				Both values must be set
			</b-form-invalid-feedback>
		</div>
		<div class="summaryWrapper border" style="grid-area:driver;">
			<b-input-group class="double-header">
				<div class="summaryHeader">
					Vehicle type
				</div>
				<b-form-input
					size="sm"
					title="Vehicle type"
					type="text"
					:disabled="!editable || disableVehicle"
					:value="vehicleType"
					@change="onVehicleTypeChanged($event)"
				></b-form-input>
				<div class="summaryHeader">
					Drivers name
				</div>
				<b-form-input
					size="sm"
					title="Drivers name"
					type="text"
					:disabled="!editable || disableVehicle"
					:value="vehicleDriverName"
					@change="onVehicleDriverNameChanged($event)"
				></b-form-input>
			</b-input-group>
		</div>
		<div class="summaryWrapper border" style="grid-area:flight;">
			<b-input-group class="double-header" v-if="!disableFlight && disableVehicle">
				<div class="summaryHeader">
					Departing flight
				</div>
				<b-form-input
					size="sm"
					title="Departing flight"
					type="text"
					:value="departingFlight"
					@change="onDepartingFlightChanged($event)"
					:state="validityChecked ? departingFlightValid : null"
					:disabled="!editable || disableFlight"
				></b-form-input>
				<div class="summaryHeader">
					Arriving flight
				</div>
				<b-form-input
					size="sm"
					title="Arriving flight"
					type="text"
					:value="arrivingFlight"
					@change="onArrivingFlightChanged($event)"
					:state="validityChecked ? arrivingFlightValid : null"
					:disabled="!editable || disableFlight"
				></b-form-input>
			</b-input-group>
			<b-input-group class="double-header" v-else-if="!disableVehicle && disableFlight">
				<div class="summaryHeader">
					Departing vehicle
				</div>
				<b-form-input
					size="sm"
					title="Departing vehicle"
					type="text"
					:value="departingVehicle"
					@change="onDepartingVehicleChanged($event)"
					:state="validityChecked ? departingVehicleValid : null"
					:disabled="!editable || disableVehicle"
				></b-form-input>
				<div class="summaryHeader">
					Arriving vehicle
				</div>
				<b-form-input
					size="sm"
					title="Arriving vehicle"
					type="text"
					:value="arrivingVehicle"
					@change="onArrivingVehicleChanged($event)"
					:state="validityChecked ? arrivingVehicleValid : null"
					:disabled="!editable || disableVehicle"
				></b-form-input>
			</b-input-group>
			<b-input-group class="double-header" v-else>
				<div class="summaryHeader">Departing</div>
				<b-form-input size="sm" title="Departing" type="text" :disabled="true"></b-form-input>
				<div class="summaryHeader">Arriving</div>
				<b-form-input size="sm" title="Arriving" type="text" :disabled="true"></b-form-input>
			</b-input-group>
			<b-form-invalid-feedback :state="validityChecked ? pickupAndDeliveryValid : null">
				Both values must be set
			</b-form-invalid-feedback>
		</div>
		<div class="summaryWrapper border" style="grid-area:terminalInfo;">
			<div class="summaryHeader">
				Terminal information
			</div>
			<b-form-input
				size="sm"
				title="Terminal information"
				type="text"
				:disabled="!editable || disableFlight"
				:value="terminalInformation"
				@change="onTerminalInformationChanged($event)"
			></b-form-input>
		</div>
	</div>
</template>

<script>
import DatePicker from './DatePicker.vue';
import moment from 'moment';
import { mapState, mapMutations } from '../store';

export default {
	name: 'OrderDelivery',
	props: ['editable'],
	components: {
		DatePicker
	},
	data() {
		return {
			customsPointSelected: null,
			vehicleNationalitySelected: null,
		};
	},
	computed: {
		...mapState({
			orderData: 'selectedOrderData',
			customsPoints: 'customsPoints',
			nationality: 'nationality',
			validityChecked: 'validityChecked',
		}),
		atd() {
			return moment(this.orderData.atd.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
		},
		ata() {
			return moment(this.orderData.ata.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
		},
		etd() {
			return moment(this.orderData.etd.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
		},
		eta() {
			return moment(this.orderData.eta.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
		},
		atdSetByConfirm() {
			return this.orderData.atd.setByConfirm;
		},
		ataSetByConfirm() {
			return this.orderData.ata.setByConfirm;
		},
		awbReference() {
			return this.orderData.awbReference;
		},
		crossingPoint() {
			return this.orderData.customsCrossingPoint;
		},
		vehicleLicensePlate() {
			return this.orderData.vehicleLicensePlate;
		},
		vehicleNationality() {
			return this.orderData.vehicleNationality || null;
		},
		vehicleDriverName() {
			return this.orderData.vehicleDriverName;
		},
		vehicleType() {
			return this.orderData.vehicleType;
		},
		departingVehicle() {
			return this.orderData.departingVehicle;
		},
		arrivingVehicle() {
			return this.orderData.arrivingVehicle;
		},
		departingFlight() {
			return this.orderData.departingFlight;
		},
		arrivingFlight() {
			return this.orderData.arrivingFlight;
		},
		terminalInformation() {
			return this.orderData.terminalInformation;
		},
		ataInvalid(){
			if(this.orderData.ata.date !== null){
				let long = 'YYYYMMDDHHmm';
				let atd = moment(this.orderData.atd.date, 'YYYYMMDDHHmm').isValid();
				let ataLong = moment(this.orderData.ata.date, long);
				if(
					!(atd ? ataLong.isSameOrAfter(moment(this.orderData.atd.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		atdInvalid(){
			if(this.orderData.atd.date !== null){
				let long = 'YYYYMMDDHHmm';
				let ata = moment(this.orderData.ata.date, 'YYYYMMDDHHmm').isValid();
				let atdLong = moment(this.orderData.atd.date, long);
				if(
					!(ata ? atdLong.isSameOrBefore(moment(this.orderData.ata.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		etaInvalid(){
			if(this.orderData.eta.date !== null){
				let long = 'YYYYMMDDHHmm';
				let etd = moment(this.orderData.etd.date, 'YYYYMMDDHHmm').isValid();
				let etaLong = moment(this.orderData.eta.date, long);
				if(
					!(etd ? etaLong.isSameOrAfter(moment(this.orderData.etd.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		etdInvalid(){
			if(this.orderData.etd.date !== null){
				let long = 'YYYYMMDDHHmm';
				let eta = moment(this.orderData.eta.date, 'YYYYMMDDHHmm').isValid();
				let etdLong = moment(this.orderData.etd.date, long);
				if(
					!(eta ? etdLong.isSameOrBefore(moment(this.orderData.eta.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		vehicleLicenseValid(){
			if(
				!this.orderData.vehicleLicensePlate &&
				this.orderData.vehicleNationality
			){
				return false;
			}
			return null;
		},
		vehicleNationalityValid(){
			if(
				this.orderData.vehicleLicensePlate &&
				!this.orderData.vehicleNationality
			){
				return false;
			}
			return null;
		},
		vehicleFeedbackState(){
			if(
				(this.orderData.vehicleLicensePlate && !this.orderData.vehicleNationality) ||
				(!this.orderData.vehicleLicensePlate && this.orderData.vehicleNationality)
			){
				return false;
			}
			return null;
		},
		awbReferenceValid(){
			if(
				this.orderData.awbReference &&
				!(this.orderData.awbReference).match('([0-9]{3})-([0-9]{8})')
			){
				return false;
			}
			return null;
		},
		departingFlightValid(){
			if(
				!this.orderData.departingFlight &&
				this.orderData.arrivingFlight
			){
				return false;
			}
			return null;
		},
		arrivingFlightValid(){
			if(
				this.orderData.departingFlight &&
				!this.orderData.arrivingFlight
			){
				return false;
			}
			return null;
		},
		flightFeedbackState(){
			if(
				(this.orderData.departingFlight && !this.orderData.arrivingFlight) ||
				(!this.orderData.departingFlight && this.orderData.arrivingFlight)
			){
				return false;
			}
			return null;
		},
		departingVehicleValid(){
			if(
				!this.orderData.departingVehicle &&
				this.orderData.arrivingVehicle
			){
				return false;
			}
			return null;
		},
		arrivingVehicleValid(){
			if(
				this.orderData.departingVehicle &&
				!this.orderData.arrivingVehicle
			){
				return false;
			}
			return null;
		},
		pickupAndDeliveryValid(){
			if(
				((this.orderData.departingFlight && !this.orderData.arrivingFlight) || (!this.orderData.departingFlight && this.orderData.arrivingFlight)) ||
				((this.orderData.departingVehicle && !this.orderData.arrivingVehicle) || (!this.orderData.departingVehicle && this.orderData.arrivingVehicle))
			) {
				return false;
			}
			return null;
		},
		deliveryInformationValid(){
			var valid = (
				this.vehicleFeedbackState === null &&
				this.flightFeedbackState === null &&
				this.awbReferenceValid === null &&
				!this.etdInvalid && !this.etaInvalid &&
				!this.atdInvalid && !this.ataInvalid
			);
			return valid;
		},
		disableVehicle(){
			if(
				this.orderData.departingFlight ||
				this.orderData.arrivingFlight ||
				this.orderData.terminalInformation ||
				this.orderData.departingTerminal ||
				(this.orderData.deliveryParty ? this.orderData.deliveryParty.iataCode : this.orderData.consignee.iataCode)
			){
				return true;
			}
			return false;
		},
		disableFlight(){
			if(
				this.orderData.departingVehicle ||
				this.orderData.arrivingVehicle ||
				this.orderData.vehicleLicensePlate ||
				this.orderData.vehicleNationality ||
				this.orderData.vehicleDriverName ||
				this.orderData.vehicleType
			) {
				return true;
			}
			return false;
		},
	},
	watch: {
		deliveryInformationValid: {
			handler: function(val){
				this.$emit('validStateChanged', val);
			},
			immediate: true
		},
	},
	methods: {
		...mapMutations([
			'toggleHasChanges',
			'updateSelectedOrderData',
		]),
		onDateChanged(value, inputType){
			// console.log(moment(moment(value, 'DD.MM.YYYY HH:mm').format('YYYYMMDDHHmm')));
			let dateValid = moment(value, 'DD.MM.YYYY HH:mm').isValid();
			let setByConfirm = this.orderData[inputType].setByConfirm;
			let newDateObj = {
				date: dateValid ? moment(value, 'DD.MM.YYYY HH:mm').format('YYYYMMDDHHmm') : null,
				hasTime: dateValid,
				timeZone: 'Europe/Oslo'
			};
			// console.log(parseInt(this.orderData[inputType].date) !== parseInt(newDateObj.date), moment().format('HH:mm:ss'));
			if(parseInt(this.orderData[inputType].date) !== parseInt(newDateObj.date) && !setByConfirm){
				this.$set(this.orderData, inputType, newDateObj);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onAwbReferenceChanged(value){
			if(value === '') value = null;
			if(this.orderData.awbReference !== value){
				this.$set(this.orderData, 'awbReference', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onCustomsCrossingChanged(value){
			if(value === 'null') value = null;
			if(this.orderData.customsCrossingPoint !== value){
				this.$set(this.orderData, 'customsCrossingPoint', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onLicensePlateChanged(value){
			if(value === '') value = null;
			if(this.orderData.vehicleLicensePlate !== value){
				this.$set(this.orderData, 'vehicleLicensePlate', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onVehicleNationalityChanged(value){
			if(value === 'null') value = null;
			if(this.orderData.vehicleNationality !== value){
				this.$set(this.orderData, 'vehicleNationality', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onVehicleDriverNameChanged(value){
			if(value === '') value = null;
			if(this.orderData.vehicleDriverName !== value){
				this.$set(this.orderData, 'vehicleDriverName', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onVehicleTypeChanged(value){
			if(value === '') value = null;
			if(this.orderData.vehicleType !== value){
				this.$set(this.orderData, 'vehicleType', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onDepartingVehicleChanged(value){
			if(value === '') value = null;
			if(this.orderData.departingVehicle !== value){
				this.$set(this.orderData, 'departingVehicle', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onArrivingVehicleChanged(value){
			if(value === '') value = null;
			if(this.orderData.arrivingVehicle !== value){
				this.$set(this.orderData, 'arrivingVehicle', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onDepartingFlightChanged(value){
			if(value === '') value = null;
			if(this.orderData.departingFlight !== value){
				this.$set(this.orderData, 'departingFlight', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onArrivingFlightChanged(value){
			if(value === '') value = null;
			if(this.orderData.arrivingFlight !== value){
				this.$set(this.orderData, 'arrivingFlight', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onTerminalInformationChanged(value){
			if(value === '') value = null;
			if(this.orderData.terminalInformation !== value){
				this.$set(this.orderData, 'terminalInformation', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
	},
	beforeMount() {
		this.customsPointSelected = this.orderData.customsCrossingPoint;
		this.vehicleNationalitySelected = this.vehicleNationality;
	}
};
</script>

<style lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'estimated' 'actual' 'awb' 'customs' 'vehicle' 'driver' 'flight' 'terminalInfo';
}
@media (min-width: 768px) {
	.componentContainer {
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: minmax(min-content, max-content);
		grid-template-areas:
			'estimated actual'
			'awb customs'
			'vehicle driver'
			'flight terminalInfo';
	}
}
.summaryHeader {
	background-color: inherit;
	text-align: left;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 1rem;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.modalSelect {
	padding-top: 0;
	padding-bottom: 0;
}

.invalid-feedback {
	margin-top: 0.25rem;
	position: absolute;
}

.datetimepicker-input-group > .datetime-container {
    width: 50%;
}
.datetimepicker-input-group .datetime-container.first /deep/ input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.datetimepicker-input-group .datetime-container.last /deep/ input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.datetimepicker-input-group > .summaryHeader {
    left: 0;
}
.datetimepicker-input-group > .summaryHeader ~ .summaryHeader {
    left: 50%;
}
.double-header > .summaryHeader {
    left: 0;
}
.double-header > .summaryHeader ~ .summaryHeader {
    left: 50%;
}

.summaryHeader.etd:before {
	content: "Etd";
}
.summaryHeader.eta:before {
	content: "Eta";
}
.summaryHeader.atd:before {
	content: "Atd";
}
.summaryHeader.ata:before {
	content: "Ata";
}
@media (min-width: 768px){
	.summaryHeader.etd:before {
		content: "Estimated time departure";
	}
	.summaryHeader.eta:before {
		content: "Estimated time arrival";
	}
	.summaryHeader.atd:before {
		content: "Actual time departure";
	}
	.summaryHeader.ata:before {
		content: "Actual time arrival";
	}
}
option.customs-point:disabled {
	display: none;
	pointer-events: none;
}
</style>
