<template>
	<div class="dropbox">
		<div class="content">
			<font-awesome-layers>
				<font-awesome-icon class="fa-md" :icon="['fas', 'spinner']" spin />
			</font-awesome-layers>
		</div>
	</div>
</template>

<script>
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas);
library.add(far);

export default {
	name: 'LoadingDocument',
	components: {
		'font-awesome-icon': FontAwesomeIcon,
		'font-awesome-layers': FontAwesomeLayers
	},
};
</script>

<style lang="scss" scoped>
$text-color: #9fa8b0;
$white: #fff;
// .uploadContainer{
// 	height:100%;
// }
.dropbox {
	border: 2px dashed lighten($color: $text-color, $amount: 10%); /* the dash box */
	border-radius: 20px;
    color: $text-color;
	min-height: 185px;
	position: relative;
	cursor: pointer;
    transition: background-color 175ms;
}

// .input-file {
// 	opacity: 0; /* invisible but it's there! */
// 	left: 0;
// 	top: 0;
// 	width: 100%;
// 	min-height: 185px;
// 	position: absolute;
// 	cursor: pointer;
//     z-index: 9998;
// }

.dropbox:hover {
	background-color: #f7f7f7;
}
.dropbox .content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.content .fa-layers {
	color: lighten($color: $text-color, $amount: 10%);
	height:3rem;
	width:3rem;
	font-size:2rem;
}

</style>
