<template>
	<div class="componentContainer">
		<div class="summaryWrapper border flex-fill" style="grid-area:references;">
			<div class="summaryHeader">
				References
			</div>
			<ul class="referenceList">
				<li>
					<div class="referenceHeader">Transporter reference</div>
					<b-form-input
						size="sm"
						title="Transporter reference"
						type="text"
						:value="referenceNo"
						:disabled="!editable"
						@change="onReferenceNoChanged($event)"
					></b-form-input>
				</li>
				<li>
					<div class="referenceHeader">TransportNeed reference</div>
					<div>{{ this.orderData.orderCollectionNo }}</div>
				</li>
				<li>
					<div class="referenceHeader">PO reference</div>
					<div>{{ this.orderData.poReference }}</div>
				</li>
				<li>
					<div class="referenceHeader">Customer</div>
					<div>{{ this.orderData.customerSettings.name }}</div>
				</li>
				<li>
					<div class="referenceHeader">Order Category</div>
					<div>{{ this.orderData.orderCategory }}</div>
				</li>
				<li>
					<div class="referenceHeader">Transporter instructions</div>
					<div>{{ this.orderData.transporterInstructions }}</div>
				</li>
			</ul>
		</div>
		<div class="summaryWrapper border" style="grid-area:summary;">
			<div class="summaryHeader">
				Summary
			</div>
			<ul class="summaryList">
				<li>
					<div class="referenceHeader">Quantity</div>
					<span>{{ this.freightLinesQuantity }}</span>
				</li>
				<li>
					<div class="referenceHeader">G.Weight (kg)</div>
					<b-form-input
						title="Total gross weight"
						type="number"
						size="sm"
						v-model="grossWeightSum"
						:disabled="!editable"
						style="text-align:right;"
						@change="onFreightLinesGrossWeightChanged"
						number
					></b-form-input>
				</li>
				<li>
					<div class="referenceHeader">Volume (m<sup>3</sup>)</div>
					<span>{{ this.freightLinesVolume }}</span>
				</li>
			</ul>
		</div>
		<div class="summaryWrapper border" style="grid-area:contact;">
			<div class="summaryHeader">
				Contact
			</div>
			<div class="summaryContainer flex-fill" v-if="this.orderData.clerk">
				<ul class="contactList">
					<li>
						<font-awesome-icon icon="user" class="fa-md"/>
						<span>{{ this.orderData.clerk.name }}</span>
					</li>
					<li>
						<font-awesome-icon icon="phone" class="fa-md"/>
						<span><a :href="`tel:${this.trimClerkPhone}`">{{ this.orderData.clerk.phone }}</a></span>
					</li>
					<li>
						<font-awesome-icon icon="at" class="fa-md"/>
						<span><a :href="`mailto:${this.orderData.clerk.email}`">{{ this.orderData.clerk.email }}</a></span>
					</li>
				</ul>
			</div>
			<div class="summaryContainer flex-fill" v-else>
				<ul class="contactList">
					<li>
						<span>No contact information</span>
					</li>
				</ul>
			</div>
		</div>
		<order-price-list
			:editable="editable"
			@validStateChanged="priceInformationValid"
			style="grid-area:prices;"
		/>
	</div>
</template>

<script>
import { mapState, mapMutations } from '../store';

import OrderPriceList from './ComponentPrice.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
	name: 'OrderSummary',
	props: ['editable'],
	components: {
		'font-awesome-icon':FontAwesomeIcon,
		OrderPriceList,
	},
	data() {
		return {
			grossWeightSum: 0,
			validState: {
				priceInformation: true
			}
		};
	},
	computed: {
		...mapState({
			orderData: 'selectedOrderData'
		}),
		trimClerkPhone(){
			if(!this.orderData.clerk) return null;
			if(!this.orderData.clerk.phone)
				return null;
			return this.orderData.clerk.phone.replace(/\s/g, '');
		},
		freightLinesQuantity(){
			return this.orderData.freightLines.map(item => item.quantity).reduce((prev, next) => prev + next, 0);
		},
		freightLinesGrossWeight(){
			return +(this.orderData.freightLines.map(item => item.grossWeight).reduce((prev, next) => prev + next, 0).toFixed(2));
		},
		freightLinesVolume(){
			return +(this.orderData.freightLines.map(item => item.volume).reduce((prev, next) => +prev + +next, 0)).toFixed(2);
		},
		referenceNo() {
			return this.orderData.transporter.referenceNo;
		},
		informationValid(){
			for(var key in this.validState){
				if(!this.validState[key]) return false;
			}
			return true;
		},
	},
	watch: {
		informationValid: function(val) {
			this.$emit('validStateChanged', val);
		}
	},
	methods: {
		...mapMutations([
			'toggleHasChanges',
			'updateSelectedOrderData',
		]),
		onReferenceNoChanged(value){
			if(value === '') value = null;
			if(this.orderData.transporter.referenceNo !== value){
				this.orderData.transporter.referenceNo = value;
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onFreightLinesGrossWeightChanged(value){
			if(!value) value = this.freightLinesGrossWeight;
			value = parseFloat(value).toFixed(0);
			if(this.freightLinesGrossWeight != value){
				let sum = this.freightLinesGrossWeight;
				let lines = this.orderData.freightLines || [];
				for(let line of lines) {
					let itemIndex = lines.findIndex(l => l.id === line.id);
					let factor = line.grossWeight/sum;
					let newWeight = +parseFloat(factor*value).toFixed(3);
					this.$set(lines[itemIndex], 'grossWeight', newWeight);
				}
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
			this.grossWeightSum = value;
		},
		priceInformationValid(val){
			this.validState.priceInformation = val;
		}
	},
	mounted() {
		this.grossWeightSum = this.freightLinesGrossWeight;
	}
};
</script>

<style  lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'references' 'summary' 'contact' 'prices';
}
@media (min-width: 768px) {
	.componentContainer {
		grid-template-columns: 1fr 1fr 3fr;
		grid-template-rows: auto 1fr;
		grid-template-areas:
			'references summary prices'
			'references contact prices';
	}
}
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 12px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	display:flex;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.referenceList,
.contactList,
.priceList {
	margin: 1rem;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.summaryList {
	margin: 1rem;
	padding-left: 0;
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 3fr 2fr;
	li {
		display: inline-block;
		margin: 0.3rem;
		span {
			line-height: 24px;
		}
	}
}
.referenceList li,
.contactList li,
.priceList li {
	display: inline-block;
	margin: 0.3rem;
	min-width: calc( 100% - 0.6rem);
	text-align: left;
}
.referenceList li > span,
.contactList li > span {
	margin-left: 0.5rem;
}
.referenceHeader {
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryContainer {
	margin: 1rem;
	display: flex;
	flex-flow: column;
}
div.row > div {
	display: flex;
	flex-direction: column;
}
.summaryWrapper.flex-fill {
	flex: 1;
}
.modalSelect {
	padding-top: 0;
	padding-bottom: 0;
}
.priceItem {
	display: flex;
	justify-content: space-between;
}
.b-btn {
	margin: 5px;
}
</style>

