<template>
	<div class="summaryWrapper border">
		<div class="summaryHeader">
			Documents
		</div>
		<ul class="documentList">
			<template
				v-if="orderData.externalDocuments"
			>
				<li
					v-for="document in externalDocuments"
					:key="document.id"
					class="externalDocument"
					:title="document.description"
				>
					<a
						:href="document.documentUrl"
						target="_blank"
						style="margin:0 auto;"
					>
						<img :src="document.documentThumbnailUrl" style="display:block;border-bottom:1px solid gray" @load="setImageBottomMargin($event.target)">
					</a>
					<div class="documentOptions">
						<input type="text" disabled :value="document.description" size="4">
					</div>
				</li>
			</template>
			<li
				v-for="(document, index) in uploadedFiles"
				:key="index"
				class="uploadedDocument"
				:title="document.description"
				v-show="document.thumbnailLoaded"
			>
				<a
					:href="document.documentUrl"
					target="_blank"
					style="margin:0 auto;"
				>
					<button @click="removeDocument($event,index)" type="button" aria-label="Close" class="remove">×</button>
					<img :src="document.documentThumbnailUrl" style="display:block;border-bottom:1px solid gray" @load="toggleThumbnailLoaded(index);setImageBottomMargin($event.target)">
				</a>
				<div class="documentOptions">
					<select
						:value="(document.fileType === 'png' ? 'PP' : document.documentType)"
						@change="fileTypeChanged($event.target.value, index)"
						:disabled="(document.fileType === 'png' ? true : false)"
					>
						<option :value="null" disabled selected>Document type</option>
						<option
							v-for="(type, index) in documentTypes"
							:key="index"
							:value="type.type"
							v-text="type.description"
						/>
					</select>
					<input
						type="text"
						:value="document.description"
						size="4"
						@change="fileDescriptionChanged($event.target.value, index)"
					>
				</div>
			</li>
			<li
				v-for="(loading, index) in loadingDocuments"
				:key="`d${index}`"
				class="dummyDocument"
			>
				<loading-document/>
			</li>
			<li class="dummyDocument" v-if="editable || editableDocuments">
				<order-upload v-on:uploadError="uploadError" v-on:uploadSuccess="uploadSuccess"></order-upload>
			</li>
		</ul>
		<order-upload-overlay v-if="editable || editableDocuments" v-on:uploadError="uploadError" v-on:uploadSuccess="uploadSuccess"></order-upload-overlay>
		<b-alert
			:show="alertCountDown"
			dismissible
			fade
			variant="warning"
			@dismissed="alertCountDown=0"
			@dismiss-count-down="countDownChanged"
		>
			<span>{{ alertMessage }}</span>
		</b-alert>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from '../store';
import OrderUpload from './documentUpload/ComponentUpload.vue';
import OrderUploadOverlay from './documentUpload/ComponentUploadOverlay.vue';
import LoadingDocument from './documentUpload/LoadingDocument.vue';

export default {
	name: 'OrderDocuments',
	props: ['editable'],
	components: {
		OrderUpload,
		OrderUploadOverlay,
		LoadingDocument,
	},
	data() {
		return {
			alertMessage: null,
			alertCountDown: 0,
			loadingDocuments: [],
			editableDocuments: false
		};
	},
	computed: {
		...mapState({
			orderData: 'selectedOrderData',
			uploadedFiles: 'selectedOrderUploadedFiles',
			documentTypes: 'documentTypes'
		}),
		externalDocuments(){
			return this.orderData.externalDocuments.slice().sort((a, b) => {
				return ('' + a.id).localeCompare(b.id);
			});
		}
	},
	methods: {
		...mapMutations({
			updateUploadedFile: 'updateUploadedFileAttribute',
			removeUploadedFile: 'removeUploadedFile',
			toggleHasChanges: 'toggleHasChanges',
		}),
		...mapActions({
			uploadFiles: 'uploadFilesToAws',
		}),
		uploadError(error){
			this.alertMessage = error.message;
			this.alertCountDown = 5;
		},
		countDownChanged(dismissCountDown) {
			this.alertCountDown = dismissCountDown;
		},
		async uploadSuccess(files){
			let orgLen = files.length;
			files = this.checkDuplicateFile(files);
			let newLen = files.length;
			if(orgLen != newLen) this.uploadError({message:`Removed ${orgLen-newLen} duplicate file(s).`});
			if(!newLen) return;
			this.loadingDocuments = Array(newLen).fill(null);
			await this.uploadFiles({newFiles:files, order:this.orderData}).then( () => {
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			});
		},
		checkDuplicateFile: function(files) {
			var removeFileIndexes = [];
			files.forEach(file => {
				if (this.uploadedFiles.find(el => el.fileName === file.fileName)) {
					removeFileIndexes.push(files.indexOf(file));
				}
				if(this.orderData.externalDocuments.find(el => el.fileName === file.fileName)) {
					removeFileIndexes.push(files.indexOf(file));
				}
			});
			removeFileIndexes.sort( (a,b) => b-a);
			removeFileIndexes.forEach(i => {
				files.splice(i, 1);
			});
			return files;
		},
		removeDocument(e, index) {
			e.preventDefault();
			this.removeUploadedFile(index);
		},
		fileTypeChanged(value, index) {
			this.updateUploadedFile({attr: 'documentType', value, index});
		},
		fileDescriptionChanged(value, index) {
			this.updateUploadedFile({attr: 'description', value, index});
		},
		toggleThumbnailLoaded(index){
			this.loadingDocuments = [];
			this.updateUploadedFile({attr: 'thumbnailLoaded', value: true, index});
		},
		setImageBottomMargin(target){
			if(((130/target.width)*target.height) > 140) return;
			target.classList.toggle('margin');
		}
	},
	beforeMount() {
		if(this.orderData.status >= 10 && this.orderData.status <= 100) this.editableDocuments = true;
	}
};
</script>

<style  lang="scss" scoped>
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.summaryContainer {
	margin: 1rem;
}
.documentList {
	margin: .75rem;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
	align-items: flex-end;
}
.documentList li {
	margin:.25rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
	position: relative;
}
.documentList li.dummyDocument {
	min-width: 132px;
	min-height: 185px;
	border: none;
}
.documentList li.uploadedDocument {
	position: relative;
}
.documentList img {
	max-width:130px;
}
.documentList li .documentOptions {
	position: absolute;
	width: 100%;
}
.documentList li .documentOptions input {
	padding: .125rem;
	width: 100%;
	border: none;
	border-top: 1px solid gray;
}
.documentList li .documentOptions select {
	padding: .125rem 0;
	width: 100%;
	border: none;
	border-top: 1px solid gray;
}

$background-color: #9fa8b0;
$text-color: #343a40;
.documentList li.uploadedDocument button.remove {
	position: absolute;
	padding:0 .1rem;
	margin: 3px;
	right: 0;
	border-radius: 2px;
	background-color: $background-color;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: .75;
    color: $text-color;
    border: 0;
    -webkit-appearance: none;
	cursor: pointer;
    transition: background-color 100ms, color 100ms;
}
.documentList li.uploadedDocument button.remove:hover {
	background-color: lighten($color: $background-color, $amount: 10%);
	color: lighten($color: $text-color, $amount: 10%);
}
.documentList li.uploadedDocument button.remove:focus {
	outline: none;
}

.documentList li.externalDocument img.margin {
	margin-bottom: 22px;
}
.documentList li.uploadedDocument img.margin {
	margin-bottom: 43px;
}

.alert {
	position: absolute;
    bottom: 0;
    width: calc(100% - 1rem);
	margin: .5rem;
}
.alert .close {
	line-height: .75;
}
</style>

