<template>
	<div>
		<select v-model="filter" class="ag-filter-select" id="filterType" @change="filterChange()">
			<option value="equals">Equals</option>
			<option value="notEqual">NotEqual</option>
			<option value="lessThan">LessThan</option>
			<option value="lessThanOrEqual">LessThanOrEqual</option>
			<option value="greaterThan">GreaterThan</option>
			<option value="greaterThanOrEqual">GreaterThanOrEqual</option>
			<option value="inRange">InRange</option>
		</select>

		<div class="ag-filter-body">
			<div class="ag-filter-date-from" id="filterDateFromPanel">
				<div class="filter">
					<input type="text" class="ag-filter-filter ag-filter-input" ref="date" :value="date" :placeholder="options.format">
				</div>
			</div>	
			<div class="ag-filter-date-to" :class="{ 'ag-hidden': !isInRange}" id="filterDateToPanel">
				<div class="filter">
					<input type="text" class="ag-filter-filter ag-filter-input" ref="dateTo" :value="dateTo" :placeholder="options.format">
				</div>
			</div>
		</div>

		<div class="ag-filter-condition" :class="{ 'ag-hidden': !conditionActive}">
			<input id="andId" type="radio" class="and" name="booleanLogic" value="AND" v-model="conditionValue" @change="filterChange()">
			<label style="display: inline" for="andId">AND</label>
			<input id="orId" type="radio" class="or" name="booleanLogic" value="OR"  v-model="conditionValue" @change="filterChange()">
			<label style="display: inline" for="orId">OR</label>

			<select v-model="filterCondition" class="ag-filter-select" id="filterType" @change="filterChange()">
				<option value="equals">Equals</option>
				<option value="notEqual">NotEqual</option>
				<option value="lessThan">LessThan</option>
				<option value="lessThanOrEqual">LessThanOrEqual</option>
				<option value="greaterThan">GreaterThan</option>
				<option value="greaterThanOrEqual">GreaterThanOrEqual</option>
				<option value="inRange">InRange</option>
			</select>

			<div class="ag-filter-body">
				<div class="ag-filter-date-from" id="filterDateFromPanel">
					<div class="filter">
						<input type="text" class="ag-filter-filter ag-filter-input" ref="dateCondition" :value="dateCondition" :placeholder="options.format">
					</div>
				</div>	
				<div class="ag-filter-date-to" :class="{ 'ag-hidden': !isInRangeCondition}" id="filterDateToPanel">
					<div class="filter">
						<input type="text" class="ag-filter-filter ag-filter-input" ref="dateToCondition" :value="dateToCondition" :placeholder="options.format">
					</div>
				</div>
			</div>	
		</div>
		<div class="ag-filter-apply-panel" id="applyPanel">
			<button v-if="clearButton" type="button" id="clearButton" @click="clearFilter">ClearFilter</button>
			<button v-if="applyButton" type="button" id="applyButton" @click="applyFilter">ApplyFilter</button>
		</div>
	</div>
</template>
<script>
import Vue from 'vue';
import jQuery from 'jquery';
import moment from 'moment';
import 'pc-bootstrap4-datetimepicker';

moment.updateLocale('en', {week: {dow: 1}});

export default Vue.extend({
	data() {
		return	{
			date: '',
			dateTo: '',
			filter: 'inRange',
			isInRange: true,
			conditionActive: false,
			conditionValue: 'AND',
			dateCondition: '',
			dateToCondition: '',
			filterCondition: 'inRange',
			isInRangeCondition: true,
			suppressAndOrCondition: false,
			valueGetter: null,
			data: null,
			customComparator: null,
			inRangeInclusive: true,
			applyButton: false,
			clearButton: false,
			options: {
				format: '',
				minDate: false,
				maxDate: false,
				useCurrent: false,
				debug: false,
				showClose: true,
				showClear: true,
				showTodayButton: true,
				toolbarPlacement: 'top',
				locale: this.getLocale(),
				icons: {
					previous: 'fas fa-chevron-left',
					next: 'fas fa-chevron-right',
					close: 'fas fa-times',
					clear: 'fas fa-trash-alt',
					today: 'fas fa-calendar-check',
					time: 'fas fa-clock',
				},
				tooltips: {
					today: 'Go to today',
					clear: 'Clear selection',
					close: 'Close',
					selectMonth: 'Select Month',
					selectYear: 'Select Year',
					selectDecade: 'Select Decade'
				}
			}
		};
	},
	created() {
		this.applyButton = this.params.colDef.filterParams.applyButton;
		this.clearButton = this.params.colDef.filterParams.clearButton;
		this.customComparator = this.params.colDef.filterParams.comparator;
		this.suppressAndOrCondition = this.params.colDef.filterParams.suppressAndOrCondition;
		this.valueGetter = this.params.valueGetter;
		this.options.format = this.params.colDef.filterParams.dateFormat;
	},
	mounted() {		
		this.clearFilter();
			
		jQuery(this.$refs.date).datetimepicker(this.options);
		jQuery(this.$refs.date).on('dp.change', e => this.setDateMoment(e.date, 'date'));
		jQuery(this.$refs.dateTo).datetimepicker(this.options);
		jQuery(this.$refs.dateTo).on('dp.change', e => this.setDateMoment(e.date, 'dateTo'));

		jQuery(this.$refs.dateCondition).datetimepicker(this.options);
		jQuery(this.$refs.dateCondition).on('dp.change', e => this.setDateMoment(e.date, 'dateCondition'));
		jQuery(this.$refs.dateToCondition).datetimepicker(this.options);
		jQuery(this.$refs.dateToCondition).on('dp.change', e => this.setDateMoment(e.date, 'dateToCondition'));
	},
	methods: {
		applyFilter() {
			if (this.isFilterActive() && !this.suppressAndOrCondition)
				this.conditionActive = true;

			this.params.filterChangedCallback(this.applyButton);
		},
		clearFilter() {
			this.date = null;
			this.dateTo = null;
			this.filter = 'inRange';
			this.isInRange = true;
			this.conditionActive = false;
			this.conditionValue = 'AND';
			this.dateCondition = null;
			this.dateToCondition = null;
			this.filterCondition = 'inRange';
			this.isInRangeCondition = true;

			this.applyFilter();
		},
		filterChange() {
			this.isInRange = this.filter === 'inRange';
			this.isInRangeCondition = this.filterCondition === 'inRange';
			if (this.isFilterActive())
				this.applyFilter();
		},
		isFilterActive() {
			return this.date !== null && this.date !== undefined && this.date !== '';
		},
		isConditionActive() {
			return this.conditionActive && this.dateCondition !== null && this.dateCondition !== undefined && this.dateCondition !== '';
		},
		getModel() {
			if (this.date === null) return null;
			var model = {
				type: this.filter,
				filter: this.date,
				filterTo: this.dateTo,
				disabled: this.filter == 'inRange'
			};
			return model;
		},
		setModel(model) {
			if (model.date)
				this.date = model.date;
			if (model.dateTo)	
				this.dateTo = model.dateTo;
			if (model.filter)
				this.filter = model.type;
			if (model.dateCondition)
				this.dateCondition = model.dateCondition;
			if (model.dateToCondition)
				this.dateToCondition = model.dateToCondition;
			if (model.filterCondition)
				this.filterCondition = model.typeCondition;
		},
		afterGuiAttached() {},
		comparator(filter, cellValue) {
			return this.customComparator ? this.customComparator(filter, cellValue) : this.defaultComparator(filter, cellValue);
		},
		defaultComparator(filter, cellValue) {
			if (!filter || filter === '') return 0;

			let cellDate = moment(cellValue, this.options.format);
			let filterDate = moment(filter, this.options.format);
			return cellDate.valueOf() - filterDate.valueOf();
		},
		doesFilterPass(params){
			let compareResult = this.comparator(this.date, this.valueGetter(params.node));
			let compareToResult = this.comparator(this.dateTo, this.valueGetter(params.node));
			let mainFilterResult = this[this.filter](compareResult, compareToResult);
			if	(!this.isConditionActive())
				return mainFilterResult;

			let compareConditionResult = this.comparator(this.dateCondition, this.valueGetter(params.node));
			let compareConditionToResult = this.comparator(this.dateToCondition, this.valueGetter(params.node));
			let conditionFilterResult = this[this.filter](compareConditionResult, compareConditionToResult);

			return this.conditionValue === 'AND' ? mainFilterResult && conditionFilterResult : mainFilterResult || conditionFilterResult;
		},
		equals(compareResult) {
			return compareResult === 0;
		},
		greaterThan(compareResult) {
			return compareResult > 0;
		},
		greaterThanOrEqual(compareResult) {
			return compareResult >= 0;
		},
		lessThan(compareResult) {
			return compareResult < 0;
		},
		lessThanOrEqual(compareResult) {
			return compareResult <= 0;
		},
		notEqual(compareResult) {
			return compareResult != 0;
		},
		inRange(compareResult, compareToResult) {
			if (this.inRangeInclusive) {
				return compareResult >= 0 && compareToResult <= 0;
			} else {
				return compareResult > 0 && compareToResult < 0;
			}
		},
		setDateMoment(date, variable) {
			if (!date)
				this[variable] = '';
			else
				this[variable] = date.format(this.options.format);
			this.applyFilter();
		},
		getLocale() {
			let lang = localStorage.getItem('locale');
			if (lang === 'no') {
				lang = 'nb';
			}
			return lang;
		},
		getModelAsString() {
			return this.date;
		},
		onFloatingFilterChanged(model) {
			this.date = model.date;
			this.applyFilter();
		}
	}
});
</script>

<style>
.ag-menu {
	overflow-y: visible !important;
	max-height: none !important;
}
</style>