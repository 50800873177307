<template>
	<div class="container" id="dragDropContainer" style="visibility: hidden">
		<p>Drag you file(s) here to upload</p>
	</div>
</template>

<script>

import { pdfCore } from '@/core';

export default {
	name: 'OrderUploadOverlay',
	data() {
		return {
			uploadedFiles: [],
			uploadError: null,
			uploadFieldName: 'photos'
		};
	},
	methods: {
		filesChange(fileList) {
			if (!fileList.length) return;

			// console.log(fileList);
			// append the files to FormData
			var files = [];
			for(var file of fileList){
				files.push(file);
			}

			// console.log(files);
			// save it
			this.save(files).then( thumbnails => {
				this.$emit('uploadSuccess', thumbnails);
			}).catch( err => {
				this.$emit('uploadError', err);
			});
		},
		async save(files) {
			// upload data to the server
			var thumbnails = [];

			// let caughtError;
			for(const file of files) {
				try {
					var b64d = await this.readFile(file);
					var image = await this.generateImage(b64d); //canvas, pagerender
					var thumbnail = await this.imageToThumbnail(image, b64d, file); //img

					thumbnails.push(thumbnail);
				} catch(err) {
					// if(!caughtError) caughtError = err;
				}
			}
			return thumbnails;
		},
		async readFile(file) {
			return new Promise((resolve) => {
				var fReader = new FileReader();

				fReader.onload = () => {
					resolve(fReader.result);
				};

				fReader.readAsDataURL(file);
			});
		},
		async generateImage(b64d) {
			// console.log(b64d);
			return new Promise( resolve => {
				let startIndex = b64d.indexOf(':');
				let endIndex = b64d.indexOf(';');
				let type = b64d.substring(startIndex+1,endIndex);
				if(type.includes('image')){
					// throw new Error('Unable to upload pictures in this version');
					var img = document.createElement('img');
					img.src = b64d;
					img.onload = () => {
						var canvas = document.createElement('canvas');
						var ctx = canvas.getContext('2d');
						canvas.width = img.width;
						canvas.height = img.height;
						// console.log(img);
						ctx.drawImage(img, 0, 0);

						var dataURL = canvas.toDataURL('image/png');
						// console.log(dataURL);
						resolve(dataURL);
					};

				} else {
					startIndex = b64d.indexOf(',');
					b64d = b64d.substring(startIndex+1);
					b64d = atob(b64d);
					pdfCore.documentToDataUrl(b64d).then(dataUrl => {
						resolve(dataUrl);
					});
				}
			});
		},
		async imageToThumbnail(img, b64d, file) {
			return new Promise( resolve => {
				var image = {
					description:file.name,
					documentUrl: b64d,
					documentThumbnailUrl: img,
					fileName: file.name,
					documentType: null,
				};
				resolve(image);
			});
		},
	},
	mounted() {
		this.uploadedFiles = [];
		this.uploadError = null;

		var self = document.getElementById('dragDropContainer');
		window.addEventListener('dragenter', e => {
			e.preventDefault();
			self.style.visibility = '';
			self.style.opacity = 1;
		});

		window.addEventListener('dragleave', e => {
			e.preventDefault();

			self.style.visibility = 'hidden';
			self.style.opacity = 0;

		});

		window.addEventListener('dragover', e => {
			e.preventDefault();
			self.style.visibility = '';
			self.style.opacity = 1;
		});

		window.addEventListener('drop', e => {
			e.preventDefault();
			self.style.visibility = 'hidden';
			self.style.opacity = 0;
		});
		self.addEventListener('drop', e => {
			e.preventDefault();
			self.style.visibility = 'hidden';
			self.style.opacity = 0;

			var files = e.dataTransfer.files;
			this.filesChange(files);
		});
	},
};
</script>

<style lang="scss" scoped>
#dragDropContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0;
    transition: visibility 175ms, opacity 175ms;
	outline: 2px dashed grey; /* the dash box */
	outline-offset: -10px;
    background: rgba(0, 0, 0, 0.45);
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: 1px 1px 2px #000;
    color: #fff;
	z-index:9999;
}
#dragDropContainer p {
	font-size: 1.2em;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
