export default function rewriteChangelog(changelog) {
	let rewritten = [];
	changelog.forEach(change => {
		let temp = {};
		let changes = [];
		temp.id = change.id;
		temp.createdAt = change.createdAt;
		temp.executedBy = change.executedBy;
		change.data.forEach(operation => {
			changes.push(translateOperation(operation.op, operation.path, operation.value, operation.oldValue));
		});
		temp.data = changes;
		rewritten.push(temp);
	});
	// console.log(changelog, rewritten);
	// return changelog; //Fjernes ved ferdig omskriving
	return rewritten;
}

function translateOperation(operation, path = null, newVal = null, oldVal = null) {
	if (operation === 'customLog')
		return newVal;

	let translated = [];
	let tOp;
	switch (operation) {
		case 'new':
			return 'Order recieved';
		case 'add':
			tOp = 'Added new';
			break;
		case 'replace':
			tOp = 'Replaced';
			break;
		case 'remove':
			tOp = 'Removed';
			break;
	}
	let tPath = translatePath(path);
	let tVal = translateValues(newVal, oldVal);
	if (tPath) {
		translated.push(tOp, tPath + '.', tVal);
	}
	return translated.join(' ');
}

function translatePath(fullPath) {
	let path = fullPath.split('/').filter(val => !isUUID(val));

	let joinedPath;
	switch (path[0]) {
		case 'freightLines':
		case 'externalDocuments':
			if (!isNaN(path[1])) return;
			path.splice(1, 1);
			joinedPath = path.join(' ');
			break;
		default:
			joinedPath = path.join(' ');
			break;
	}
	return joinedPath;
}

function translateValues(newVal, oldVal = null) {
	let values = [];
	if (newVal != null) {
		if (typeof newVal === 'object') {
			values.push('Description');
			values.push(newVal.description + '.');
		} else {
			values.push('New value is');
			values.push(newVal + '.');
		}
	}
	if (oldVal != null) {
		values.push('Old value was');
		values.push(oldVal + '.');
	}
	return values.join(' ');
}

function isUUID(val) {
	return val.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
}
