import Vue from 'vue';
import {newUUID} from 'core';
import rewriteChangelog from './rewriteChangelog';

const setOrders = function(state, orderDtos) {
	state.orders = orderDtos;
};
const setSelectedOrder = function(state, order) {
	Vue.set(state, 'selectedOrderData', order);
	// state.selectedOrderData =  order;
};
const clearSelectedOrder = function(state) {
	state.selectedOrderData =  null;
};
const setSelectedOrders = function(state, orders) {
	state.selectedOrders = orders;
};
const updateSelectedOrderData = function(state, order){
	for(var i = 0; i < state.orders.length; i++){
		if(state.orders[i].id === order.id){
			Vue.set(state.orders, i, order);
			return;
		}
	}
};
const toggleHasChanges = function(state, payload) {
	let order = state.orders.filter(order => order.id === payload.id)[0];
	if(order){
		Vue.set(order, 'hasChanges', payload.state);
	}
};
const toggleHasPriceChanges = function(state, payload) {
	let order = state.orders.filter(order => order.id === payload.id)[0];
	if(order){
		Vue.set(order, 'hasPriceChanges', payload.state);
	}
};
const toggleCocAccepted = function(state, payload) {
	let order = state.orders.filter(order => order.id === payload.id)[0];
	if(order){
		Vue.set(order.transporter, 'cocAccepted', payload.state);
	}
};
const setHandledBy = function(state, {orderId, currentUser}) {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		let handledBy = { email: currentUser.email, displayName: currentUser.displayName};
		Vue.set(order, 'handledBy', handledBy);
	}
};

const startLoading = function(state, payload) {
	state.loading.push(payload);
};
const stopLoading = function(state, payload) {
	state.loading.pop(payload);
};

const setStatus = function(state, payload) {
	let order = state.orders.filter(order => order.id === payload.id)[0];
	if(order){
		Vue.set(order, 'status', payload.status);
	}
};
const deleteFromStateOrders = function(state, orderId) {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		Vue.delete(state.orders, state.orders.map( o => {return o.id;}).indexOf(orderId));
	}
};

const selectedOrderSetStatus = function(state, status){
	Vue.set(state.selectedOrderData, 'status', status);
};

const transferPrices = function(state, isAccepted){
	if(!state.selectedOrderData) return;
	var est = state.selectedOrderData.transporter.estimatedPriceItems;
	var act = state.selectedOrderData.transporter.priceItems;
	for(let price of est){
		act.push(newPriceItem(price, isAccepted));
	}
};
const spliceEmptyPrices = function(state, orderId){
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		let removeItemIndexes = [];
		let act = order.transporter.priceItems;
		for(let item of act) {
			if(!item.description && !item.grossAmount && !item.netAmount){
				removeItemIndexes.push(act.indexOf(item));
			}
		}
		removeItemIndexes.sort( (a,b) => b-a);
		removeItemIndexes.forEach(i => {
			act.splice(i, 1);
		});
		Vue.set(order.transporter, 'priceItems', act);
	}
};
const addPriceItemToOrder = function(state, priceItem){
	if(!state.selectedOrderData) return;
	var act = state.selectedOrderData.transporter.priceItems;
	act.push(newPriceItem(priceItem, false));
};
const addEmptyPriceItemToOrder = function(state){
	if(!state.selectedOrderData) return;
	var act = state.selectedOrderData.transporter.priceItems;
	act.push(newPriceItem(null, false));
};
const removePriceItemFromOrder = function(state, priceId){
	if(!state.selectedOrderData) return;
	var act = state.selectedOrderData.transporter.priceItems;
	act.splice(act.findIndex(p => p.id === priceId), 1);
};

//Only used internally in mutations. Not to be exported
const newPriceItem = function(price, isAccepted){
	let newPrice = {
		code: null,
		type: 'frakt',
		currency: 'NOK',
		description: null,
		id: newUUID(),
		lineNo: 0,
		netAmount: null,
		grossAmount: null,
		transporterId: null,
		aggregation: null,
		basePrice: null,
		basePriceUnit: null,
		vat: 0,
		isAccepted: isAccepted,
	};
	if(price) {
		newPrice.code = price.code;
		newPrice.type = price.type;
		newPrice.currency = price.currency;
		newPrice.description = price.description;
		newPrice.netAmount = price.netAmount;
		newPrice.grossAmount = price.netAmount;
		newPrice.transporterId = price.transporterId;
		newPrice.aggregation = price.aggregation;
		newPrice.basePrice = price.basePrice;
		newPrice.basePriceUnit = price.basePriceUnit;
	}
	return newPrice;
};
const updateAggregatedPriceAmount = function(state, priceItem) {
	let priceId = priceItem.id;
	let order = state.selectedOrderData;
	let amount = null;
	let basePriceUnit = null;
	if(priceItem.basePrice && priceItem.aggregation){
		let aggregation = getAggregation(state, priceItem.aggregation);
		basePriceUnit = aggregation.unit;
		let multiplicatorFunction = aggregation.multiplicator;
		let multiplicator = multiplicatorFunction(order);
		amount = priceItem.basePrice * multiplicator;
		amount = parseFloat(amount.toFixed(2)); // Max 2 decimals
	}
	let priceItemIndex = order.transporter.priceItems.findIndex(p => p.id === priceId);
	Vue.set(order.transporter.priceItems[priceItemIndex], 'netAmount', amount);
	Vue.set(order.transporter.priceItems[priceItemIndex], 'grossAmount', amount);
	Vue.set(order.transporter.priceItems[priceItemIndex], 'basePriceUnit', basePriceUnit);
};
const getAggregation = function(state, type){
	if(!type) return null;
	let priceAggregation = state.priceAggregations.filter(x => x.type === type)[0];
	return priceAggregation;
};


const logFunction = function(){
	console.log('debugMark');
};
const setQueryStatus = function(state, status) {
	state.queryStatus = status;
};

const setSelectedOrderChangelog = function(state, changelog){
	let log = rewriteChangelog(changelog);
	log = log.sort( (a, b) => {
		return Date.parse(b.createdAt) - Date.parse(a.createdAt);
	});
	Vue.set(state, 'selectedOrderChangelog', log);
};

const setSelectedOrderUploadedFiles = function(state, files){
	state.selectedOrderUploadedFiles = files;
};
const updateUploadedFileAttribute = function(state, {attr, value, index}) {
	Vue.set(state.selectedOrderUploadedFiles[index], attr, value);
};
const removeUploadedFile = function(state, index) {
	state.selectedOrderUploadedFiles.splice(index, 1);
};

const setStoredGridOptions = function(state, gridOptions) {
	state.gridOptions = gridOptions;
};
const setValidityChecked = function(state, val){
	state.validityChecked = val;
};

const setOrderChangeMap = function(state, {orderId, changeMap}){
	let order = state.orders.find(order => order.id === orderId);
	if(order){
		Vue.set(order, 'changeMap', changeMap);
	}
};
const setSelectedOrderChangeMap = function(state, changeMap){
	Vue.set(state.selectedOrderData, 'changeMap', changeMap);
};

export default {
	setOrders,
	setSelectedOrder,
	clearSelectedOrder,
	setSelectedOrders,
	toggleHasChanges,
	toggleHasPriceChanges,
	toggleCocAccepted,
	setHandledBy,
	updateSelectedOrderData,
	startLoading,
	stopLoading,
	setStatus,
	deleteFromStateOrders,
	selectedOrderSetStatus,
	transferPrices,
	spliceEmptyPrices,
	addPriceItemToOrder,
	addEmptyPriceItemToOrder,
	removePriceItemFromOrder,
	updateAggregatedPriceAmount,
	logFunction,
	setQueryStatus,
	setSelectedOrderChangelog,
	setSelectedOrderUploadedFiles,
	updateUploadedFileAttribute,
	removeUploadedFile,
	setStoredGridOptions,
	setValidityChecked,
	setOrderChangeMap,
	setSelectedOrderChangeMap,
};
