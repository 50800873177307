<template>
	<modal
		:visible="show"
		size="md"
		@close="handleClosing"
		:title="title"
		centered
		class="closingModal"
		:okButton="false"
		:cancelButton="false"
	>
		<b-container>
			<div>
				<span class="content" v-text="this.content"></span>
			</div>
		</b-container>


		<b-container slot="footer" fluid>
			<b-btn slot="modal-cancel" variant="secondary" @click="close" v-text="this.closeButton"></b-btn>
		</b-container>
	</modal>
</template>

<script>
import StackableModal from '../StackableModal.vue';

export default {
	name: 'ResponseModal',
	props: ['visible', 'title', 'content', 'closeButton'],
	components: {
		'modal': StackableModal
	},
	data() {
		return {
			show: this.visible,
		};
	},
	methods: {
		handleClosing(){
			this.$emit('close');
		},
		close() {
			this.show = false;
		},
	},

};
</script>

<style lang="scss" scoped>
.closingModal /deep/ .modal-header {
	padding:.5rem;
	padding-bottom:.25rem;
	border-bottom: none;
}
.closingModal /deep/ .modal-header .modal-title{
	font-size:15px;
}
.closingModal /deep/ .modal-header .close:focus{
	outline: none;
}
.closingModal /deep/ .modal-footer {
	border-top: none;
	padding: .5rem 0;
}
.closingModal footer.modal-footer button {
	margin: 0 5px;
}
.closingModal footer.modal-footer button:first-child {
	margin-left: 0;
}
.closingModal footer.modal-footer button:last-child {
	margin-right: 0;
}
.closingModal /deep/ .modal-body li {
	margin-bottom: .125rem
}
.content {
	overflow-wrap: break-word;
}
</style>

