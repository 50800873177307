import {validate} from '@/core';

const getOrderHasChanges = (state) => {
	if(!state.selectedOrderData){
		return false;
	}
	let hasChanges = state.selectedOrderData.hasChanges;
	let hasPriceChanges = state.selectedOrderData.hasPriceChanges;

	return !!(hasChanges||hasPriceChanges);
};

const getOrderReadyToSend = (state, getters, rootState) => {
	let readyTosend = false;
	if(state.selectedOrderData !== null){
		let order = state.selectedOrderData;
		let validators = rootState.user.transporterSettings.validators.sendOrder;
		if(
			validate(order, validators)
		){
			readyTosend = true;
		}
	}
	return readyTosend;
};

const getOrderReadyToFinalize = (state, getters, rootState) => {
	let readyToFinalize = false;
	if(state.selectedOrderData !== null){
		let order = state.selectedOrderData;
		let validators = rootState.user.transporterSettings.validators.finalizeOrder;
		if(
			validate(order, validators) &&
			order.status < 80 && order.status >= 30
		){
			readyToFinalize = true;
		}
	}
	return readyToFinalize;
};

const getTransporterSettings = (state, getters, rootState) => {
	return rootState.user.transporterSettings;
};

const isLoading = (state) => (id) => {
	return state.loading.includes(id);
};

export default {
	getOrderHasChanges,
	getOrderReadyToSend,
	getOrderReadyToFinalize,
	getTransporterSettings,
	isLoading,
};
