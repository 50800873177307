<template>
	<div style="display:inline;">
		<!-- <b-btn v-if="isDeleted" class="gridActions" type="button" @click="restoreOrder" title="Restore order"><font-awesome-icon icon="undo-alt" :class="this.class"></font-awesome-icon></b-btn>
		<b-btn v-if="!isDeleted" class="gridActions" type="button" @click="deleteOrder" title="Delete order"><font-awesome-icon icon="trash-alt" :class="this.class"></font-awesome-icon></b-btn> -->
		<b-btn v-if="false" class="gridActions" type="button" @click="sendOrder" title="Send order to TMS"><font-awesome-icon icon="paper-plane" :class="this.class"></font-awesome-icon></b-btn>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from './store';
import BaseStore from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default Vue.extend({
	name: 'Actions',
	store: BaseStore,
	components: {
		'font-awesome-icon': FontAwesomeIcon
	},
	data() {
		return {
			data: null,
			classes: ['fa-sm']
		};
	},
	created() {
		if(this.params){
			// console.log(this.params);
			this.data = this.params.data;
		}
	},
	methods: {
		...mapActions({
			sendOrderAction: 'sendOrderToTMS',
			deleteOrderAction: 'deleteOrder',
			restoreOrderAction: 'restoreOrder'
		}),
		sendOrder(){
			this.sendOrderAction(this.data.id);
		},
		deleteOrder() {
			this.deleteOrderAction(this.data.id);
		},
		restoreOrder() {
			this.restoreOrderAction(this.data.id);
		}
	},
	computed: {
		class() {
			return this.classes.join(' ');
		},
		isDeleted() {
			return (this.data.status < 0);
		}
	}
});
</script>

<style lang="scss">
button.gridActions {
	margin: auto .1875rem;
}
</style>
