import { getOrders, getDeletedOrders, getCompletedOrders, getOrderById, getOrdersByQuery } from '../requests';
import { createPatch, request } from '@/core';

// const baseUrl = 'http://localhost:6060/v1/'; //devUrl
// const baseUrl = 'https://transportportal.timpex.cloud/v1/';

const getOrdersAction = async({ commit }) => {
	let orderDtos = await getOrders();
	commit('setQueryStatus', null); //queryStatus null = active orders
	commit('setOrders', orderDtos);
};
const getOrdersByQueryAction = async({commit}, payload) => {
	let orderDtos = await getOrdersByQuery(payload);
	commit('setOrders', orderDtos);
};
const getDeletedOrdersAction = async({ commit }) => {
	let orderDtos = await getDeletedOrders();
	commit('setQueryStatus', -10);
	commit('setOrders', orderDtos);
};
const getCompletedOrdersAction = async({ commit }) => {
	let orderDtos = await getCompletedOrders();
	commit('setQueryStatus', 100);
	commit('setOrders', orderDtos);
};

const reloadSelectedOrder = async({ commit }, payload) => {
	let orderDto = await getOrderById(payload);
	commit('updateSelectedOrderData', orderDto);
	commit('setSelectedOrder', orderDto);

	// Other values that need to be cleared
	commit('setSelectedOrderUploadedFiles', []);
};

const getOrderChangelog = async({state, commit}, payload) => {
	var order = state.orders.filter(order => order.id === payload)[0];
	if(!order) return;
	var url = `/orders/${order.id}/changelog`;
	let changelog = await request({
		method: 'get',
		url: url,
		data: {}
	}).catch(e => {
		console.log(e.message);
	});
	commit('setSelectedOrderChangelog', changelog);
	return changelog;
};

const getConfirmEvent = async(store, payload) => {
	var url = `/orders/events/${payload}`;
	let confirmEvent = await request({
		method: 'get',
		url: url,
		data: {}
	}).catch(e => {
		console.log(e.message);
	});
	return confirmEvent;
};

const getRelatedDeclarations = async({state}) => {
	let orderId = state.selectedOrderData.id;
	if(!orderId) return [];
	var url = `/orders/${orderId}/declarations`;
	let declarations = await request({
		method: 'get',
		url: url,
		data: {}
	}).catch(e => {
		console.log(e.message);
	});
	return declarations;
};

const saveDraft = async({ dispatch, commit, state }) => {
	// commit('startLoading', 'saveDraft');
	let selectedOrder = Object.assign({}, state.selectedOrderData);
	delete selectedOrder.hasChanges;
	delete selectedOrder.hasPriceChanges;

	let originalOrder = await getOrderById(selectedOrder.id);
	let patch = createPatch(originalOrder, selectedOrder);
	// console.log(patch);
	let url = `/orders/${selectedOrder.id}`; //kundeKode
	try {
		await request({
			method: 'patch',
			url: url,
			data: patch
		});
		await dispatch('saveUploadedFiles', selectedOrder);
		await dispatch('getOrderChangelog', selectedOrder.id);
		commit('toggleHasChanges', {id: selectedOrder.id, state: false});
		commit('toggleHasPriceChanges', {id: selectedOrder.id, state: false});
	} catch (e) {
		console.log(e.message);
		throw new Error(e);
	}
	// commit('stopLoading', 'saveDraft');
};
const saveMultipleDraft = async({ state }) => {
	// // commit('startLoading', 'saveDraft');
	for(let order of state.selectedOrders){
		let selectedOrder = Object.assign({}, order);

		let originalOrder = await getOrderById(order.id);
		let patch = createPatch(originalOrder, selectedOrder);
		// console.log(patch);
		let url = `/orders/${selectedOrder.id}`; //kundeKode
		try {
			await request({
				method: 'patch',
				url: url,
				data: patch
			});
		} catch (e) {
			console.log(e.message);
			throw new Error(e);
		}
	}
	// // commit('stopLoading', 'saveDraft');
};

const sendOrderToTMS = async({ dispatch, state }, payload) => {
	let order = state.orders.filter(order => order.id === payload)[0];
	var url =  `/orders/${order.id}/sendEdi`; //kundeKode
	if(!order){
		return;
	} else if (order.hasChanges){
		await dispatch('saveBeforeSend', order);
	}
	try{
		// console.log(url, payload);
		await request({
			method: 'post',
			url: url,
			data: payload
		});
		// commit('setStatus', {id: order.id, status: orderStatusValues.externalSent});
		dispatch('saveBeforeSend', order);
	} catch (e) {
		// commit('setStatus', {id: order.id, status: orderStatusValues.externalFailed});
		dispatch('saveBeforeSend', order);
		console.log(e.message);
	}
};

const sendOrderToConfirm = async({ dispatch, state }, {orderId, confirmInformation}) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	var url =  `/orders/${orderId}/sendConfirm`; //kundeKode
	if(!order){
		return;
	} else if (order.hasChanges){
		await dispatch('saveBeforeSend', order);
	}
	try{
		await request({
			method: 'post',
			url: url,
			data: confirmInformation
		});
	} catch (e) {
		console.log(e.message);
	}
};

const flagSeenByUser = async({rootState, commit}, {orderId, logId}) => {
	let order = rootState.orders.orders.filter(order => order.id === orderId)[0];
	if(!order) return;
	let orderSeenBy = order.seenBy || [];
	let userId = rootState.user.currentUser.uid;
	let userInstance = orderSeenBy.find(x => x.userId === userId) || {};
	if(userInstance.logId === logId) return;
	let url = `/orders/${order.id}/flagSeen`;
	try{
		await request({
			method: 'post',
			url: url,
			data: {logId}
		});
		commit('setSelectedOrderChangeMap', {});
	} catch (e) {
		console.log(e.message);
	}
};

const uploadFilesToAws = async({commit, state}, {newFiles, order}) => {
	var existingFiles = state.selectedOrderUploadedFiles;
	var url = `/orders/${order.id}/uploadFiles`;
	try{
		let uploadedFiles = await request({
			method: 'post',
			url: url,
			data: newFiles
		});
		commit('setSelectedOrderUploadedFiles', existingFiles.concat(uploadedFiles));
	} catch (e) {
		console.log(e.message);
	}
};

const saveUploadedFiles = async({commit, state}, order) => {
	var files = state.selectedOrderUploadedFiles;
	if(!files.length) return;
	var url = `/orders/${order.id}/saveFiles`;
	try{
		await request({
			method: 'post',
			url: url,
			data: files
		});
		commit('setSelectedOrderUploadedFiles', []);
	} catch (e) {
		console.log(e.message);
	}
};

const finalizeOrder = async({dispatch, commit, state}, orderId) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		await commit('selectedOrderSetStatus', 100);
		// await commit('setStatus', {id: orderId, status: 100});
		await dispatch('saveDraft');
		await commit('deleteFromStateOrders', orderId);
	}
};

const deleteOrder = function({dispatch, commit, state}, orderId) {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		commit('setStatus', {id: orderId, status: -10});
		dispatch('saveBeforeSend', order);
		commit('deleteFromStateOrders', orderId);
	}
};
const restoreOrder = function({dispatch, commit, state}, orderId) {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		commit('setStatus', {id: orderId, status: 0});
		dispatch('saveBeforeSend', order);
		commit('deleteFromStateOrders', orderId);
	}
};

const acceptOrder = async({dispatch, commit, state, rootState}, orderId) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		if(order.customerSettings.requiresCodeOfConduct){
			commit('toggleCocAccepted',  {id: orderId, state: true});
		}
		commit('setHandledBy', {orderId: orderId, currentUser: rootState.user.currentUser});
		commit('setStatus', {id: orderId, status: 10});
		dispatch('saveBeforeSend', order);
		// commit('deleteFromStateOrders', orderId);
	}
};
const refuseOrder = async({dispatch, commit, state, rootState}, orderId) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		commit('setHandledBy', {orderId: orderId, currentUser: rootState.user.currentUser});
		commit('setStatus', {id: orderId, status: -15});
		dispatch('saveBeforeSend', order);
		commit('deleteFromStateOrders', orderId);
	}
};
//Accepts existing prices
const acceptPrices = async({dispatch, commit, state}, orderId) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		commit('transferPrices', true);
		if(order.transporter.priceItems.every(pI => pI.isAccepted)){
			commit('setStatus', {id: orderId, status: 30});
		} else {
			commit('setStatus', {id: orderId, status: 25});
		}
		dispatch('savePrices', order);
	}
};
//Transfers estimated priceitems to actual, and edits
const requestPrices = async({dispatch, commit, state}, orderId) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		commit('transferPrices', false);
		commit('setStatus', {id: orderId, status: 20});
		dispatch('importActualPriceList');
		dispatch('savePrices', order);
	}
};
//Resets status from awaiting approval to awaiting price, which allows editing priceitems
const editPrices = async({dispatch, commit, state}, orderId) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		commit('setStatus', {id: orderId, status: 20});
		dispatch('importActualPriceList');
		dispatch('savePrices', order);
	}
};
//Approves new prices
const approvePrices = async({dispatch, commit, state}, orderId) => {
	let order = state.orders.filter(order => order.id === orderId)[0];
	if(order){
		if(order.transporter.priceItems.every(pI => pI.isAccepted)){
			commit('setStatus', {id: orderId, status: 30});
		} else {
			commit('setStatus', {id: orderId, status: 25});
		}
		dispatch('savePrices', order);
	}
};
const savePrices = async({ dispatch, commit, state }) => {
	let selectedOrder = Object.assign({}, state.selectedOrderData);
	delete selectedOrder.hasPriceChanges;

	let originalOrder = await getOrderById(selectedOrder.id);
	let patch = createPatch(originalOrder, selectedOrder);

	//Only apply patches related to prices and status
	patch = patch.filter(x => x.path.includes('/transporter/priceItems/') || x.path === '/status');
	// console.log(patch);

	let url = `/orders/${selectedOrder.id}`; //kundeKode
	try {
		await request({
			method: 'patch',
			url: url,
			data: patch
		});
		await dispatch('getOrderChangelog', selectedOrder.id);
		commit('toggleHasPriceChanges', {id: selectedOrder.id, state: false});
	} catch (e) {
		console.log(e.message);
		throw new Error(e);
	}
};
const importActualPriceList = async({ commit, state, rootState}) => {
	let order = state.selectedOrderData;
	if(!order) return;
	if(((order.transporter || {}).priceItems || []).length) return; // If priceItems not empty, do not import priceList
	let priceLists = rootState.user.transporterSettings.priceLists;
	let orderPriceLists = priceLists.filter( l => l.listType === 'order');
	let actualPriceLists = orderPriceLists.filter( l => l.priceType === 'actual');
	let customerPriceList = actualPriceLists.find( l => l.customer === order.customerSettings.code);
	if(!customerPriceList || !customerPriceList.priceItems) return;
	for(let priceItem of customerPriceList.priceItems){
		commit('addPriceItemToOrder', priceItem);
	}
	for(let priceItem of order.transporter.priceItems){
		if(priceItem.aggregation && priceItem.basePrice){
			commit('updateAggregatedPriceAmount', priceItem);
		}
	}
};

const saveBeforeSend = async({dispatch, commit, state}, payload) => {
	if(!state.selectedOrderData || state.selectedOrderData.id !== payload.id){
		commit('setSelectedOrder', payload);
		await dispatch('saveDraft');
		commit('clearSelectedOrder');
	} else {
		await dispatch('saveDraft');
	}
};

export default {
	getOrders: getOrdersAction,
	getOrdersByQuery: getOrdersByQueryAction,
	getDeleted: getDeletedOrdersAction,
	getCompleted: getCompletedOrdersAction,
	reloadSelectedOrder,
	getOrderChangelog,
	getConfirmEvent,
	getRelatedDeclarations,
	saveDraft,
	saveMultipleDraft,
	sendOrderToTMS,
	sendOrderToConfirm,
	flagSeenByUser,
	uploadFilesToAws,
	saveUploadedFiles,
	finalizeOrder,
	saveBeforeSend,
	deleteOrder,
	restoreOrder,
	acceptOrder,
	refuseOrder,
	acceptPrices,
	requestPrices,
	editPrices,
	approvePrices,
	importActualPriceList,
	savePrices,
};
