<template>
	<div class="componentContainer tat" v-if="confirmEvent">
		<div class="summaryWrapper border flex-fill" style="grid-area:details;">
			<div class="summaryHeader">
				Details
			</div>
			<ul class="detailsList flex-fill">
				<li>
					<div class="detailsHeader">Info</div>
					<div>{{ this.confirmEvent.infoEnglish }}</div>
				</li>
				<li>
					<div class="detailsHeader">Date and time</div>
					<div v-text="dateFormat(confirmEvent.date)" />
				</li>
				<li>
					<div class="detailsHeader">Arrival time</div>
					<div v-text="dateFormat(confirmEvent.arrivalTime)" />
					<div>{{ this.confirmEvent.arrivalTime }}</div>
				</li>
				<li>
					<div class="detailsHeader">Executed by</div>
					<div>
						<div v-for="(item, idx) in executedBy" v-bind:key="idx">
							{{item}}
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="summaryWrapper border flex-fill" style="grid-area:pod;">
			<div class="summaryHeader">
				Signature: {{ this.signatureName }}
			</div>
			<div class="signatureContainer flex-fill">
				<img
					v-if="signatureUrl"
					:src="signatureUrl"
					class="signatureImage"
				/>
			</div>
		</div>
		<div class="summaryWrapper border flex-fill" style="grid-area:location;">
			<div class="summaryHeader">
				Location
			</div>
			<div class="locationContainer flex-fill">
				<iframe
					:src="eventLocation"
					width="100%"
					height="400px"
					frameborder="0" style="border:0"
					allowfullscreen
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions} from '../store';
import moment from 'moment-timezone';
export default {
	name: 'OrderTrackAndTrace',
	props: ['eventId'],
	components: {

	},
	data() {
		return {
			confirmEvent: null,
		};
	},
	computed: {
		signatureUrl() {
			return this.confirmEvent.signatureUrl;
		},
		signatureName() {
			return this.confirmEvent.name;
		},
		eventLocation() {
			return `https://www.google.com/maps/embed/v1/place?key=AIzaSyBDgCxAbkvZw21XSzvhHcBCP20D0D0KEfU&q=${this.confirmEvent.latitude},${this.confirmEvent.longitude}`;
		},
		executedBy() {
			let arr =[];
			let phoneNumber = this.confirmEvent.executedBy.phoneNumber;
			let name = this.confirmEvent.executedBy.name;
			let email = this.confirmEvent.executedBy.email;
			
			if (name)
				arr.push('Name: ' + name);
			if (phoneNumber)
				arr.push('Phone: ' + phoneNumber);
			if (email)
				arr.push('E-mail: ' + email);

			return arr;
		},
	},
	methods: {
		...mapActions([
			'getConfirmEvent'
		]),
		dateFormat(dateVal) {
			let date = moment(dateVal, 'YYYYMMDDHHmm');
			return date.isValid() ? date.format('DD.MM.YYYY') : null;
		},
	},
	async beforeMount() {
		let event = await this.getConfirmEvent(this.eventId);
		this.confirmEvent = event;
	}
};
</script>

<style lang="scss" scoped>
.signatureContainer img {
	max-height: 250px;
}
.locationContainer {
	margin-bottom: -5px;
}

.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns:100%;
	padding:15px;
	grid-template-areas:
		'details' 'pod'	'location' ;
}
@media (min-width: 768px) {
	.componentContainer {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr auto;
		padding:15px;
		grid-template-areas:
			'details pod'
			'location location';
	}
}
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 12px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	display:flex;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.detailsList {
	margin: 1rem;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.detailsList li {
	display: inline-block;
	margin: 0.3rem;
	min-width: calc( 100% - 0.6rem);
	text-align: left;
}
.detialsList li > span {
	margin-left: 0.5rem;
}
.detailsHeader {
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryContainer {
	margin: 1rem;
	display: flex;
	flex-flow: column;
}
</style>
