<template>
	<div style ="display: flex; flex-direction: row; align-items: center;">
		<input
			type="text"
			class="ag-filter-filter ag-filter-input"
			ref="date"
			style="width:100px"
			@change="dateChanged"
			:value="date"
			:placeholder="dateFormat"
			:disabled="disabled"
		>
	</div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
	data() {
		return	{
			date: '',
			onFloatingFilterChanged: null,
			disabled: true,
			dateFormat: ''
		};
	},
	created() {
		this.onFloatingFilterChanged = this.params.onFloatingFilterChanged;
		this.dateFormat = this.params.dateFormat;
	},
	mounted() {
	},
	methods: {
		onParentModelChanged(parentModel) {
			if (!parentModel) {
				this.date = '';
				this.disabled = false;
			} else {
				this.date = parentModel.filter;
				this.disabled = parentModel.disabled;
			}
		},
		dateChanged() {
			this.date = this.$refs.date.value;
			this.onFloatingFilterChanged({ date: this.date });
		},
		getLocale() {
			let lang = localStorage.getItem('locale');
			if (lang === 'no') {
				lang = 'nb';
			}
			return lang;
		}
	}
});
</script>

<style>
.ag-menu {
	overflow-y: visible !important;
	max-height: none !important;
}
</style>
