<template>
	<div class="componentContainer">
		<div class="componentContent flex-fill">
			<div class="componentHeader">
				Address
			</div>
			<div class="first-row">
				<div class="simpleInput">
					<span v-text="'Order'"/>
					<input
						placeholder="Order number"
						type="text"
						:value="orderNo"
						:disabled="true"
					/>
				</div>
				<div class="simpleInput">
					<span v-text="'Type'"/>
					<input
						placeholder="E / I"
						type="text"
						:value="orderType"
						:disabled="true"
					/>
				</div>
			</div>
			<div class="first-row">
				<div class="simpleInput">
					<span v-text="'Int. id'"/>
					<input
						placeholder="E / I"
						type="text"
						:value="interchangeId"
						:disabled="true"
					/>
				</div>
			</div>
			<div class="party-row">
				<div class="party-column-1 simpleInput">
					<span v-text="'Consignor'"/>
					<input
						placeholder="Company Name"
						type="text"
						:value="consignorName"
						:disabled="true"
					/>
					<input
						placeholder="Address Line"
						type="text"
						:value="consignorAddressline"
						:disabled="true"
					/>
					<input
						placeholder="Address Line 2"
						type="text"
						:value="consignorAddressline2"
						:disabled="true"
					/>
					<input
						placeholder="Postal Code"
						type="text"
						:value="consignorPostalCode"
						:disabled="true"
					/>
					<input
						placeholder="Postal Place"
						type="text"
						:value="consignorPostalPlace"
						:disabled="true"
					/>
					<input
						placeholder="Country Code"
						type="text"
						:value="consignorCountryCode"
						:disabled="true"
					/>
					<input
						placeholder="Vat No"
						type="text"
						:value="consignorVatNo"
						:disabled="true"
					/>
				</div>
				<div class="party-column-2 simpleInput">
					<span v-text="'Delivery Party'"/>
					<input
						placeholder="Delivery name"
						type="text"
						:value="deliveryPartyName"
						:disabled="true"
					/>
					<input
						placeholder="Address Line"
						type="text"
						:value="deliveryPartyAddressline"
						:disabled="true"
					/>
					<input
						placeholder="Address Line 2"
						type="text"
						:value="deliveryPartyAddressline2"
						:disabled="true"
					/>
					<input
						placeholder="Postal Code"
						type="text"
						:value="deliveryPartyPostalCode"
						:disabled="true"
					/>
					<input
						placeholder="Postal Place"
						type="text"
						:value="deliveryPartyPostalPlace"
						:disabled="true"
					/>
					<input
						placeholder="Country Code"
						type="text"
						:value="deliveryPartyCountryCode"
						:disabled="true"
					/>
					<input
						placeholder="Vat No"
						type="text"
						:value="deliveryPartyVatNo"
						:disabled="true"
					/>
				</div>
				<div class="party-column-3 simpleInput">
					<span v-text="'Carrier'"/>
					<input
						placeholder="Carrier name"
						type="text"
						:value="carrierName"
						:disabled="true"
					/>
					<input
						placeholder="Address Line"
						type="text"
						:value="carrierAddressline"
						:disabled="true"
					/>
					<input
						placeholder="Address Line 2"
						type="text"
						:value="carrierAddressline2"
						:disabled="true"
					/>
					<input
						placeholder="Postal Code"
						type="text"
						:value="carrierPostalCode"
						:disabled="true"
					/>
					<input
						placeholder="Postal Place"
						type="text"
						:value="carrierPostalPlace"
						:disabled="true"
					/>
					<input
						placeholder="Country Code"
						type="text"
						:value="carrierCountryCode"
						:disabled="true"
					/>
					<input
						placeholder="Vat No"
						type="text"
						:value="carrierVatNo"
						:disabled="true"
					/>
				</div>
				<div class="party-column-4 simpleInput">
					<span v-text="'Customer'"/>
					<input
						placeholder="Customer name"
						type="text"
						:value="customerName"
						:disabled="true"
					/>
					<input
						placeholder="Address Line"
						type="text"
						:value="customerAddressline"
						:disabled="true"
					/>
					<input
						placeholder="Address Line 2"
						type="text"
						:value="customerAddressline2"
						:disabled="true"
					/>
					<input
						placeholder="Postal Code"
						type="text"
						:value="customerPostalCode"
						:disabled="true"
					/>
					<input
						placeholder="Postal Place"
						type="text"
						:value="customerPostalPlace"
						:disabled="true"
					/>
					<input
						placeholder="Country Code"
						type="text"
						:value="customerCountryCode"
						:disabled="true"
					/>
					<input
						placeholder="Vat No"
						type="text"
						:value="customerVatNo"
						:disabled="true"
					/>
				</div>
			</div>

			<div class="componentHeader">
				Details
			</div>
			<div class="first-row-details">
				<div class="first-column-details">
					<div class="simpleInput">
						<span v-text="'OrderNumber'" />
						<input
							type="text"
							:value="orderNumber"
							:disabled="true"
						/>
					</div>
					<div class="simpleInput">
						<span v-text="'Order Date'" />
						<date-picker
							size="sm"
							title="Order Date"
							:value="orderDate"
							:disabled="true"
							class="grid-box"
							dateFormat="DD.MM.YYYY HH:mm"
						/>
					</div>
					<div class="simpleInput">
						<span v-text="'ETD'" />
						<date-picker
							size="sm"
							title="ETD"
							:value="etd"
							:disabled="true"
							class="grid-box"
							dateFormat="DD.MM.YYYY HH:mm"
						/>
					</div>
					<div class="simpleInput">
						<span v-text="'ETA'" />
						<date-picker
							size="sm"
							title="ETA"
							:value="eta"
							:disabled="true"
							class="grid-box"
							dateFormat="DD.MM.YYYY HH:mm"
						/>
					</div>
				</div>
				<div class="second-column-details">
					<div class="simpleInput">
						<span v-text="'Customs Agent'" />
						<input
							type="text"
							:value="agent"
							:disabled="true"
						/>
					</div>
					<div class="simpleInput">
						<span v-text="'terms of Delivery Code'" />
						<input
							type="text"
							:value="termsOfDeliveryCode"
							:disabled="true"
						/>
					</div>
					<div class="simpleInput">
						<span v-text="'Terms of Delivery Place'" />
						<input
							type="text"
							:value="termsOfDeliveryPlace"
							:disabled="true"
						/>
					</div>
					<div class="simpleInput">
						<span v-text="'Clearance Date'" />
						<date-picker
							size="sm"
							title="Clearance Date"
							:value="clearanceDate"
							:disabled="true"
							class="grid-box"
							dateFormat="DD.MM.YYYY HH:mm"
						/>
					</div>
				</div>
				<div class="third-column-details" v-if="invoices">
					<div class="third-column-details-content simpleInput">
						<span>Invoice number</span>
						<span>Amount</span>
						<span>Currency</span>
						<span>Invoice Date</span>
					</div>
					<div
						class="third-column-details-content simpleInput"
						v-for="(invoice, index) in invoices"
						:key="index"
					>
						<input
							type="text"
							:value="invoice.invoiceNo"
							:index="index"
							:disabled="true"
						/>
						<input
							type="text"
							:value="invoice.amount"
							:index="index"
							:disabled="true"
						/>
						<input
							type="text"
							:value="invoice.currency"
							:index="index"
							:disabled="true"
						/>
						<date-picker
							size="sm"
							title="Invoice Date"
							:value="parseDate(invoice.invoiceDate.date)"
							:disabled="true"
							class="grid-box"
							dateFormat="DD.MM.YYYY HH:mm"
						/>
					</div>
				</div>
				<div v-else>No invoices</div>
			</div>

			<div class="componentHeader">
				Items
			</div>
			<div class="first-row-items simpleInput" v-if="lines">
				<span>Name</span>
				<span>Latin Name</span>
				<span>Producer Code</span>
				<span>Item Size</span>
				<span>Number of Packages</span>
				<span>References</span>
			</div>
			<div v-else>No items to show</div>
			<div class="first-row-items simpleInput" v-for="(line, index) in lines" :key="index">
				<input
					type="text"
					:value="line.itemName"
					:index="index"
					:disabled="true"
				/>
				<input
					type="text"
					:value="line.itemNameLatin"
					:index="index"
					:disabled="true"
				/>
				<input
					type="text"
					:value="line.producerCode"
					:index="index"
					:disabled="true"
				/>
				<input
					type="text"
					:value="line.itemSize"
					:index="index"
					:disabled="true"
				/>
				<input
					type="text"
					:value="line.numberOfPackages"
					:index="index"
					:disabled="true"
				/>
				<input
					type="text"
					:value="(line.refs || []).map(ref => `${ref.text ? `${ref.text} ` : ''}Code: ${ref.code}`).join(', ')"
					:index="index"
					:disabled="true"
				/>
				<!-- <select placeholder="References" class="lookDisabled">
					<option v-for="(ref, index) in line.refs" :key="index" :disabled="true"
						v-text="`${ref.text ? `${ref.text} ` : ''}Code: ${ref.code}`"
					/>
				</select> -->
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import DatePicker from './DatePicker.vue';

export default {
	name: 'OrderDeclaration',
	props: ['declarationData'],
	components: {
		DatePicker
	},
	data() {
		return {
			declaration: this.declarationData || {},
		};
	},
	computed: {
		orderNo() {
		return this.declaration.orderNo;
		},
		orderType() {
		return this.declaration.orderType;
		},
		consignorName() {
			if(this.declaration.consignor){
				return this.declaration.consignor.name;
			}
			return null;
		},
		consignorAddressline() {
			if(this.declaration.consignor){
				return this.declaration.consignor.address1;
			}
			return null;
		},
		consignorAddressline2() {
			if(this.declaration.consignor){
				return this.declaration.consignor.address2;
			}
			return null;
		},
		consignorCountryCode() {
			if(this.declaration.consignor){
				return this.declaration.consignor.countryCode;
			}
			return null;
		},
		consignorVatNo() {
			if(this.declaration.consignor){
				return this.declaration.consignor.vatNo;
			}
			return null;
		},
		consignorPostalCode() {
			if(this.declaration.consignor){
				return this.declaration.consignor.postcode;
			}
			return null;
		},
		consignorPostalPlace() {
			if(this.declaration.consignor){
				return this.declaration.consignor.postalPlace;
			}
			return null;
		},
		deliveryPartyName() {
			if(this.declaration.deliveryParty){
				return this.declaration.deliveryParty.name;
			}
			return null;
		},
		deliveryPartyAddressline() {
			if(this.declaration.deliveryParty){
				return this.declaration.deliveryParty.address1;
			}
			return null;
		},
		deliveryPartyVatNo() {
			if(this.declaration.deliveryParty){
				return this.declaration.deliveryParty.vatNo;
			}
			return null;
		},
		deliveryPartyAddressline2() {
			if(this.declaration.deliveryParty){
				return this.declaration.deliveryParty.address2;
			}
			return null;
		},
		deliveryPartyCountryCode() {
			if(this.declaration.deliveryParty){
				return this.declaration.deliveryParty.countryCode;
			}
			return null;
		},
		deliveryPartyPostalCode() {
			if(this.declaration.deliveryParty){
				return this.declaration.deliveryParty.postcode;
			}
			return null;
		},
		deliveryPartyPostalPlace() {
			if(this.declaration.deliveryParty){
				return this.declaration.deliveryParty.postalPlace;
			}
			return null;
		},
		carrierName() {
			if(this.declaration.carrier){
				return this.declaration.carrier.name;
			}
			return null;
		},
		carrierVatNo() {
			if(this.declaration.carrier){
				return this.declaration.carrier.vatNo;
			}
			return null;
		},
		carrierAddressline() {
			if(this.declaration.carrier){
				return this.declaration.carrier.address1;
			}
			return null;
		},
		carrierAddressline2() {
			if(this.declaration.carrier){
				return this.declaration.carrier.address2;
			}
			return null;
		},
		carrierCountryCode() {
			if(this.declaration.carrier){
				return this.declaration.carrier.countryCode;
			}
			return null;
		},
		carrierPostalCode() {
			if(this.declaration.carrier){
				return this.declaration.carrier.postcode;
			}
			return null;
		},
		carrierPostalPlace() {
			if(this.declaration.carrier){
				return this.declaration.carrier.postalPlace;
			}
			return null;
		},
		customerName() {
			if(this.declaration.customer){
				return this.declaration.customer.name;
			}
			return null;
		},
		customerVatNo() {
			if(this.declaration.customer){
				return this.declaration.customer.vatNo;
			}
			return null;
		},
		customerAddressline() {
			if(this.declaration.customer){
				return this.declaration.customer.address1;
			}
			return null;
		},
		customerAddressline2() {
			if(this.declaration.customer){
				return this.declaration.customer.address2;
			}
			return null;
		},
		customerCountryCode() {
			if(this.declaration.customer){
				return this.declaration.customer.countryCode;
			}
			return null;
		},
		customerPostalCode() {
			if(this.declaration.customer){
				return this.declaration.customer.postcode;
			}
			return null;
		},
		customerPostalPlace() {
			if(this.declaration.customer){
				return this.declaration.customer.postalPlace;
			}
			return null;
		},
		etd() {
			if(this.declaration.etd){
				return this.parseDate(this.declaration.etd.date);
			}
			return null;
		},
		etdError() {
		return this.declaration.etdError;
		},
		eta() {
			if(this.declaration.eta){
				return this.parseDate(this.declaration.eta.date);
			}
			return null;
		},
		etaError() {
		return this.declaration.etaError;
		},
		orderDate() {
			if(this.declaration.orderDate){
				return this.parseDate(this.declaration.orderDate.date);
			}
			return null;
		},
		orderDateError() {
		return this.declaration.orderDateError;
		},
		orderNumber() {
		return this.declaration.orderNo;
		},
		termsOfDeliveryCodeError() {
		return null;
		},
		customsAgent() {
		return this.customsAgentName;
		},
		agent() {
		return this.declaration.customsAgent;
		},
		termsOfDeliveryCode() {
		return this.declaration.termsOfDeliveryCode;
		},
		interchangeId() {
		return this.declaration.interchangeId;
		},
		termsOfDeliveryPlace() {
		return this.declaration.termsOfDeliveryPlace;
		},
		clearanceDate() {
			if(this.declaration.clearanceDate){
				return this.parseDate(this.declaration.clearanceDate.date);
			}
			return null;
		},
		lines() {
		return this.declaration.lines;
		},
		invoices() {
		return this.declaration.invoices;
		},
		externalDocuments() {
		return this.declaration.externalDocuments;
		},
		kghReceiptDetails() {
		return null;
		},
	},
	methods: {
		parseDate(date){
			return moment(date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
		},
	}
};
</script>

<style lang="scss" scoped>

.componentContent {
	display: grid;
	grid-gap: 10px;
	margin: 1rem;
	width: 100%;
	grid-template-columns: auto;
}
@media (min-width: 768px) {
	.componentContent {
		grid-template-columns: auto;
	}
}
.componentHeader {
    background-color: inherit;
    font-weight: 600;
    font-size: 12px;
    color: #9fa8b0;
    text-align: left;
}
.componentHeader:not(:first-of-type){
    margin-top: 1.5em;
}
.componentContainer {
	background-color: inherit;
	// margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	display:flex;
}
.componentContainer.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.componentContainer.flex-fill {
	flex: 1;
}

.simpleInput > * {
	display: block;
	text-align: left;
}
.lookDisabled {
    background-color: #f8f8f8;
    border-color: rgba(118, 118, 118, 0.3);
}


.first-row {
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.party-row {
  display: grid;
  grid-template-columns: 160px 160px 160px 160px;
  grid-gap: 30px;
}

.party-column-1 {
  grid-column: 1;
}

.party-column-2 {
  grid-column: 2;
}

.party-column-3 {
  grid-column: 3;
}

.party-column-4 {
  grid-column: 4;
}

.first-row-details {
  display: grid;
  grid-template-columns: 160px 160px auto;
  grid-gap: 30px;
}

.first-column-details {
  grid-column: 1;
}

.second-column-details {
  grid-column: 2;
}

.third-column-details {
  grid-column: 3;
}

.third-column-details-content {
  display: grid;
  grid-template-columns: 90px 95px 54px 150px;
}

.first-row-items {
  display: grid;
  grid-template-columns: 260px 160px 100px 100px 120px 220px;
  // grid-gap: 30px;
}

span {
  font-size: 11px;
  //margin-top: 5px;
  margin-bottom: 2px;
}
</style>
