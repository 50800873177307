<template>
	<modal
		:visible="show"
		@closing="handleClosing"
		@close="handleClose"
		:title="title"
		centered
		class="multipleEditModal"
		:okButton="false"
		:cancelButton="false"
	>
		<b-container fluid class="componentContainer">
			<div style="grid-area:atd;">
				<div class="summaryHeader">
					Atd
				</div>
				<b-input-group>
					<date-picker
						size="sm"
						:value="atd"
						:disabled="(!editable || atdEmpty)"
						:invalid="atdInvalid"
						:placeholder="(atdEmpty?'Empty':'Actual time departure')"
						dateFormat="DD.MM.YYYY HH:mm"
						@input="onDateChanged($event, 'atdDate')"
					></date-picker>
					<b-input-group-append>
						<b-btn
							size="sm"
							:pressed.sync="atdEmpty"
							:disabled="!editable"
							@click="onDateChanged(null, 'atdDate')"
						>Leave empty</b-btn>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div style="grid-area:ata;">
				<div class="summaryHeader">
					Ata
				</div>
				<b-input-group>
					<date-picker size="sm" :value="ata" :disabled="(!editable || ataEmpty)" :invalid="ataInvalid" :placeholder="(ataEmpty?'Empty':'Actual time arrival')" dateFormat="DD.MM.YYYY HH:mm" @input="onDateChanged($event, 'ataDate')"></date-picker>
					<b-input-group-append>
						<b-btn
							size="sm"
							:pressed.sync="ataEmpty"
							:disabled="!editable"
							@click="onDateChanged(null, 'ataDate')"
						>Leave empty</b-btn>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div style="grid-area:awb;">
				<div class="summaryHeader">
					Air Waybill
				</div>
				<b-input-group>
					<b-form-input
						size="sm"
						type="text"
						:placeholder="(awbEmpty ? 'Empty' : 'Air Waybill')"
						:disabled="(!editable || awbEmpty)"
						v-model="awb"
					></b-form-input>
					<b-input-group-append>
						<b-btn
							size="sm"
							:pressed.sync="awbEmpty"
							:disabled="!editable"
							:state="validityChecked ? awbValid : null"
							@click="awb=null"
						>Leave empty</b-btn>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div style="grid-area:customs;">
				<div class="summaryHeader">
					Customs crossing point
				</div>
				<b-input-group>
					<b-form-select
						class="modalSelect"
						size="sm"
						v-model="ccp"
					>
						<option value="null">None selected</option>
						<option
							v-for="point in customsPoints"
							:key="point.tollst"
							class="customs-point"
							:value="point.tollst"
							v-text="point.tollsted"
							:disabled="point.disabled"
						/>
					</b-form-select>
					<b-input-group-append>
						<b-button
							size="sm"
							:pressed.sync="ccpEmpty"
							:disabled="!editable"
							@click="ccp=null"
						>
							Leave empty
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div style="grid-area:vehicle;">
				<div class="summaryHeader">
					VehicleLicense
				</div>
				<b-input-group>
					<b-form-input
						size="sm"
						type="text"
						:placeholder="(vRegEmpty?'Empty':'License Plate')"
						:disabled="(!editable || vRegEmpty)"
						:state="validityChecked ? vRegValid : null"
						v-model="vReg"
					></b-form-input>
					<b-form-select
						class="modalSelect"
						size="sm"
						v-model="vNat"
						:disabled="(!editable || vRegEmpty)"
						:state="validityChecked ? vNatValid : null"
					>
						<option value="null">Vehicle nationality</option>
						<option
							v-for="(nation, index) in nationality"
							:key="index"
							:value="nation.value"
							v-text="nation.text"
						/>
					</b-form-select>
					<b-input-group-append>
						<b-button
							size="sm"
							:pressed.sync="vRegEmpty"
							:disabled="!editable"
							@click="vReg=null;vNat=null"
						>
							Leave empty
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
			<div style="grid-area:driver;">
				<div class="summaryHeader">
					Vehicle Driver
				</div>
				<b-input-group>
					<b-form-input
						size="sm"
						type="text"
						:placeholder="(vNamEmpty?'Empty':'Drivers Name')"
						:disabled="(!editable || vNamEmpty)"
						v-model="vNam"
					></b-form-input>
					<b-input-group-append>
						<b-button
							size="sm"
							:pressed.sync="vNamEmpty"
							:disabled="!editable"
							@click="vNam=null"
						>
							Leave empty
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
		</b-container>
		<b-container slot="footer" fluid>

			<b-btn v-if="!dataHasChanges" slot="modal-cancel" variant="primary" @click="modalHide">Close</b-btn>
			<b-btn v-if="dataHasChanges" variant="secondary" @click="reloadAndClose"><font-awesome-icon style="margin-right: .75rem;" icon="undo-alt"></font-awesome-icon> Close without saving</b-btn>
			<template v-if="dataHasChanges">
				<b-btn v-if="!valid && validityChecked" disabled variant="danger"><font-awesome-icon style="margin-right: .75rem;" icon="times"></font-awesome-icon> Information invalid</b-btn>
				<b-btn v-else variant="primary" @click="saveDraft"><font-awesome-icon style="margin-right: .75rem;" icon="save"></font-awesome-icon> Save and close</b-btn>
			</template>
			<!-- <b-btn v-if="orderReady" variant="success" @click="modalHide"><font-awesome-icon style="margin-right: .75rem;" icon="check"></font-awesome-icon> Finalize order</b-btn> -->
			<!-- <icon-button v-if="dataHasChanges" icon="undo-alt" label="Undo changes" :onClicked="reloadSelectedOrder"></icon-button>
			<icon-button v-if="dataHasChanges" icon="save" label="Save draft" :onClicked="saveDraft"></icon-button> -->

		</b-container>
		<!-- <div slot="modal-footer">
		</div> -->
	</modal>
</template>

<script>
//<icon-button v-if="dataHasChanges" :icon="{'save': !isLoading('saveDraft'), 'spinner': isLoading('saveDraft')}" label="Save draft" :onClicked="saveDraft"></icon-button>

//import moment from 'moment-timezone';
import { mapState, mapActions } from '../../store';
import StackableModal from '../../../StackableModal.vue';

import DatePicker from '../DatePicker.vue';
// import IconButton from '../../IconButton.vue';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
	name: 'OrdersMultipleModal',
	props: ['visible'],
	components: {
		'modal': StackableModal,
		FontAwesomeIcon,
		DatePicker
	},
	data() {
		return {
			show: this.visible,
			validityChecked: false,
			ataDate: {date:null,hasTime:false},
			ataEmpty: false,
			atdDate: {date:null,hasTime:false},
			atdEmpty: false,
			awb: null,
			awbEmpty: false,
			ccp: null,
			ccpEmpty: false,
			vReg: null,
			vRegEmpty: false,
			vNat: null,
			vNatEmpty: false,
			vNam: null,
			vNamEmpty: false,
		};
	},
	computed: {
		...mapState({
			orderData: 'selectedOrders',
			customsPoints: 'customsPoints',
			nationality: 'nationality',
		}),
		atd() {
			return this.atdDate.date == null ? null : moment(this.atdDate.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
		},
		ata() {
			return this.ataDate.date == null ? null : moment(this.ataDate.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
		},
		title() {
			if(this.orderData.length){
				return `Delivery No: ${this.orderData.map(o => o.poReference).sort(sortDeliveryNo).join(', ')}`;
			}
			return '';
		},
		editable() {
			for(let order of this.orderData){
				if(order.status <= 0 && order.status >= 100) return false;
			}
			return true;
		},
		ataInvalid(){
			if(this.ataDate.date !== null){
				let long = 'YYYYMMDDHHmm';
				let atd = moment(this.atdDate.date, long).isValid();
				let ataLong = moment(this.ataDate.date, long);
				if(
					!(atd ? ataLong.isSameOrAfter(moment(this.atdDate.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		atdInvalid(){
			if(this.atdDate.date !== null){
				let long = 'YYYYMMDDHHmm';
				let ata = moment(this.ataDate.date, long).isValid();
				let atdLong = moment(this.atdDate.date, long);
				if(
					!(ata ? atdLong.isSameOrBefore(moment(this.ataDate.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		vRegValid(){
			if(
				!(this.vRegEmpty&&this.vNatEmpty) &&
				(!this.vReg && this.vNat)
			){
				return false;
			}
			return null;
		},
		vNatValid(){
			if(
				!(this.vRegEmpty&&this.vNatEmpty) &&
				(this.vReg && !this.vNat)
			){
				return false;
			}
			return null;
		},
		awbValid(){
			if(
				!this.awbEmpty &&
				(this.awb && !(this.awb).match('([0-9]{3})-([0-9]{8})'))
			){
				return false;
			}
			return null;
		},
		valid(){
			var valid = (
				this.vRegValid === null &&
				this.vNatValid === null &&
				this.awbValid === null &&
				!this.atdInvalid && !this.ataInvalid
			);
			return valid;
		},
		dataHasChanges(){
			if(this.editable){
				if(
					this.atdDate.date !== null ||
					this.ataDate.date !== null ||
					this.vNat !== null ||
					this.ccp !== null ||
					(this.vReg !== null && this.vReg !== '') ||
					(this.vNam !== null && this.vNam !== '') ||
					(this.awb !== null && this.awb !== '') ||
					this.atdEmpty != false ||
					this.ataEmpty != false ||
					this.vRegEmpty != false ||
					this.vNamEmpty != false ||
					this.vNatEmpty != false ||
					this.ccpEmpty != false ||
					this.awbEmpty != false
				) {
					return true;
				}
			}
			return false;
		},
	},
	watch: {
		valid: function(val) {
			if(val){
				this.validityChecked = false;
			}
		}
	},
	methods: {
		...mapActions({
			save: 'saveMultipleDraft'
		}),
		handleClose(){
			this.$emit('close', this.orderData);
		},
		handleClosing(abortClose) {
			if(this.dataHasChanges){
				abortClose(true);
				this.$dialog.confirm('Close without saving changes?', {
					okText: 'Save Changes',
					cancelText: 'Don\'t save',
					animation: 'bounce'
				}).then(() => {
					this.saveDraft();
				}).catch(() => {
					this.reloadAndClose();
				});
			} else {
				abortClose(false);
			}
		},
		modalHide() {
			this.show = false;
		},
		async saveDraft(){
			if(!this.valid){
				this.validityChecked=true;
				return;
			}

			this.prepForSave();
			await this.save();
			this.reloadAndClose();
		},
		prepForSave(){
			for(let order of this.orderData){
				if(this.atdEmpty){
					order.atd = this.atdDate;
				} else if(this.atdDate.date !== null){
					order.atd = this.atdDate;
				}
				if(this.ataEmpty){
					order.ata = this.ataDate;
				} else if(this.ataDate.date !== null){
					order.ata = this.ataDate;
				}
				if(this.ccpEmpty){
					order.customsCrossingPoint = this.ccp;
				} else if(this.ccp !== null){
					order.customsCrossingPoint = this.ccp;
				}
				if(this.vNatEmpty){
					order.vehicleNationality = this.vNat;
				} else if(this.vNat !== null){
					order.vehicleNationality = this.vNat;
				}
				if(this.vRegEmpty){
					order.vehicleLicensePlate = this.vReg;
				} else if(this.vReg !== null && this.vReg !== ''){
					order.vehicleLicensePlate = this.vReg;
				}
				if(this.vNamEmpty){
					order.vehicleDriverName = this.vNam;
				} else if(this.vNam !== null && this.vNam !== ''){
					order.vehicleDriverName = this.vNam;
				}
				if(this.awbEmpty){
					order.awbReference = this.awb;
				} else if(this.awb !== null && this.awb !== ''){
					order.awbReference = this.awb;
				}
			}
		},
		reloadAndClose(){
			this.ataDate = {date:null,hasTime:false};
			this.ataEmpty = false;
			this.atdDate = {date:null,hasTime:false};
			this.atdEmpty = false;
			this.awb = null;
			this.awbEmpty = false;
			this.ccp = null;
			this.ccpEmpty = false;
			this.vReg = null;
			this.vRegEmpty = false;
			this.vNat = null;
			this.vNatEmpty = false;
			this.vNam = null;
			this.vNamEmpty = false;
			this.modalHide();
		},
		onDateChanged(value, inputType){
			// console.log(moment(moment(value, 'DD.MM.YYYY HH:mm').format('YYYYMMDDHHmm')));
			let dateValid = moment(value, 'DD.MM.YYYY HH:mm').isValid();
			let newDateObj = {
				date: dateValid ? moment(value, 'DD.MM.YYYY HH:mm').format('YYYYMMDDHHmm') : null,
				hasTime: dateValid,
				timeZone: 'Europe/Oslo'
			};
			this[inputType] = newDateObj;
		},
	},
};

function sortDeliveryNo(a,b) {
	var as = splitByType(a);
	var bs = splitByType(b);
	return sortNumber(as, bs);
}
function sortNumber(as, bs) {
	if(!as.length && !bs.length) return 0;
	var a = as[0];
	var b = bs[0];
	if (!a && !b || a === b) return sortString(as.slice(1), bs.slice(1));
	if (!a) return 1;
	if (!b) return -1;
	return +a - +b;
}
function sortString(as, bs) {
	if(!as.length && !bs.length) return 0;
	var a = as[0];
	var b = bs[0];
	if (!a && !b || a === b) return sortNumber(as.slice(1), bs.slice(1));
	if (!a) return 1;
	if (!b) return -1;
	return (a < b) ? -1 : 1;
}
function splitByType(s) {
	var result = [];
	var part = '';
	var type = 0;
	for (var i = 0; i < s.length; i++) {
		var c = s.charAt(i);
		if (type == 0 && !isNaN(c)) part += c;
		else if (type == 1 && isNaN(c)) part += c;
		else {
			result.push(part);
			part = c;
			type = 1 - type;
		}
	}
	result.push(part);
	return result;
}

</script>

<style lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'atd' 'ata' 'awb' 'customs' 'vehicle' 'driver';
}
@media (min-width: 768px) {
	.componentContainer {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
		'atd ata' 'awb customs' 'vehicle driver';
	}
}
.multipleEditModal /deep/ footer.modal-footer button{
	margin: 0 5px;
}
.multipleEditModal /deep/ footer.modal-footer button:last-child{
	margin-right: 0;
}
.multipleEditModal /deep/ .summaryHeader {
	background-color: inherit;
	font-size: 1rem;
	padding: 0 .5rem;
	position: static;
	font-weight: 600;
	margin-left: 1rem;
	text-align: left;
	margin-top: 10px;
}
.multipleEditModal /deep/ .summaryWrapper {
	background-color: inherit;
	margin-top: 2.5rem;
	position: relative;
	font-size: 1rem;
}
.multipleEditModal /deep/ .summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.btn-group:not(:last-child) {
	margin-bottom: 1.5rem;
}
.btn-group /deep/ input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.modalSelect {
	padding-top: 0;
	padding-bottom: 0;
}
option.customs-point:disabled {
	display: none;
	pointer-events: none;
}
</style>

