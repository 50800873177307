<template>
	<div class="summaryWrapper border flex-fill">
		<div class="summaryHeader">
			Prices
		</div>
		<div class="componentContainer" :class="{editable: orderStatus == 20}">
			<div class="estimated" style="grid-area:estimated;">
				<span>
					Estimated
				</span>
				<ul class="priceList">
					<li v-for="price in estimatedPriceItems" :key="price.id">
						<div class="priceItem estimated">
							<span
								style="grid-area:type;"
								class="type"
							>{{ getTypeDescription(price.type) }}:</span>
							<span
								style="grid-area:description;"
								class="description"
							>{{ price.description }}</span>
							<span
								style="grid-area:price;"
							>{{ price.currency }} {{ price.netAmount }}</span>
						</div>
					</li>
				</ul>
			</div>
			<div class="actual" style="grid-area:actual;">
				<span>
					Actual
				</span>
				<ul class="priceList">
					<template v-if="orderStatus != 20">
						<li v-for="price in sortedPriceItems" :key="price.id">
							<div class="priceItem">
								<span
									style="grid-area:type;"
									class="type"
								>{{ price.type ? `${getTypeDescription(price.type)}: ` : '' }}</span>
								<span
									style="grid-area:description;"
									class="description"
								>{{ price.description }}</span>
								<span
									v-if="price.aggregation"
									style="grid-area:aggregation;"
									class="aggregation"
								>{{ price.aggregation ? `${getAggregationDescription(price.aggregation)} (${price.basePrice} ${price.currency})` : `` }}</span>
								<span
									style="grid-area:price;"
								>{{ price.currency }} {{ price.netAmount }}</span>
								<span
									style="grid-area:accepted;"
									:class="{'accepted': price.isAccepted}"
								>
									<font-awesome-icon
										v-if="price.isAccepted"
										style="color:#28a745;"
										icon="check-circle"
										class="fa-md"
									/>
									<font-awesome-icon
										v-else-if="price.parentId"
										:title="`From Transportneed ${orderCollectionRef}`"
										style="color:#6c757d;"
										icon="question-circle"
										class="fa-md close"
									/>
								</span>
							</div>
						</li>
					</template>
					<template v-else>
						<li v-for="price in sortedPriceItems" :key="price.id" class="new">
							<div class="priceItem editable">
								<b-input-group :ref="price.id">
									<b-form-select
										style="grid-area:type;"
										class="type"
										size="sm"
										title="Price type"
										:value="price.type"
										@change="onTypeChanged($event, price.id)"
										:disabled="(!!price.parentId || !editable)"
									>
										<option class="font-italic" :value="null">Type</option>
										<option
											v-for="(type, index) in priceTypes" :key="index"
											:value="type.type"
											v-text="type.description"
										>
										</option>
									</b-form-select>
									<b-form-input
										style="grid-area:description;"
										size="sm"
										class="description"
										title="Price description"
										placeholder="Description"
										type="text"
										:value="price.description"
										:disabled="(!!price.parentId || !editable)"
										@change="onDescriptionChanged($event, price.id)"
									></b-form-input>
									<b-form-select
										style="grid-area:aggregation;"
										class="aggregation"
										size="sm"
										title="Price aggregation"
										:value="price.aggregation"
										:disabled="(!!price.parentId || !editable)"
										@change="onAggregationChanged($event, price.id)"
									>
										<option class="font-italic" :value="null">Aggregation (none)</option>
										<option
											v-for="(type, index) in priceAggregations" :key="index"
											:value="type.type"
											:disabled="!type.applicable(orderData)"
											:class="{ 'font-italic' : !type.applicable(orderData) }"
											v-text="type.description"
										>
										</option>
									</b-form-select>
									<b-form-select
										style="grid-area:currency;"
										class="currency"
										size="sm"
										title="Price currency"
										v-model="price.currency"
										@change="onCurrencyChanged($event, price.id)"
										:disabled="(!!price.parentId || !editable)"
									>
										<option class="font-italic" value="NOK">NOK</option>
										<option class="font-italic" value="EUR">EUR</option>
									</b-form-select>
									<b-form-input
										v-if="!price.aggregation"
										style="grid-area:net;"
										size="sm"
										class="amount"
										title="Net amount"
										placeholder="Net amount"
										type="number"
										step="any"
										:value="price.netAmount"
										:disabled="(!!price.parentId || !editable)"
										@change="onNetChanged($event, price.id)"
									></b-form-input>
									<b-form-input
										v-else
										style="grid-area:net;"
										size="sm"
										class="amount"
										title="Base price"
										placeholder="Base price"
										type="number"
										step="any"
										:value="price.basePrice"
										:disabled="(!!price.parentId || !editable)"
										@change="onBasePriceChanged($event, price.id)"
									></b-form-input>
								</b-input-group>
								<font-awesome-icon v-if="!price.parentId" @click="removePriceItem(price.id)" style="color:#6c757d;" icon="times" class="fa-md close"/>
								<font-awesome-icon
									v-else
									:title="`From Transportneed ${orderCollectionRef}`"
									style="color:#6c757d;"
									icon="question-circle"
									class="fa-md close"
								/>
							</div>
						</li>
					</template>
				</ul>
			</div>
			<div class="buttonContainer" style="grid-area:buttonContainer;">
				<template v-if="orderStatus < 20">
					<b-btn @click="requestNewPrices" class="b-btn" size="sm" :disabled="!editable" variant="secondary">Request new prices</b-btn>
					<b-btn @click="acceptExistingPrices" class="b-btn" size="sm" :disabled="!editable" variant="success">Accept prices</b-btn>
				</template>
				<template v-else-if="orderStatus == 20">
					<b-btn @click="addPriceItem" class="b-btn" size="sm" :disabled="!editable" variant="secondary">Add price item</b-btn>
					<b-btn v-if="!priceInformationValid && validityChecked" class="b-btn" size="sm" disabled variant="danger">Prices invalid</b-btn>
					<b-btn v-else @click="approveNewPrices" class="b-btn" size="sm" :disabled="!editable" variant="primary">Approve new prices</b-btn>
				</template>
				<template v-else>
					<b-btn @click="editExistingPrices" class="b-btn" size="sm" :disabled="!editable" variant="secondary">Edit prices</b-btn>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from '../store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
	name: 'OrderPriceList',
	props: ['editable'],
	components: {
		FontAwesomeIcon
	},
	computed: {
		...mapState({
			orderData: 'selectedOrderData',
			priceTypes: 'priceTypes',
			priceAggregations: 'priceAggregations',
			validityChecked: 'validityChecked'
		}),
		estimatedPriceItems(){
			return this.orderData.transporter.estimatedPriceItems;
		},
		priceItems(){
			return this.orderData.transporter.priceItems;
		},
		sortedPriceItems() {
			return [...this.priceItems].sort((a, b) => !!a.parentId - !!b.parentId);
		},
		orderStatus(){
			return this.orderData.status;
		},
		freightLinesQuantity(){
			return this.orderData.freightLines.map(item => item.quantity).reduce((prev, next) => prev + next, 0);
		},
		freightLinesGrossWeight(){
			return +(this.orderData.freightLines.map(item => item.grossWeight).reduce((prev, next) => prev + next, 0)).toFixed(2);
		},
		priceInformationValid(){
			if(this.orderStatus!==20)return true;

			var priceItems = this.priceItems;
			for(let item of priceItems) {
				// Skip validation for child prices
				if(item.parentId) continue;
				// if(!item.type || !item.description || !item.grossAmount || !item.netAmount){
				// if(!item.type || !item.description || !item.netAmount){
				if(!item.type || !item.netAmount){
					return false;
				}
			}
			return true;
		},
		orderCollectionRef(){
			return this.orderData.orderCollectionNo;
		}
	},
	watch: {
		priceInformationValid: {
			handler: function(val){
				this.validatePriceItemElements();
				this.$emit('validStateChanged', val);
			},
			immediate: true
		},
		validityChecked: {
			handler: function() {
				if(this.orderStatus===20){
					this.validatePriceItemElements();
				}
			}
		},
		freightLinesQuantity: {
			handler: function(newValue, oldValue){
				if(oldValue != newValue) {
					for(let priceItem of this.priceItems.filter(pi => pi.aggregation)){
						this.updateAggregatedPriceAmount(priceItem);
						this.updateSelectedOrderData(this.orderData);
					}
				}
			}
		},
		freightLinesGrossWeight: {
			handler: function(newValue, oldValue){
				if(oldValue != newValue) {
					for(let priceItem of this.priceItems.filter(pi => pi.aggregation)){
						this.updateAggregatedPriceAmount(priceItem);
						this.updateSelectedOrderData(this.orderData);
					}
				}
			}
		},
	},
	methods: {
		...mapMutations([
			'toggleHasPriceChanges',
			'updateSelectedOrderData',
			'addEmptyPriceItemToOrder',
			'removePriceItemFromOrder',
			'spliceEmptyPrices',
			'setValidityChecked',
		]),
		...mapActions([
			'acceptPrices',
			'requestPrices',
			'editPrices',
			'approvePrices'
		]),
		acceptExistingPrices(){
			this.acceptPrices(this.orderData.id);
		},
		requestNewPrices(){
			this.requestPrices(this.orderData.id);
		},
		editExistingPrices(){
			this.editPrices(this.orderData.id);
		},
		approveNewPrices(){
			// console.log(this);
			this.spliceEmptyPrices(this.orderData.id);
			var priceItems = this.orderData.transporter.priceItems;
			var valid = true;
			this.setValidityChecked(true);
			for(let item of priceItems) {
				// Skip validation for child prices
				if(item.parentId) continue;
				// if(!item.type || !item.description || !item.grossAmount || !item.netAmount){
				// if(!item.type || !item.description || !item.netAmount){
				if(!item.type || !item.netAmount){
					this.validatePriceItemElements();
					valid = false;
					break;
				}
			}
			if(this.orderData.transporter.priceItems.length && valid){
				this.setValidityChecked(false);
				this.approvePrices(this.orderData.id);
			}
		},
		validatePriceItemElements(){
			if(!this.validityChecked) return;

			var priceItems = this.orderData.transporter.priceItems;
			for(let item of priceItems) {
				// Skip validation for child prices
				if(item.parentId) continue;

				var el = this.$refs[item.id];
				if(!item.type){
					el[0].children[0].classList.add('is-invalid');
				} else {
					el[0].children[0].classList.remove('is-invalid');
				}
				// if(!item.description){
				// 	el[0].children[1].classList.add('is-invalid');
				// } else {
				// 	el[0].children[1].classList.remove('is-invalid');
				// }
				if(!item.currency){
					el[0].children[3].classList.add('is-invalid');
				} else {
					el[0].children[3].classList.remove('is-invalid');
				}
				if(!item.netAmount){
					el[0].children[4].classList.add('is-invalid');
				} else {
					el[0].children[4].classList.remove('is-invalid');
				}
			}
		},
		addPriceItem() {
			this.addEmptyPriceItemToOrder();
		},
		removePriceItem(priceId) {
			this.removePriceItemFromOrder(priceId);
			if(this.orderData.hasPriceChanges !== true){
				this.toggleHasPriceChanges({id: this.orderData.id, state: true});
			}
		},
		onTypeChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderData.transporter.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.type !== value){
				var priceItemIndex = this.orderData.transporter.priceItems.findIndex(p => p.id === priceId);
				this.orderData.transporter.priceItems[priceItemIndex].type = value;
				this.orderData.transporter.priceItems[priceItemIndex].isAccepted = false;
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onDescriptionChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderData.transporter.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.description !== value){
				var priceItemIndex = this.orderData.transporter.priceItems.findIndex(p => p.id === priceId);
				this.orderData.transporter.priceItems[priceItemIndex].description = value;
				this.orderData.transporter.priceItems[priceItemIndex].isAccepted = false;
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onAggregationChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderData.transporter.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.aggregation !== value){
				var priceItemIndex = this.orderData.transporter.priceItems.findIndex(p => p.id === priceId);
				this.orderData.transporter.priceItems[priceItemIndex].aggregation = value;
				this.orderData.transporter.priceItems[priceItemIndex].isAccepted = false;
				this.updateAggregatedPriceAmount(priceItem);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onCurrencyChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderData.transporter.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.currency !== value){
				var priceItemIndex = this.orderData.transporter.priceItems.findIndex(p => p.id === priceId);
				this.orderData.transporter.priceItems[priceItemIndex].currency = value;
				this.orderData.transporter.priceItems[priceItemIndex].isAccepted = false;
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onGrossChanged(value, priceId) {
			if(value === 'null') value = null;
			else value = parseFloat(value);
			var priceItem = this.orderData.transporter.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.grossAmount !== value){
				var priceItemIndex = this.orderData.transporter.priceItems.findIndex(p => p.id === priceId);
				this.orderData.transporter.priceItems[priceItemIndex].grossAmount = value;
				this.orderData.transporter.priceItems[priceItemIndex].isAccepted = false;
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onNetChanged(value, priceId) {
			if(value === 'null') value = null;
			else value = parseFloat(value);
			var priceItem = this.orderData.transporter.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.netAmount !== value){
				var priceItemIndex = this.orderData.transporter.priceItems.findIndex(p => p.id === priceId);
				this.orderData.transporter.priceItems[priceItemIndex].netAmount = value;
				//
				// Gross removed from gui. Remove if added back
				this.orderData.transporter.priceItems[priceItemIndex].grossAmount = value;
				//
				this.orderData.transporter.priceItems[priceItemIndex].isAccepted = false;
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onBasePriceChanged(value, priceId) {
			if(value === 'null') value = null;
			else value = parseFloat(value);
			var priceItem = this.orderData.transporter.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.basePrice !== value){
				var priceItemIndex = this.orderData.transporter.priceItems.findIndex(p => p.id === priceId);
				this.orderData.transporter.priceItems[priceItemIndex].basePrice = value;
				this.orderData.transporter.priceItems[priceItemIndex].isAccepted = false;
				this.updateAggregatedPriceAmount(priceItem);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderData.id, state: true});
				}
			}
		},
		updateAggregatedPriceAmount(priceItem) {
			let priceId = priceItem.id;
			let order = this.orderData;
			let amount = null;
			let basePriceUnit = null;
			if(priceItem.basePrice && priceItem.aggregation){
				let aggregation = this.getAggregation(priceItem.aggregation);
				basePriceUnit = aggregation.unit;
				let multiplicatorFunction = aggregation.multiplicator;
				let multiplicator = multiplicatorFunction(order);
				amount = priceItem.basePrice * multiplicator;
				amount = parseFloat(amount.toFixed(2)); // Max 2 decimals
			}
			let priceItemIndex = order.transporter.priceItems.findIndex(p => p.id === priceId);
			this.$set(order.transporter.priceItems[priceItemIndex], 'netAmount', amount);
			this.$set(order.transporter.priceItems[priceItemIndex], 'grossAmount', amount);
			this.$set(order.transporter.priceItems[priceItemIndex], 'basePriceUnit', basePriceUnit);
		},
		getTypeDescription(type){
			if(!type) return null;
			let priceType = this.priceTypes.filter(x => x.type === type)[0];
			return priceType.description;
		},
		getAggregationDescription(type){
			if(!type) return null;
			let priceAggregation = this.priceAggregations.filter(x => x.type === type)[0];
			return priceAggregation.description;
		},
		getAggregation(type){
			if(!type) return null;
			let priceAggregation = this.priceAggregations.filter(x => x.type === type)[0];
			return priceAggregation;
		}
	}
};
</script>

<style lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	margin: 1rem;
	width: 100%;
	grid-template-columns: 1fr;
	grid-auto-rows: auto 1fr auto;
	grid-template-areas:
		'estimated' 'actual' 'buttonContainer';
}
@media (min-width: 768px) {
	.componentContainer {
		grid-template-columns: 1fr 2fr;
		grid-auto-rows: 1fr auto;
		grid-template-areas:
			'estimated actual'
			'buttonContainer buttonContainer';
	}
}
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 12px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	// display:flex;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.priceList {
	margin: 1rem 0;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.priceList li {
	display: inline-block;
	padding: 0.3rem;
	min-width: calc( 100% - 0.6rem);
	text-align: left;
}
.priceList li:not(.new):nth-child(odd) {
	background-color: #f0f0f0;
}
.priceList li span.type,
.priceList li span.description {
	text-align: left;
	padding-left: 0.375rem;
}
.priceList li span {
	text-align: right;
}
.priceList li span svg {
	margin-left: .375rem;
}
.summaryWrapper.flex-fill {
	flex: 1;
}
.priceItem {
	display: flex;
	justify-content: space-between;
}
.priceItem .close {
	font-size: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: .25rem;
	width: 16px
}
.priceItem input,
.priceItem select {
	width: 100%;
}
.priceItem .currency,
.priceItem .aggregation,
.priceItem .type {
	padding: 0;
	padding-left: .375rem;
	font-size: 100%;
}
.b-btn {
	margin: 0 5px;
}
.priceItem.editable .input-group {
    display: grid;
    grid-gap: 0px;
    width: 100%;
    grid-template-columns: 2fr 2.5fr 2fr 1fr 1.5fr;
    grid-template-areas: 'type description aggregation currency net';
    // grid-template-columns: 2fr 3fr 1fr 1.5fr 1.5fr;
    // grid-template-areas: 'type description currency gross net';
}
.priceItem:not(.editable) {
    display: grid;
    grid-gap: 0px;
    width: 100%;
    grid-template-columns: 5fr 5fr 5fr 5fr 1fr;
    grid-template-areas: 'type description aggregation price accepted';
}
.priceItem.estimated {
    display: grid;
    grid-gap: 0px;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: 'type description price';
}
</style>

