<template>
	<div class="componentContainer">
		<div class="summaryWrapper" style="grid-area:from;">
			<div class="summaryHeader">
				From
			</div>
			<div class="summaryContainer" v-html="this.addressRender(this.orderData.consignor)">
			</div>
		</div>
		<div class="summaryWrapper" style="grid-area:to;">
			<div class="summaryHeader">
				To
			</div>
			<div class="summaryContainer" v-html="this.addressRender(this.orderData.consignee)">
			</div>
		</div>
		<div v-if="this.orderData.deliveryParty" class="summaryWrapper" style="grid-area:fromParty;">
			<div class="summaryHeader">
				Collect adress
			</div>
			<div class="summaryContainer bold" v-html="this.addressRender(this.orderData.despatchParty)">
			</div>
		</div>
		<div v-if="this.orderData.deliveryParty" class="summaryWrapper" style="grid-area:toParty;">
			<div class="summaryHeader">
				Delivery address
			</div>
			<div class="summaryContainer bold" v-html="this.addressRender(this.orderData.deliveryParty)">
			</div>
		</div>
		<div v-if="this.orderData.incotermsCode" class="summaryWrapper" style="grid-area:incoterms;">
			<div class="summaryHeader">
				Incoterms
			</div>
			<div class="summaryContainer">
				<span class="addressLine">{{ this.orderData.incotermsCode }} - {{ this.orderData.incotermsPlace }}</span>
			</div>
		</div>
		<div class="summaryWrapper border half-width" style="grid-area:toIata;">
			<div class="summaryHeader">
				Airport
			</div>
			<b-form-input
				size="sm"
				title="Airport"
				type="text"
				:disabled="!editable || disableFlight"
				:value="toIata"
				@change="onToIataChanged($event)"
			></b-form-input>
		</div>
		<div class="summaryWrapper border half-width" style="grid-area:departingTerminal;">
			<div class="summaryHeader">
				Departing terminal
			</div>
			<b-input-group>
				<b-form-select
					class="modalSelect"
					:class="{ 'font-italic': !departingTerminalSelected}"
					size="sm"
					title="Vehicle Nationality"
					v-model="departingTerminalSelected"
					@change="onDepartingTerminalChanged($event)"
					:disabled="!editable || disableFlight"
				>
					<option class="font-italic" :value="null"></option>
					<option
						v-for="(terminal, index) in departingTerminals"
						style="font-style:normal;"
						:key="index"
						:value="terminal.value"
						v-text="terminal.text"
					/>
				</b-form-select>
			</b-input-group>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from '../store';
export default {
	name: 'OrderToFrom',
	props: ['editable'],
	data() {
		return {
			departingTerminalSelected: null,
		};
	},
	computed: {
		...mapState({
			orderData: 'selectedOrderData',
			departingTerminals: 'departingTerminals'
		}),
		toIata() {
			let party = this.orderData.deliveryParty ? 'deliveryParty' : 'consignee';
			return this.orderData[party].iataCode || null;
		},
		departingTerminal() {
			return this.orderData.departingTerminal || null;
		},
		disableFlight(){
			if(
				this.orderData.vehicleLicensePlate ||
				this.orderData.vehicleNationality ||
				this.orderData.vehicleDriverName ||
				this.orderData.vehicleType
			) {
				return true;
			}
			return false;
		},
	},
	methods: {
		...mapMutations([
			'toggleHasChanges',
			'updateSelectedOrderData',
		]),
		onToIataChanged(value){
			if(value === '') value = null;
			let party = this.orderData.deliveryParty ? 'deliveryParty' : 'consignee';
			if(this.orderData[party].iataCode !== value){
				this.$set(this.orderData[party], 'iataCode', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		onDepartingTerminalChanged(value){
			if(value === '') value = null;
			if(this.orderData.departingTerminal !== value){
				this.$set(this.orderData, 'departingTerminal', value);
				this.updateSelectedOrderData(this.orderData);
				if(this.orderData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderData.id, state: true});
				}
			}
		},
		addressRender(party) {
			var firstLine = [party.companyName, party.street].filter(x => !!x);
			firstLine = firstLine.join(', ');
			var secondLine = [party.address2, party.address3].filter(x => !!x);
			secondLine = secondLine.join(', ');
			var thirdLine = [party.countryCode, party.postalCode].filter(x => !!x);
			thirdLine = thirdLine.join('-');
			thirdLine = [thirdLine, party.postalPlace, party.iataCode].filter(x => !!x);
			thirdLine = thirdLine.join(', ');
			return `
				<div class="addressLine">${firstLine}</div>
				<div class="addressLine">${secondLine}</div>
				<div class="addressLine">${thirdLine}</div>
			`;
		},
	},
	beforeMount() {
		this.departingTerminalSelected = this.departingTerminal;
	}
};
</script>

<style  lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'from' 'to' 'fromParty' 'toParty' 'departingTerminal' 'toIata' 'incoterms';
}
@media (min-width: 768px) {
	.componentContainer {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'from to'
			'fromParty toParty'
			'departingTerminal toIata'
			'incoterms .';
	}
}
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 1rem;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.summaryContainer {
	text-align: left;
	margin-bottom:5px;
	line-height: 15px;
}
.summaryContainer.bold {
	font-weight: bold;
}
.summaryContainer /deep/ .addressLine {
    font-size: 12px;
}
.modalSelect {
	padding-top: 0;
	padding-bottom: 0;
}
.summaryWrapper.half-width {
	width: 50%;
}
</style>

