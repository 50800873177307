<template>
	<modal
		:visible="show"
		size="lg"
		@close="handleClosing"
		:title="title"
		centered
		class="codeOfConductModal"
		:okButton="false"
		:cancelButton="false"
	>
		<b-container>
			<div v-for="(code, index) of customerCodes" :key="index">
				<div v-html="coc[code]" />
				<span style="font-weight:bold;" @click="checkbox[code]=!checkbox[code]"><input type="checkbox" v-model="checkbox[code]"> I accept the terms of transport and Code of Conduct for suppliers.</span>
				<hr>
			</div>
			<b-alert
				:show="alertCountDown"
				dismissible
				fade
				variant="warning"
				@dismissed="alertCountDown=0"
				@dismiss-count-down="countDownChanged"
			>
				<span>You must check to approve the code of conduct(s) before you can proceed</span>
			</b-alert>
		</b-container>


		<b-container slot="footer" fluid>
			<b-btn slot="modal-cancel" variant="secondary" @click="modalHide">Close</b-btn>
			<b-btn variant="primary" @click="acceptCoc">Accept</b-btn>
		</b-container>
	</modal>
</template>

<script>
import StackableModal from '../../StackableModal.vue';

export default {
	name: 'CodeOfConductModal',
	props: ['visible', 'customerCodes'],
	components: {
		'modal': StackableModal
	},
	data() {
		return {
			show: this.visible,
			title: 'Code of Conduct',
			codes: this.customerCodes,
			checkbox: {},
			accepted: false,
			alertCountDown: 0,
			coc: {},
		};
	},
	methods: {
		handleClosing(){
			this.$emit('close', this.accepted);
		},
		modalHide() {
			this.show = false;
		},
		acceptCoc() {
			let allAccepted = Object.keys(this.checkbox).every(x => this.checkbox[x]);
			if(allAccepted){
				this.accepted = true;
				this.modalHide();
			} else {
				this.notAcceptedMessageShow();
			}
		},
		notAcceptedMessageShow(){
			this.alertCountDown = 5;
		},
		countDownChanged(dismissCountDown) {
			this.alertCountDown = dismissCountDown;
		},
	},
	async created(){
		for(let code of this.customerCodes){
			/* eslint-disable no-alert, no-console */
			let mod = await import(`@/assets/coc/${code}`); // eslint-disable-line no-alert
			/* eslint-enable no-alert, no-console */
			this.$set(this.coc, code, mod.default);
			this.$set(this.checkbox, code, false);
		}
	},

};
</script>

<style lang="scss" scoped>
.codeOfConductModal /deep/ .modal-body {
	padding:0;
}
.codeOfConductModal /deep/ .modal-header {
	padding:.5rem;
	padding-bottom:.25rem;
	border-bottom: none;
}
.codeOfConductModal /deep/ .modal-header .modal-title{
	font-size:15px;
}
.codeOfConductModal /deep/ .modal-header .close:focus{
	outline: none;
}
.codeOfConductModal /deep/ .modal-footer {
	border-top: none;
	padding: .5rem 0;
}
.codeOfConductModal footer.modal-footer button {
	margin: 0 5px;
}
.codeOfConductModal footer.modal-footer button:first-child {
	margin-left: 0;
}
.codeOfConductModal footer.modal-footer button:last-child {
	margin-right: 0;
}
.codeOfConductModal /deep/ .modal-body li {
	margin-bottom: .125rem
}
.alert {
    position: absolute;
    margin: auto;
    width: 60%;
    bottom: 0;
    right: 0;
    left: 0;
}
.alert .close {
	line-height: .75;
}
</style>

