<template>
	<div>
		<modal
			:visible="show"
			@closing="handleClosing"
			@close="handleClose"
			:title="title"
			centered
			class="singleEditModal"
			:okButton="false"
			:cancelButton="false"
			:header-class="cancelledClass"
			:footer-class="cancelledClass"
		>
			<div v-if="isCanceled" class="cancelledOverlay">
				<span>Cancelled</span>
			</div>
			<b-tabs>
				<b-tab title="Order details" active>
					<div class="mainContainer firstTab">
						<order-to-from
							:editable="editable"
							style="grid-area:toFrom;"
						/>
						<order-delivery
							:editable="editable"
							@validStateChanged="deliveryInformationValid"
							style="grid-area:delivery;"
						/>
						<order-freight-lines
							:editable="editable"
							style="grid-area:freightLines;"
						/>
						<order-summary
							:editable="editable"
							@validStateChanged="summaryInformationValid"
							style="grid-area:summary;"
						/>
					</div>
				</b-tab>
				<b-tab title="Documents and logs">
					<div class="mainContainer secondTab">
						<order-documents
							:editable="editable"
							style="grid-area:documents;"
						/>
						<!-- <order-messages
							style="grid-area:messages;"
						/>-->
						<order-changelog
							style="grid-area:changelog;"
						/>
					</div>
				</b-tab>
				<b-tab
					title="Track and trace"
					v-if="transporterHasConfirm"
					:disabled="noConfirmEvents"
				>
					<div class="mainContainer thirdTab">
						<order-tat
							v-if="despatchEvent"
							:eventId="despatchEvent"
							style="grid-area:tat-despatch;"
						/>
						<!-- <order-messages
							style="grid-area:messages;"
						/>-->
						<order-tat
							v-if="deliveryEvent"
							:eventId="deliveryEvent"
							style="grid-area:tat-delivery;"
						/>
					</div>
				</b-tab>
				<b-tab
					title="Declarations"
					v-if="orderHasDeclarations"
				>
					<div class="mainContainer fourthTab">
						<order-declarations
							style="grid-area:declarations"
						/>
					</div>
				</b-tab>
			</b-tabs>


			<b-container
				slot="footer"
				class="footer"
				fluid
			>
				<div v-if="isCanceled" class="d-flex justify-content-end">
					<b-btn variant="secondary" :disabled="true">Order is canceled</b-btn>
					<b-btn slot="modal-cancel" variant="secondary" @click="modalHide">Close</b-btn>
				</div>
				<div v-else-if="isTender" class="d-flex justify-content-end">
					<b-btn variant="secondary" @click="refuseOrder">Reject order</b-btn>
					<b-btn variant="primary" @click="acceptOrder">Accept order</b-btn>
					<b-btn slot="modal-cancel" variant="primary" @click="modalHide">Close</b-btn>
				</div>
				<div v-else-if="editable" class="d-flex justify-content-end">
					<b-btn v-if="!dataHasChanges" slot="modal-cancel" variant="primary" @click="modalHide">Close</b-btn>
					<b-btn v-if="dataHasChanges" variant="secondary" @click="reloadSelectedOrder"><font-awesome-icon style="margin-right: .75rem;" icon="undo-alt"></font-awesome-icon> Close without saving</b-btn>
					<template v-if="dataHasChanges">
						<b-btn v-if="!informationValid && validityChecked" disabled variant="danger"><font-awesome-icon style="margin-right: .75rem;" icon="times"></font-awesome-icon> Information invalid</b-btn>
						<b-btn v-else variant="primary" @click="saveDraft"><font-awesome-icon style="margin-right: .75rem;" icon="save"></font-awesome-icon> Save</b-btn>
						<b-btn v-if="!informationValid && validityChecked" disabled variant="danger"><font-awesome-icon style="margin-right: .75rem;" icon="times"></font-awesome-icon> Information invalid</b-btn>
						<b-btn v-else variant="primary" @click="saveAndCloseDraft"><font-awesome-icon style="margin-right: .75rem;" icon="save"></font-awesome-icon> Save and close</b-btn>
					</template>
					<b-btn v-if="orderReadyToFinalize" :disabled="validityChecked ? !informationValid : false" variant="success" @click="finalizeOrder"><font-awesome-icon style="margin-right: .75rem;" icon="check"></font-awesome-icon> Finalize</b-btn>
					<b-btn v-if="transporterHasConfirm && !confirmCompleted" :disabled="validityChecked ? !informationValid : false" variant="success" @click="sendToConfirm"><font-awesome-icon style="margin-right: .75rem;" icon="truck"></font-awesome-icon> Send to Confirm</b-btn>
					<b-btn v-if="orderReadyToSend && transporterHasTms" :disabled="validityChecked ? !informationValid : false" variant="success" @click="sendOrder"><font-awesome-icon style="margin-right: .75rem;" icon="paper-plane"></font-awesome-icon> Send order EDI</b-btn>
					<!-- Use over when transporter settings -->
					<!-- <b-btn  :disabled="validityChecked ? !informationValid : false" variant="success" @click="finalizeOrder"><font-awesome-icon style="margin-right: .75rem;" icon="paper-plane"></font-awesome-icon> Send order</b-btn> -->
				</div>
				<div v-else class="d-flex justify-content-end">
					<b-btn slot="modal-cancel" variant="primary" @click="modalHide">Close</b-btn>
				</div>

			</b-container>
			<!-- <div slot="modal-footer">
			</div> -->
		</modal>
		<code-of-conduct-modal
			v-if="codeOfConductShow"
			:visible="codeOfConductShow"
			:customerCodes="[customerCode]"
			@close="codeOfConductClose"
		></code-of-conduct-modal>
		<closing-modal
			v-if="closingModalProperties.show"
			:closeButton="closingModalProperties.closeButton"
			:okButton="closingModalProperties.okButton"
			:content="closingModalProperties.content"
			:visible="closingModalProperties.show"
			:title="closingModalProperties.title"
			:valid="closingModalProperties.valid"
			@close="closingModalClose"
		></closing-modal>
		<complete-order-modal
			v-if="completeOrderModalProperties.show"
			:closeButton="completeOrderModalProperties.closeButton"
			:okButton="completeOrderModalProperties.okButton"
			:content="completeOrderModalProperties.content"
			:visible="completeOrderModalProperties.show"
			:title="completeOrderModalProperties.title"
			@close="completeOrderModalClose"
		></complete-order-modal>
		<confirm-modal
			v-if="confirmModalProperties.show"
			:closeButton="confirmModalProperties.closeButton"
			:okButton="confirmModalProperties.okButton"
			:content="confirmModalProperties.content"
			:visible="confirmModalProperties.show"
			:title="confirmModalProperties.title"
			@close="confirmModalClose"
		></confirm-modal>
	</div>
</template>

<script>
//<icon-button v-if="dataHasChanges" :icon="{'save': !isLoading('saveDraft'), 'spinner': isLoading('saveDraft')}" label="Save draft" :onClicked="saveDraft"></icon-button>

//import moment from 'moment-timezone';
import { mapState, mapMutations, mapActions, mapGetters } from '../store';
import StackableModal from '../../StackableModal.vue';

import OrderFreightLines from './ComponentFreightLines.vue';
import OrderSummary  from './ComponentSummary.vue';
import OrderToFrom  from './ComponentToFrom.vue';
import OrderDelivery from './ComponentDelivery.vue';
// import OrderMessages from './ComponentMessages.vue';
import OrderDocuments from './ComponentDocuments.vue';
import OrderChangelog from './ComponentChangelog.vue';
import CodeOfConductModal from './CodeOfConductModal.vue';
import ClosingModal from './ClosingModal.vue';
import CompleteOrderModal from './CompleteOrderModal.vue';
import ConfirmModal from '../../ConfirmModal.vue';
import OrderTrackAndTrace from './ComponentTrackAndTrace.vue';
import OrderDeclarations from './ComponentDeclarations.vue';

// import IconButton from '../IconButton.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
	name: 'OrdersModal',
	props: ['visible'],
	components: {
		'modal': StackableModal,
		// IconButton,
		FontAwesomeIcon,
		'order-freight-lines': OrderFreightLines,
		'order-to-from': OrderToFrom,
		'order-summary': OrderSummary,
		OrderDelivery,
		// OrderMessages,
		OrderDocuments,
		OrderChangelog,
		CodeOfConductModal,
		ClosingModal,
		CompleteOrderModal,
		ConfirmModal,
		'order-tat': OrderTrackAndTrace,
		OrderDeclarations,
	},
	data() {
		return {
			show: this.visible,
			editable: false,
			isTender: false,
			isCanceled: false,
			codeOfConductShow: false,
			validState: {
				deliveryInformation: true,
				summaryInformation: true
			},
			closingModalProperties: {
				show: false,
				title: false,
				content: false,
				okButton: false,
				closeButton: false,
				valid: true
			},
			completeOrderModalProperties: {
				show: false,
				title: false,
				content: false,
				okButton: false,
				closeButton: false,
			},
			confirmModalProperties: {
				show: false,
				title: false,
				content: false,
				okButton: false,
				closeButton: false,
			}
		};
	},
	computed: {
		...mapState({
			orderData: 'selectedOrderData',
			validityChecked: 'validityChecked',
			changeLog: 'selectedOrderChangelog'
		}),
		...mapGetters({
			dataHasChanges: 'getOrderHasChanges',
			orderReadyToFinalize: 'getOrderReadyToFinalize',
			orderReadyToSend: 'getOrderReadyToSend',
			transporterSettings: 'getTransporterSettings',
			isLoading: 'isLoading'
		}),
		title() {
			return `Delivery No. ${this.orderData.orderNo}${this.orderData.isPreorder ? ' (Preorder)' : ''}`;
		},
		customerCode() {
			return this.orderData.customerSettings.code;
		},
		informationValid(){
			for(var key in this.validState){
				if(!this.validState[key]) return false;
			}
			return true;
		},
		cancelledClass() {
			return this.isCanceled ? 'cancelledOverlayColor' : '';
		},
		transporterHasTms() {
			return this.transporterSettings.hasTms.includes(this.customerCode);
		},
		transporterHasConfirm() {
			return this.transporterSettings.hasConfirm;
		},
		noConfirmEvents() {
			return !this.orderData.collectedEvent && !this.orderData.deliveredEvent;
		},
		confirmCompleted() {
			let atdCompleted = this.orderData.atd.date && this.orderData.atd.setByConfirm;
			let ataCompleted = this.orderData.ata.date && this.orderData.ata.setByConfirm;
			return atdCompleted && ataCompleted;
		},
		despatchEvent() {
			return this.orderData.collectedEvent;
		},
		deliveryEvent() {
			return this.orderData.deliveredEvent;
		},
		orderHasDeclarations() {
			return (this.orderData.declarations || []).length;
		},
	},
	watch: {
		informationValid: function(val) {
			if(val){
				this.setValidityChecked(false);
			}
		}
	},
	methods: {
		...mapActions({
			reloadOrder: 'reloadSelectedOrder',
			save: 'saveDraft',
			finalize: 'finalizeOrder',
			sendEdi: 'sendOrderToTMS',
			sendConfirm: 'sendOrderToConfirm',
			accept: 'acceptOrder',
			refuse: 'refuseOrder',
			flagSeenByUser: 'flagSeenByUser',
		}),
		...mapMutations([
			'setValidityChecked',
		]),
		async handleClose(){
			let lastChangelog = this.changeLog[0];
			await this.flagSeenByUser({orderId: this.orderData.id, logId: lastChangelog.id});
			this.$emit('close', this.orderData);
		},
		modalHide() {
			this.show = false;
		},
		handleClosing(abortClose) {
			if(this.dataHasChanges){
				abortClose(true);
				if(!this.informationValid){
					this.setValidityChecked(true);
				}
				this.closingModalShow();
			} else {
				abortClose(false);
			}
		},
		async reloadSelectedOrder(){
			await this.reloadOrder(this.orderData.id);
			this.modalHide();
		},
		async saveDraft(){
			if(!this.informationValid){
				this.setValidityChecked(true);return;
			}
			await this.save();
		},
		async saveAndCloseDraft(){
			if(!this.informationValid){
				this.setValidityChecked(true);return;
			}
			await this.save();
			this.modalHide();
		},
		finalizeOrder(){
			if(!this.informationValid){
				this.setValidityChecked(true);return;
			}
			this.completeOrderModalShow();
		},
		async completeOrder(){
			await this.finalize(this.orderData.id);
			this.modalHide();
		},
		async sendOrder(){
			if(!this.informationValid){
				this.setValidityChecked(true);return;
			}
			// console.log('SendOrder');
			await this.sendEdi(this.orderData.id);
			this.modalHide();
		},
		acceptOrder(){
			if(this.orderData.customerSettings.requiresCodeOfConduct){
				this.codeOfConductShow = true;
			} else {
				this.codeOfConductClose(true);
			}
		},
		async refuseOrder(){
			await this.refuse(this.orderData.id);
			this.modalHide();
		},
		async codeOfConductClose(accepted){
			this.codeOfConductShow = false;
			if(accepted){
				await this.accept(this.orderData.id);
				this.editable = true;
				this.isTender = false;
			}
		},
		closingModalShow(){
			this.closingModalProperties = {
				show: true,
				title: 'Save changes?',
				content: 'Do you want to save changes before closing the order?',
				okButton: 'Save Changes',
				closeButton: 'Don\'t save',
				valid: this.informationValid
			};
		},
		async closingModalClose(accepted){
			this.closingModalProperties.show = false;
			if(accepted === null){
				return;
			} else if(accepted) {
				this.saveDraft();
			} else {
				await this.reloadSelectedOrder();
			}
			this.modalHide();
		},
		completeOrderModalShow(){
			this.completeOrderModalProperties = {
				show: true,
				title: 'Finalize order?',
				content: 'This will mark the order as delivered, and save changes. Complete the order?',
				okButton: 'Complete order',
				closeButton: 'Don\'t complete order'
			};
		},
		async completeOrderModalClose(accepted){
			this.completeOrderModalProperties.show = false;
			if(accepted){
				this.completeOrder();
			} else {
				return;
			}
		},
		sendToConfirm() {
			if(!this.informationValid){
				this.setValidityChecked(true);return;
			}
			this.confirmModalShow();
		},
		confirmModalShow(){
			this.confirmModalProperties = {
				show: true,
				title: 'Confirm',
				okButton: 'Send to Confirm',
				closeButton: 'Cancel'
			};
		},
		async confirmModalClose({shouldSend, confirmInformation}){
			this.confirmModalProperties.show = false;
			if(shouldSend){
				this.sendConfirm({orderId: this.orderData.id, confirmInformation: confirmInformation});
			} else {
				return;
			}
		},
		deliveryInformationValid(val){
			this.validState.deliveryInformation = val;
		},
		summaryInformationValid(val){
			this.validState.summaryInformation = val;
		}
	},
	beforeMount() {
		this.setValidityChecked(false);
		if(this.orderData.status >= 10 && this.orderData.status < 100) this.editable = true;
		if(this.orderData.status == 0) this.isTender = true;
		if(this.orderData.status == -5) this.isCanceled = true;
	}
};
</script>

<style lang="scss" scoped>
.mainContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns:100%;
}
.firstTab.mainContainer {
	padding:15px;
	grid-template-areas:
		'toFrom' 'delivery'	'freightLines' 'summary';
}
.secondTab.mainContainer {
	padding:15px;
	grid-template-areas:
		'documents' 'changelog';
}

.thirdTab.mainContainer {
	padding:15px;
	grid-template-areas:
			'tat-despatch' 'tat-delivery';
}

.fourthTab.mainContainer {
	padding:0;
	grid-template-areas:
			'declarations' 'declarations';
}

.singleEditModal /deep/ .modal-container {
	max-width: 100% !important;
	margin-left: 8px;
	margin-right: 8px;
}
@media (min-width: 768px) {
	.singleEditModal /deep/ .modal-container {
		max-width: 90% !important;
		margin-left: auto;
		margin-right: auto;
	}
	.mainContainer {
		grid-template-columns: 1fr 1fr;
	}
	.firstTab.mainContainer {
		padding:15px;
		grid-template-areas:
			'toFrom delivery'
			'freightLines freightLines'
			'summary summary';
	}
	.secondTab.mainContainer {
		padding:15px;
		grid-template-areas:
			'documents changelog'
			'messages changelog';
	}
	.thirdTab.mainContainer {
		padding:15px;
		grid-template-areas:
			'tat-despatch tat-delivery';
	}
	.fourthTab.mainContainer {
		padding:0;
		grid-template-areas:
				'declarations declarations';
	}
}
.singleEditModal /deep/ .modal-body {
	padding:0;
}
.singleEditModal /deep/ .modal-header {
	padding:.5rem;
	padding-bottom:.25rem;
	border-bottom: none;
}
.singleEditModal /deep/ .modal-header .modal-title{
	font-size:15px;
}
.singleEditModal /deep/ .modal-header .close:focus{
	outline: none;
}
.singleEditModal /deep/ .modal-footer {
	border-top: none;
	padding: .5rem 0;
}
.singleEditModal /deep/ footer.modal-footer button {
	margin: 0 5px;
}
.singleEditModal /deep/ footer.modal-footer button:first-child {
	margin-left: 0;
}
.singleEditModal /deep/ footer.modal-footer button:last-child {
	margin-right: 0;
}
.singleEditModal .tabs /deep/ ul[role="tablist"] li {
	padding-left: 2.5px;
}

/deep/ input {
	color:#2c3e50;
}
::-webkit-input-placeholder {
	font-style: italic;
}
::-moz-placeholder {
	font-style: italic !important;
}
:-moz-placeholder {
	font-style: italic !important;
}
::-ms-input-placeholder {
	font-style: italic !important;
}
.singleEditModal /deep/ .cancelledOverlayColor {
	background-color: rgba(0,0,0,0.05);
}
.singleEditModal /deep/ .cancelledOverlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	pointer-events: none;
	background-color: rgba(0,0,0,0.05);
}
.singleEditModal /deep/ .cancelledOverlay > span {
	z-index: 100;
	margin: auto;
	transform: rotate(-10deg);
	font-size: 14rem;
	color: rgba(0,0,0,0.2);
}
</style>

