// const mockOrders = require('./mockOrders');
// const axios = require('axios');
const { request } = require('@/core');

export async function getOrders() {
	let response = await request({
		method: 'get',
		url: '/orders',
		params: {
			status_ge: -5,
			status_lt: 100
		}
	});
	return response;
}

export async function getOrderById(orderId) {
	let response = await request({
		method: 'get',
		url: `/orders/${orderId}`,
	});
	return response;
}

export async function getOrdersByQuery(params) {
	let response = await request({
		method: 'get',
		url: '/orders',
		params: {
			...params
		}
	});
	return response;
}

export async function getDeletedOrders() {
	let response = await request({
		method: 'get',
		url: '/orders',
		params: {
			status_lt: 0
		}
	});
	return response;
}

export async function getCompletedOrders() {
	let response = await request({
		method: 'get',
		url: '/orders',
		params: {
			status_ge: 100
		}
	});
	return response;
}

export async function updateOrder() {
	return null;
}

export async function sendOrder() {
	return null;
}