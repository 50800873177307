<template>
	<div class="componentGrid">
		<div class="summaryWrapper border" style="grid-area:freightlines;">
			<div class="summaryHeader">
				Freight lines
			</div>
			<ag-grid-vue
				class="ag-theme-alpine"
				@gridReady="onGridReady"
				@cellValueChanged="cellValueChanged"
				@rowEditingStopped="editingStopped"
				:gridOptions="gridOptions"
				:columnDefs="columnDefs"
				:rowData="orderData.freightLines"
				:stopEditingWhenGridLosesFocus="true"
			></ag-grid-vue>
		</div>
	</div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapMutations } from '../store';
// import RowEditButton from './ModalRowEditButton.vue';

export default {
	name: 'OrderFreightLines',
	props: ['editable'],
	components: {
		AgGridVue,
	},
	data() {
		return {
			gridOptions: {},
			components: null,
			columnDefs: [],
			hasSavedFilters: false,
		};
	},
	computed: {
		...mapState({
			orderData: 'selectedOrderData',
			unitType: 'unitType'
		}),
		freightLinesQuantity(){
			return this.orderData.freightLines.map(item => item.quantity).reduce((prev, next) => prev + next, 0);
		},
		freightLinesGrossWeight(){
			return +(this.orderData.freightLines.map(item => item.grossWeight).reduce((prev, next) => prev + next, 0)).toFixed(2);
		},
		freightLinesNetWeight(){
			return +(this.orderData.freightLines.map(item => item.netWeight).reduce((prev, next) => prev + next, 0)).toFixed(2);
		},
		freightLinesVolume(){
			return +(this.orderData.freightLines.map(item => item.volume).reduce((prev, next) => +prev + +next, 0)).toFixed(2);
		},
	},
	methods: {
		...mapMutations([
			'toggleHasChanges',
			'updateSelectedOrderData'
		]),
		onGridReady(params) {
			// console.log('gridReady');
			// params.api.sizeColumnsToFit();
			// var timeoutId;
			// window.addEventListener('resize', function() {
			// 	clearTimeout(timeoutId);
			// 	timeoutId = setTimeout(function() {
			// 		clearTimeout(timeoutId);
			// 		timeoutId = null;
			// 		params.api.sizeColumnsToFit();
			// 	}, 50);
			// });
			// var defaultSortModel = [
			// 	{colId: 'lineNo', sort: 'asc'},
			// ];
			// params.api.setSortModel(defaultSortModel);
			params.api.doLayout();
		},
		// toggleEdit() {
		// 	if(!this.editable){
		// 		alert('Unable to edit order');
		// 		return;
		// 	}
		// 	this.gridOptions.api.startEditingCell({
		// 		rowIndex: 0,
		// 		colKey: 'description'
		// 	});
		// },
		unitTypeValueSetter(params){
			// //If unitType should change l*w*h, use this object
			let newValue = this.unitType.filter(ut => ut.codeAndDescription === params.newValue)[0];
			let oldValue = this.unitType.filter(ut => ut.codeAndDescription === params.oldValue)[0];
			let rowData = params.data;
			if(!oldValue || oldValue.id !== newValue.id){
				rowData.unitCode = newValue.code;
				rowData.unitDescription = newValue.description;
				return true;
			} else {
				return false;
			}

			// let newValue = params.newValue;
			// let unitTypeSplit = newValue.split(' - ');
			// let newUnitCode = unitTypeSplit[0];
			// let newUnitDescription = unitTypeSplit[1];
			// let old = params.data;
			// if( old.unitCode !== newUnitCode || old.unitDescription !== newUnitDescription){
			// 	old.unitCode = newUnitCode;
			// 	old.unitDescription = newUnitDescription;
			// 	return true;
			// } else {
			// 	return false;
			// }
		},
		numberValueSetter(params){
			let newNumber = parseFloat(params.newValue);
			if(isNaN(newNumber)) return false;
			let old = params.data;
			if(newNumber !== old[params.column.colId]) {
				old[params.column.colId] = newNumber;
				return true;
			} else {
				return false;
			}
		},
		stringValueSetter(params){
			let newNumber = params.newValue;
			let old = params.data;
			if(newNumber !== old[params.column.colId]) {
				old[params.column.colId] = newNumber;
				return true;
			} else {
				return false;
			}
		},
		volumeValueSetter(params){
			let newNumber = parseFloat(params.newValue);
			if(isNaN(newNumber)) return false;
			let oldRow = params.data;
			if(newNumber !== oldRow[params.column.colId]) {
				oldRow[params.column.colId] = newNumber;
				params.node.setDataValue('volume', +(params.data.quantity*params.data.unitLength*params.data.unitWidth*params.data.unitHeight).toFixed(2));
				return true;
			} else {
				return false;
			}
		},
		cellValueChanged(params){
			if(params.oldValue !== params.newValue && this.orderData.hasChanges !== true){
				this.$nextTick( () => {
					this.toggleHasChanges({id: this.orderData.id, state: true});
				});
			}
		},
		editingStopped(){
			this.updateSelectedOrderData(this.orderData);
		},
		beginRowEdit(params){
			this.gridOptions.api.startEditingCell({
				rowIndex: params.rowIndex,
				colKey: params.colDef.field
			});
		}
	},
	watch: {
		editable: function(newValue, oldValue) {
			if(oldValue != newValue){
				this.gridOptions.columnApi.getAllColumns().forEach(col => {if(!['lineNo', 'volume'].includes(col.colId)) col.colDef.editable = true;});
			}
		},
		freightLinesQuantity: function(newValue, oldValue){
			if(oldValue != newValue) {
				return this.orderData.freightLinesQuantity = newValue;
			}
		},
		freightLinesGrossWeight: function(newValue, oldValue){
			this.gridOptions.api.redrawRows();
			if(oldValue != newValue) {
				return this.orderData.freightLinesGrossWeight = newValue;
			}
		},
		freightLinesNetWeight: function(newValue, oldValue){
			if(oldValue != newValue) {
				return this.orderData.freightLinesNetWeight = newValue;
			}
		},
		freightLinesVolume: function(newValue, oldValue){
			if(oldValue != newValue) {
				return this.orderData.freightLinesVolume = newValue;
			}
		},
	},
	beforeMount() {

		this.gridOptions = {
			enableCellChangeFlash: true,
			domLayout: 'autoHeight',
			suppressPropertyNamesCheck: true,
			pagination: false,
			context: {
				componentParent: this
			},
			singleClickEdit: true,
			suppressClickEdit: true,
			onCellDoubleClicked: this.beginRowEdit,
			editType: 'fullRow',
			rowHeight: 32,
			defaultColDef: {
				editable: this.editable,
				headerClass: 'table-header',
				sortable: true,
				filter: false,
				resizable: true,
				floatingFilter: false,
			},
		};

		this.columnDefs = [
			{
				field: 'lineNo',
				valueSetter: this.stringValueSetter,
				pinned: 'left',
				width: 34,
				sort: 'asc',
				sortIndex: 0,
				header: '',
				cellClass: 'left',
				editable: false,
				resizable: false,
				// 		type: 'string',
				// 		filter: 'agNumberColumnFilter',
				// 		sort: 'desc',
				// 		width: 100,
				// 		comparator: (a,b) => +a - +b
			},
			{
				field: 'description',
				valueSetter: this.stringValueSetter
				// 		type: 'string',
				// 		filter: 'agNumberColumnFilter',
				// 		sort: 'desc',
				// 		width: 100,
				// 		comparator: (a,b) => +a - +b
			},
			{
				field: 'marks',
				valueSetter: this.stringValueSetter
				// 		cellClass: 'left',
				// 		valueGetter: (params) => {
				// 			var etd = params.data.etd;
				// 			var date = moment(etd.date, 'YYYYMMDDHHmm');
				// 			date.tz(etd.timeZone);
				// 			return {
				// 				...etd,
				// 				dateObject: date
				// 			};
			},
			// 		cellRenderer: (params) => {
			// 			var etd = params.data.etd;
			// 			return dateRender(etd, 'DD.MM');
			// 		},
			// 		width: 70,
			// 		comparator: dateCompare
			// 	},
			{
				field: 'netWeight',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				valueSetter: this.numberValueSetter
				// 		cellClass: 'left',
				// 		valueGetter: (params) => {
				// 			var eta = params.data.eta;
				// 			var date = moment(eta.date, 'YYYYMMDDHHmm');
				// 			date.tz(eta.timeZone);
				// 			return {
				// 				...eta,
				// 				dateObject: date
				// 			};
				// 		},
				// 		cellRenderer: (params) => {
				// 			var eta = params.data.eta;
				// 			return dateRender(eta, 'DD.MM');
				// 		},
				// 		width: 70,
				// 		comparator: dateCompare
			},
			{
				field: 'grossWeight',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				valueSetter: this.numberValueSetter
				// 		headerName: 'From',
				// 		cellClass:'left',
				// 		cellRenderer: (params) => {
				// 			let con = params.data.consignor;
				// 			return addressRender(con);
				// 		},
			},
			{
				field: 'quantity',
				headerName: 'Units',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				valueSetter: this.volumeValueSetter
				// 		headerName: 'To',
				// 		cellClass:'left',
				// 		cellRenderer: (params) => {
				// 			let con = params.data.consignee;
				// 			return addressRender(con);
				// 		},
			},
			{
				field: 'unit',
				headerName: 'Unit Type',
				valueGetter: (params) => {
					var line = [params.data.unitCode, params.data.unitDescription].filter(x => !!x);
					return line.join(' - ');
				},
				valueSetter: this.unitTypeValueSetter,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: this.unitType.map(ut => ut.codeAndDescription)
				}
			},
			{
				field: 'unitLength',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				valueSetter: this.volumeValueSetter
			},
			{
				field: 'unitWidth',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				valueSetter: this.volumeValueSetter
			},
			{
				field: 'unitHeight',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				valueSetter: this.volumeValueSetter
			},
			{
				field: 'volume',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				editable: false,
				// valueGetter: (params) => {
				// 	return params.data.unitLength*params.data.unitWidth*params.data.unitHeight;
				// },
				// valueSetter: (params) => {
				// 	console.log(params);
				// 	let newNumber = parseFloat(params.data.unitLength*params.data.unitWidth*params.data.unitHeight);
				// 	let old = params.data;
				// 	if(newNumber !== old[params.column.colId]) {
				// 		old[params.column.colId] = newNumber;
				// 		return true;
				// 	} else {
				// 		return false;
				// 	}
				// }
			},
			// {
			// 	headerName: 'Edit',
			// 	editable: false,
			// 	sortable: false,
			// 	cellRendererFramework: RowEditButton,
			// 	hide: !this.editable
			// }
		];
	}
};

</script>

<style lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'freightlines';
}
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	left:0;
	font-size: 12px;
	color: #9fa8b0;
	line-height: .75rem;
}
.freightSummary {
    background-color: inherit;
    margin-top: -1.5em;
    position: absolute;
    font-weight: 400;
    right: 0;
	font-size: 12px;
	color: black;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 1rem;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.summaryList {
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.summaryList li {
	display: inline-block;
}
.summaryList li > span {
	margin-left: 0.5rem;
}
.ag-theme-bootstrap /deep/ .ag-cell:not(:last-child),
.ag-theme-bootstrap /deep/ .ag-header-cell:not(:last-child),
.ag-theme-bootstrap /deep/ .ag-pinned-left-cols-container .ag-cell,
.ag-theme-bootstrap /deep/ .ag-pinned-left-header .ag-header-cell {
	border-right: 1px solid #e6e6e6 !important;
}
.ag-theme-bootstrap /deep/ .ag-cell.ag-cell-focus,
.ag-theme-bootstrap /deep/ .ag-pinned-left-cols-container .ag-cell.ag-cell-focus {
    border: 1px solid darkgrey !important;
}
.ag-theme-bootstrap /deep/ .ag-cell.left {
    text-align: left;
}
.ag-theme-bootstrap /deep/ .ag-cell.mono {
	font-family: 'Roboto Mono';
}
.ag-theme-bootstrap /deep/ .ag-header-cell {
    color: #9fa8b0;
    font-size: 12px;
}
.ag-cell.left {
    text-align: left;
}
</style>

